import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { TargetCollection } from '../proto/projectChangeEvents_pb';

export interface JobQueueStatusStore {
    jobCount: number
}

const initialState: JobQueueStatusStore = {
    jobCount: 0
};

export default function (state: JobQueueStatusStore = initialState, action: IStoreActions): JobQueueStatusStore {
    if (action.type === getType(actions.receivedProjectChangeEvent)
        && action.payload.getTargetcollection() === TargetCollection.JOBQUEUESTATUSES) {
        const status = action.payload.getJobqueuestatus();
        if(status)
            return {
                jobCount: status.getJobcount()
            };
    }
    return state;
}