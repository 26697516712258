import { Feature, FeatureCollection, LineString, MultiLineString, Point, Polygon } from 'geojson';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';

export type GeomType = Point | Polygon | LineString | MultiLineString;

export interface AnnotationProperties {
    summaryType: string;
    strokeColor: string;
    fillColor?: string;
    radius?: number;
    type: string;
    info: string;
    text?: string;
    textPosition?: [number, number];
    textColor?: string;
    textAngle?: number;
    fontSize?: number;
}

export interface AnnotationCollection extends FeatureCollection<GeomType, AnnotationProperties> {}

export interface AnnotationStore {
    [pageId: string]: AnnotationCollection;
}

const initialState = {};

export const annotationsBySummaryType = (
    annotations: AnnotationCollection,
    summaryType: string): Array<Feature<GeomType, AnnotationProperties>> => 
        annotations.features.filter((feature) => feature.properties.summaryType === summaryType);

export default function(state: AnnotationStore = initialState, action: IStoreActions): AnnotationStore {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.fetchAnnotationsSuccess)) {
            draftState[action.meta] = action.payload;
        }
        if (action.type === getType(actions.fetchAnnotationsBegin)) {
            // clear current annotation cache
            return initialState;
        }
    });
}