import * as React from 'react';
import { connect } from 'react-redux';
import { doLogout } from '../../actions/users';
import { ThunkDispatch } from '../../store-types';

interface LogoutDispatchProps {
    doLogout: () => void;
}

class Logout extends React.Component<LogoutDispatchProps> {
    public componentDidMount() {
        this.props.doLogout();
    }

    public render() {
        return (
            <div>Redirecting...</div>
        );
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch): LogoutDispatchProps {
    return {
        doLogout: () => {
            dispatch(doLogout());
        }
    };
}

export default connect(null, mapDispatchToProps)(Logout);