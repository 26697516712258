import React, { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { subscribeListProjects, unsubscribeListProjects } from '../../actions/projects';
import { ThunkDispatch } from '../../store-types';
import { userIsAdminRedir } from '../../utils/auth';
import asyncComponent from '../AsyncComponent';
import NotificationManager from '../NotificationManager';

const AsyncUserDetails = asyncComponent(() => import('../UserDetails'));
const AsyncProject = asyncComponent(() => import('../Project'));
const AsyncPortal = asyncComponent(() => import('../Portal'));
const AsyncAdminPanel = asyncComponent(() => import('../Portal/Admin'));
const AsyncAbout = asyncComponent(() => import('../About'));

// @ts-ignore
const ProtectedAsyncAdminPanel = userIsAdminRedir(AsyncAdminPanel);

interface DispatchProps {
    doSubscribeListProjects: (callback?: () => void) => void;
    doUnsubscribeListProjects: () => void;
}

type Props = DispatchProps;

const useDisablePinchZoomEffect = () => {
    useLayoutEffect(() => {
        const disablePinchZoom = (e: WheelEvent) => {
            if(e.ctrlKey)
                e.preventDefault();
        }
        window.addEventListener("wheel", disablePinchZoom, { passive: false });
        return () => window.removeEventListener("wheel", disablePinchZoom);
    }, []);
}

function ActualApplication(props: Props) {
    useEffect(() => {
        props.doSubscribeListProjects();
        return () => props.doUnsubscribeListProjects();
    }, []);
    useDisablePinchZoomEffect();

    return (
        <React.Fragment>
            <Helmet>
                <title>Model Broker for Logic Diagram</title>
                <meta name="description" content="Translation of PDF documents into intelligent logic diagrams" />
                <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
            </Helmet>
            <Switch>
                <Route exact={true} path="/me" component={AsyncUserDetails} />
                <Route exact={true} path="/admin" component={ProtectedAsyncAdminPanel} />
                <Route exact={true} path="/about" component={AsyncAbout} />
                <Route exact={false} path="/project/:projectId/:pageId?" component={AsyncProject} />
                <Route component={AsyncPortal} />
            </Switch>
            <NotificationManager />
        </React.Fragment>
    );
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        doSubscribeListProjects: (callback?: () => void) => {
            dispatch(subscribeListProjects(callback));
        },
        doUnsubscribeListProjects: () => {
            dispatch(unsubscribeListProjects());
        },
    };
}

export default connect(null, mapDispatchToProps)(ActualApplication);
