// This must be the first line in src/index.js
import { ConnectedRouter } from 'connected-react-router';
import 'core-js';
import JavascriptTimeAgo from 'javascript-time-ago';
// The desired locales.
// @ts-ignore
import en from 'javascript-time-ago/locale/en';
// @ts-ignore
import fi from 'javascript-time-ago/locale/fi';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'fomantic-ui-css/semantic.min.css';
import 'whatwg-fetch';
import Application from './components/Application';
import './index.css';
import configureStore, { history } from './store';
import 'react-input-range/lib/css/index.css';
import { enableMapSet } from 'immer';

// Initialize the desired locales.
JavascriptTimeAgo.locale(en);
JavascriptTimeAgo.locale(fi);

// add immer features
enableMapSet();

const store = configureStore();

const render = (App: any) => {
    return ReactDOM.render(
        (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>
        ),
        document.getElementById('root') as HTMLElement
    );
};

render(Application);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./components/Application', () => {
        render(require('./components/Application').default);
    });
}
