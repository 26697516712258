import { routerMiddleware, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Action, applyMiddleware, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { newApiMiddleware } from './middleware/apiMiddleware';
import { newGrpcMiddleware } from './middleware/grpcMiddleware2';
import { newPdfPageMiddleware } from './middleware/pdfPageMiddleware';
import createRootReducer from './reducers';
import { AccessRightsStore } from './reducers/accessrights';
import { AnnotationStore } from './reducers/annotations';
import { UserState } from './reducers/auth';
import { ConfigurationsStore } from './reducers/configurations';
import { DrawingSheetDefinitionsStore } from './reducers/drawingsheets';
import { JobQueueStore } from './reducers/jobqueue';
import { JobQueueStatusStore } from './reducers/jobqueuestatus';
import { MetamodelsStore } from './reducers/metamodels';
import { NotificationStore } from './reducers/notifications';
import { PageErrorsStore } from './reducers/pageerrors';
import { PagePartitionStore } from './reducers/pagepartitions';
import { PageStore } from './reducers/pages';
import { PageTranslationStore } from './reducers/pagetranslations';
import { PaintingStore } from './reducers/painting';
import { Paper2PidState } from './reducers/paper2pid';
import { PatternDefinitionsStore } from './reducers/patterns';
import { ProjectRemovalState } from './reducers/projectremoval';
import { ProjectStore } from './reducers/projects2';
import { ProjectTranslationStore } from './reducers/projecttranslations';
import { PropertiesStore } from './reducers/properties';
import { PropertyTypeStore } from './reducers/propertytypes';
import { ResponseStore } from './reducers/responses';
import { SourceFilesStore } from './reducers/sourcefiles';
import { SubscriptionStore } from './reducers/subscriptions';
import { TargetConfigurationsStore } from './reducers/targetconfigurations';
import { TargetPatternDefinitionsStore } from './reducers/targetpatterns';
import { UserStore } from './reducers/users';
import { VectorFontDefinitionsStore } from './reducers/vectorfonts';
import { ViewersState } from './reducers/viewers';
import { IssueStore } from './reducers/issues';

export interface StoreState {
    accessrights: AccessRightsStore;
    annotations: AnnotationStore;
    auth: UserState;
    responses: ResponseStore;
    router: RouterState;
    paper2pid: Paper2PidState;
    notifications: NotificationStore;
    user: UserStore;
    projects: ProjectStore;
    viewers: ViewersState;
    pages: PageStore;
    pagepartitions: PagePartitionStore;
    pagetranslations: PageTranslationStore;
    painting: PaintingStore;
    projectremoval: ProjectRemovalState;
    projecttranslations: ProjectTranslationStore;
    properties: PropertiesStore;
    propertytypes: PropertyTypeStore;
    sourcefiles: SourceFilesStore;
    pageerrors: PageErrorsStore;
    patterndefinitions: PatternDefinitionsStore;
    subscriptions: SubscriptionStore;
    targetconfigurations: TargetConfigurationsStore;
    drawingsheets: DrawingSheetDefinitionsStore;
    jobqueuestatus: JobQueueStatusStore;
    configurations: ConfigurationsStore;
    jobqueue: JobQueueStore;
    targetpatterndefinitions: TargetPatternDefinitionsStore;
    metamodels: MetamodelsStore;
    vectorfontdefinitions: VectorFontDefinitionsStore;
    issues: IssueStore;
}

export const history = createBrowserHistory();

const reducer = createRootReducer(history); // root reducer with router state

export default function configureStore(preloadedState?: StoreState) {
    const store = createStore(
        enableBatching(reducer),
        // preloadedState,
        composeWithDevTools(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                // ... other middlewares ...
                thunk as ThunkMiddleware<StoreState, Action>,
                newApiMiddleware(),
                newGrpcMiddleware(),
                newPdfPageMiddleware(),
            ),
        ),
    );

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(reducer);
        });
    }
    return store;
}
