import { createAction } from 'typesafe-actions';
import { Pattern, patternToString, parsePattern, patternsToString } from '../components/patterns/Pattern';
import { PatternTestResult } from '../components/patterns/PatternTestResult';
import { RemovePatternsRequest, RemovePatternsResponse, UpdatePatternsRequest, UpdatePatternsResponse, CreatePatternRequest, CreatePatternResponse, NormalizePatternDefinitionRequest, NormalizePatternDefinitionResponse } from '../proto/project_pb';
import { ProjectService } from '../proto/project_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { stdOnError } from './grpcutils';
import yaml from 'js-yaml';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { PatternDefinition } from '../proto/projectChangeEvents_pb';

export const updatePattern = createAction('UPDATE_PATTERN', (resolve) => {
    return (projectId: string, patternId: string, pattern: Pattern) => resolve({ projectId, patternId, pattern });
});

export const clearModifiedPatterns = createAction('CLEAR_MODIFIED_PATTERNS', (resolve) => {
    return () => resolve();
});

export const clearModifiedPattern = createAction('CLEAR_MODIFIED_PATTERN', (resolve) => {
    return (patternId: string) => resolve(patternId);
});

export const clearPatternTestResults = createAction('CLEAR_PATTERN_TEST_RESULTS', (resolve) => {
    return (patternId: string) => resolve(patternId);
});

export const addPatternTestResult = createAction('ADD_PATTERN_TEST_RESULT', (resolve) => {
    return (patternId: string | null, result: PatternTestResult) => resolve({ patternId, result });
});

export const saveAllModifiedPatterns = (projectId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { patternDefinitions, modifiedPatternDefinitions } = getState().patterndefinitions;

        const request = new UpdatePatternsRequest();
        request.setProjectid(projectId);
        const updatesMap = request.getUpdatesMap();
        for (const id of Object.keys(modifiedPatternDefinitions)) {
            const pattern = modifiedPatternDefinitions[id];
            const patternDefinition = new PatternDefinition();
            patternDefinition.setName(pattern.name);
            patternDefinition.setDefinition(patternToString(pattern));
            patternDefinition.setActive(patternDefinitions[id].active);
            updatesMap.set(id, patternDefinition);
        }

        const grpcAction: GrpcActionPayload<UpdatePatternsRequest, UpdatePatternsResponse> = {
            batch: true,
            methodDescriptor: ProjectService.UpdatePatterns,
            onMessage: (event) => dispatch(clearModifiedPatterns()),
            onError: stdOnError(dispatch, 'Saving patterns'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const saveModifiedPattern = (projectId: string, patternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { patternDefinitions, modifiedPatternDefinitions } = getState().patterndefinitions;

        const request = new UpdatePatternsRequest();
        request.setProjectid(projectId);
        const updatesMap = request.getUpdatesMap();
        const pattern = modifiedPatternDefinitions[patternId];
        if (!pattern)
            return;
        const patternDefinition = new PatternDefinition();
        patternDefinition.setName(pattern.name);
        patternDefinition.setDefinition(patternToString(pattern));
        patternDefinition.setActive(patternDefinitions[patternId].active);
        updatesMap.set(patternId, patternDefinition);

        const grpcAction: GrpcActionPayload<UpdatePatternsRequest, UpdatePatternsResponse> = {
            batch: true,
            methodDescriptor: ProjectService.UpdatePatterns,
            onMessage: (event) => dispatch(clearModifiedPattern(patternId)),
            onError: stdOnError(dispatch, 'Saving patterns'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const activatePattern = (projectId: string, patternId: string, activate: boolean): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { patternDefinitions } = getState().patterndefinitions;

        const patternDefinitionP = patternDefinitions[patternId];
        if (!patternDefinitionP)
            return;
        const patternDefinition = new PatternDefinition();
        patternDefinition.setDefinition(yaml.safeDump(patternDefinitionP.pattern));
        patternDefinition.setActive(activate);
        patternDefinition.setName(patternDefinitionP.pattern.name);

        const timestamp = new Timestamp();
        timestamp.setSeconds(patternDefinitionP.updated);
        timestamp.setNanos(0);
        patternDefinition.setUpdated(timestamp);

        const request = new UpdatePatternsRequest();
        request.setProjectid(projectId);

        const updatesMap = request.getUpdatesMap();
        updatesMap.set(patternId, patternDefinition);

        const grpcAction: GrpcActionPayload<UpdatePatternsRequest, UpdatePatternsResponse> = {
            methodDescriptor: ProjectService.UpdatePatterns,
            onError: stdOnError(dispatch, 'Changing pattern activation'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const removePattern = (projectId: string, patternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new RemovePatternsRequest();
        request.setProjectid(projectId);
        request.setPatternidsList([patternId]);

        const grpcAction: GrpcActionPayload<RemovePatternsRequest, RemovePatternsResponse> = {
            methodDescriptor: ProjectService.RemovePatterns,
            onError: stdOnError(dispatch, 'Removing pattern'),
            request,
        };
        dispatch(clearModifiedPattern(patternId));
        dispatch(grpcRequest(grpcAction));
    };
};

export const duplicatePattern = (projectId: string, patternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { patterndefinitions } = getState();

        // Find pattern definition
        var pattern = patterndefinitions.modifiedPatternDefinitions[patternId];
        if (!pattern) {
            const patternDef = patterndefinitions.patternDefinitions[patternId];
            if (patternDef)
                pattern = patternDef.pattern;
            else
                return;
        }

        // Rename
        pattern = { ...pattern, name: pattern.name + " (copy)"};

        const definition = new PatternDefinition();
        definition.setName(pattern.name);
        definition.setDefinition(patternToString(pattern));
        definition.setActive(false);

        const request = new CreatePatternRequest();
        request.setProjectid(projectId);
        request.setDefinition(definition);

        // Invoke
        const grpcAction: GrpcActionPayload<CreatePatternRequest, CreatePatternResponse> = {
            methodDescriptor: ProjectService.CreatePattern,
            onError: stdOnError(dispatch, 'Duplicating pattern'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const normalizeAndCopyToClipboard = (patterns: Pattern[]): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new NormalizePatternDefinitionRequest();
        request.setDefinition(patternsToString(patterns));

        const grpcAction: GrpcActionPayload<NormalizePatternDefinitionRequest, NormalizePatternDefinitionResponse> = {
            batch: true,
            methodDescriptor: ProjectService.NormalizePatternDefinition,
            onMessage: (event) => {
                navigator.clipboard.writeText(event.getDefinition());
            },
            onError: stdOnError(dispatch, 'Normalizing pattern'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    }
}

export const copyPatternsToClipboard = (patternIds: string[]): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { patterndefinitions } = getState();
        const patterns: Pattern[] = patternIds.map(id => {
            const modifiedPattern = patterndefinitions.modifiedPatternDefinitions[id];
            if(modifiedPattern)
                return modifiedPattern;
            const patternDef = patterndefinitions.patternDefinitions[id];
            if(patternDef)
                return patternDef.pattern;
            return null;
        }).filter(pattern => pattern !== null) as Pattern[];
        dispatch(normalizeAndCopyToClipboard(patterns));
    }
}

export default {
    updatePattern, clearModifiedPatterns, clearModifiedPattern,
    clearPatternTestResults, addPatternTestResult
};
