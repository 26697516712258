import yaml from 'js-yaml';
import { Extent } from '@modelbroker/diagram-viewport';
import { uniqBy } from 'lodash';

export interface DrawingSheet {
    name: string;
    horLines: HorLine[];
    verLines: VerLine[];
    gridAreas: GridArea[];
    regions: DrawingSheetRegion[];
    includeSheetLines: boolean;
}

export interface DrawingSheetSelection {
    horLines: HorLine[];
    verLines: VerLine[];
}

const horKey = (line: HorLine) => line.x0 + " " + line.x1 + " " + line.y;
const verKey = (line: VerLine) => line.x + " " + line.y0 + " " + line.y1;

export function mergeDrawingSheetSelections(a: DrawingSheetSelection, b: DrawingSheetSelection): DrawingSheetSelection {
    const horLineSet = new Set(a.horLines.map(horKey));
    const verLineSet = new Set(a.verLines.map(verKey));

    return {
        horLines: a.horLines.concat(b.horLines.filter(line => !horLineSet.has(horKey(line)))),
        verLines: a.verLines.concat(b.verLines.filter(line => !verLineSet.has(verKey(line))))
    };
}

export function xorDrawingSheetSelections(a: DrawingSheetSelection, b: DrawingSheetSelection): DrawingSheetSelection {
    const horLineSetA = new Set(a.horLines.map(horKey));
    const horLineSetB = new Set(b.horLines.map(horKey));
    const verLineSetA = new Set(a.verLines.map(verKey));
    const verLineSetB = new Set(b.verLines.map(verKey));

    return {
        horLines: a.horLines.filter(line => !horLineSetB.has(horKey(line)))
            .concat(
                b.horLines.filter(line => !horLineSetA.has(horKey(line)))
            ),
        verLines: a.verLines.filter(line => !verLineSetB.has(verKey(line)))
            .concat(
                b.verLines.filter(line => !verLineSetA.has(verKey(line)))
            )
    };
}

export function removeDrawingSheetSelections(a: DrawingSheetSelection, b: DrawingSheetSelection): DrawingSheetSelection {
    const horLineSet = new Set(b.horLines.map(horKey));
    const verLineSet = new Set(b.verLines.map(verKey));

    return {
        horLines: a.horLines.filter(line => !horLineSet.has(horKey(line))),
        verLines: a.verLines.filter(line => !verLineSet.has(verKey(line)))
    };
}

export function selectLinesInsideExtent(drawingSheet: DrawingSheet, extent: Extent): DrawingSheetSelection {
    const { minX, minY, maxX, maxY } = extent;
    function isInside(low: number, x: number, high: number) {
        return low <= x && x <= high;
    }
    function horIsInside({ x0, x1, y }: HorLine) {
        return isInside(minX, x0, maxX)
            && isInside(minX, x1, maxX)
            && isInside(minY, y, maxY);
    }
    function verIsInside({ x, y0, y1 }: VerLine) {
        return isInside(minX, x, maxX)
            && isInside(minY, y0, maxY)
            && isInside(minY, y1, maxY);
    }
    return {
        horLines: drawingSheet.horLines.filter(horIsInside),
        verLines: drawingSheet.verLines.filter(verIsInside)
    };
}

export function selectNearestLine(drawingSheet: DrawingSheet, position: [number, number], maxDist: number): DrawingSheetSelection {
    const [px, py] = position;
    var nearestDist = maxDist;
    var nearestHorLine: HorLine | undefined;
    var nearestVerLine: VerLine | undefined;

    for (const line of drawingSheet.horLines) {
        const { x0, x1, y } = line;
        const dist = Math.hypot(px <= x0 ? x0 - px : px >= x1 ? x1 - px : 0, y - py);
        if (dist < nearestDist) {
            nearestDist = dist;
            nearestHorLine = line;
        }
    }

    for (const line of drawingSheet.verLines) {
        const { x, y0, y1 } = line;
        const dist = Math.hypot(x - px, py <= y0 ? y0 - py : py >= y1 ? y1 - py : 0);
        if (dist < nearestDist) {
            nearestDist = dist;
            nearestVerLine = line;
        }
    }

    if (nearestVerLine)
        return {
            horLines: [],
            verLines: [nearestVerLine]
        };
    else if (nearestHorLine)
        return {
            horLines: [nearestHorLine],
            verLines: []
        };
    else
        return EMPTY_DRAWING_SHEET_SELECTION;
}

export function removeSelectedLines(drawingSheet: DrawingSheet, selection: DrawingSheetSelection): DrawingSheet {
    return {
        ...drawingSheet,
        ...removeDrawingSheetSelections(drawingSheet, selection)
    };
}

export const EMPTY_DRAWING_SHEET_SELECTION: DrawingSheetSelection = {
    horLines: [],
    verLines: []
}

export interface HorLine {
    x0: number;
    x1: number;
    y: number;
}

export interface VerLine {
    x: number;
    y0: number;
    y1: number;
}

export interface DrawingSheetRegion {
    name: string;
    boundary: number[][];
    includeInterior: boolean;
    includeSheetLines: boolean;
}

export interface GridArea {
    vertical: boolean;

    x0: number;
    x1: number;
    y0: number;
    y1: number;

    minCount: number;
    maxCount: number;
}

export function getDrawingSheetBoundingBox(drawingSheet: DrawingSheet | null) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    if (drawingSheet) {
        const { horLines, verLines } = drawingSheet;

        for (const { x0, x1, y } of horLines) {
            minX = Math.min(minX, x0);
            maxX = Math.max(maxX, x1);
            minY = Math.min(minY, y);
            maxY = Math.max(maxY, y);
        }
        for (const { x, y0, y1 } of verLines) {
            minX = Math.min(minX, x);
            maxX = Math.max(maxX, x);
            minY = Math.min(minY, y0);
            maxY = Math.max(maxY, y1);
        }
    }

    if (!isFinite(minX)) {
        minX = -10;
        maxX = 10;
    }
    if (!isFinite(minY)) {
        minY = -10;
        maxY = 10;
    }
    return { minX, minY, maxX, maxY };
}

export function findRegions(drawingSheet: DrawingSheet) {
    const { horLines, verLines } = drawingSheet;

    // Find indices for x and y coordinates

    const xSet: Set<number> = new Set(), ySet: Set<number> = new Set();
    for (const { x0, x1, y } of horLines) {
        xSet.add(x0);
        xSet.add(x1);
        ySet.add(y);
    }
    for (const { x, y0, y1 } of verLines) {
        xSet.add(x);
        ySet.add(y0);
        ySet.add(y1);
    }

    const xs: number[] = Array.from(xSet), ys: number[] = Array.from(ySet);
    xs.sort(compareNumbers);
    ys.sort(compareNumbers);

    const xMap = new Map(), yMap = new Map();
    xs.forEach((x, i) => xMap.set(x, i));
    ys.forEach((y, i) => yMap.set(y, i));

    // Raster

    const width = xs.length - 1;
    const height = ys.length - 1;
    const raster = new Raster(width, height);

    for (const { x0, x1, y } of horLines) {
        raster.addHorLine(xMap.get(x0), xMap.get(x1), yMap.get(y));
    }
    for (const { x, y0, y1 } of verLines) {
        raster.addVerLine(xMap.get(x), yMap.get(y0), yMap.get(y1));
    }

    // Fill areas

    raster.findRegionIds();

    return new DrawingSheetRegions(xs, ys, raster.regionCount, raster.regionIds);
}

/**
 * Finds i such that xs[i] <= x <= xs[i+1].
 */
function segIndexOf(xs: number[], x: number) {
    if (x < xs[0] || x > xs[xs.length - 1]) {
        return null;
    }
    let a = 0;
    let b = xs.length - 1;
    while (b - a > 1) {
        const c = Math.floor(0.5 * (a + b));
        if (x < xs[c]) {
            b = c;
        }
        else {
            a = c;
        }
    }
    return a;
}

/**
 * Finds i such that abs(xs[i] - x) is minimized
 */
function pointIndexOf(xs: number[], x: number) {
    if (x <= xs[0]) {
        return 0;
    }
    if (x >= xs[xs.length - 1]) {
        return xs.length - 1;
    }

    let a = 0;
    let b = xs.length - 1;

    while (b - a > 1) {
        const c = Math.floor(0.5 * (a + b));
        const xc = xs[c];
        if (x < xc) {
            b = c;
        }
        else if (x > xc) {
            a = c;
        }
        else {
            return c;
        }
    }

    const m = 0.5 * (xs[a] + xs[b]);
    if (x < m) {
        return a;
    }
    else {
        return b;
    }
}

function compareNumbers(a: number, b: number) {
    return a - b;
}

enum Dir {
    U, D, L, R
}

export class DrawingSheetRegions {
    public width: number;
    public height: number;
    public xs: number[];
    public ys: number[];
    public regionCount: number;
    public regionIds: Int32Array;

    constructor(xs: number[], ys: number[], regionCount: number, regionIds: Int32Array) {
        this.width = xs.length - 1;
        this.height = ys.length - 1;
        this.xs = xs;
        this.ys = ys;
        this.regionCount = regionCount;
        this.regionIds = regionIds;
    }

    public findRegion(x: number, y: number) {
        const ix = segIndexOf(this.xs, x);
        const iy = segIndexOf(this.ys, y);
        // console.log(`ix,iy = ${ix},${iy}`);
        if (ix !== null && iy !== null) {
            return this.regionIds[ix + iy * this.width];
        }
        else {
            return null;
        }
    }

    public regionsBoundary(regions: Set<number>) {
        const { width, height, regionIds } = this;
        const row = width + 1;
        const id = (x: number, y: number) => x + row * y;
        const xOf = (id: number) => id % row;
        const yOf = (id: number) => Math.floor(id / row);

        const
            u: Set<number> = new Set(),
            d: Set<number> = new Set(),
            l: Set<number> = new Set(),
            r: Set<number> = new Set();
        let ad = 0;
        for (let y = 0; y < height; ++y) {
            for (var x = 0; x < width; ++x, ++ad)
                if (regions.has(regionIds[ad])) {
                    if (x === 0 || !regions.has(regionIds[ad - 1]))
                        u.add(id(x, y + 1));
                    if (y === 0 || !regions.has(regionIds[ad - width]))
                        r.add(id(x, y));
                    if (x === width - 1 || !regions.has(regionIds[ad + 1]))
                        d.add(id(x + 1, y));
                    if (y === height - 1 || !regions.has(regionIds[ad + width]))
                        l.add(id(x + 1, y + 1));
                }
        }

        const result: number[][] = [];

        const seeds = Array.from(r);
        seeds.sort(compareNumbers);
        for (const seed of seeds) {
            if (!r.has(seed)) {
                continue;
            }

            let cur = seed;
            const loop: Array<[Dir, number]> = [];
            const loopAdd = (dir: Dir) => {
                if (loop.length > 0) {
                    const last = loop[loop.length - 1];
                    if (last[0] === dir) {
                        ++last[1];
                        return;
                    }
                }
                loop.push([dir, 1]);
            };
            do {
                if (u.delete(cur)) {
                    loopAdd(Dir.U);
                    cur = cur - row;
                } else if (d.delete(cur)) {
                    loopAdd(Dir.D);
                    cur = cur + row;
                } else if (r.delete(cur)) {
                    loopAdd(Dir.R);
                    cur = cur + 1;
                } else if (l.delete(cur)) {
                    loopAdd(Dir.L);
                    cur = cur - 1;
                } else {
                    console.log('Internal error while finding region boundary. This indicates a bug in the implementation.');
                    cur = seed; // this is assumed in the later code
                    break;
                }
            } while (cur !== seed);

            const points: number[] = [];
            for (const seg of loop) {
                points.push(this.xs[xOf(cur)]);
                points.push(this.ys[yOf(cur)]);
                const len = seg[1];
                switch (seg[0]) {
                    case Dir.U:
                        cur -= row * len;
                        break;
                    case Dir.D:
                        cur += row * len;
                        break;
                    case Dir.L:
                        cur -= len;
                        break;
                    case Dir.R:
                        cur += len;
                        break;
                }
            }
            result.push(points);
        }

        return result;
    }

    filterHorLine(selectedRegions: Set<number>, line: HorLine): HorLine[] {
        const { width, height, regionIds } = this;
        const { x0, x1, y } = line;

        const yi = pointIndexOf(this.ys, y);
        if (yi === 0 || yi === height) {
            return [line];
        }

        const xi0 = pointIndexOf(this.xs, x0);
        const xi1 = pointIndexOf(this.xs, x1);
        const iLen = xi1 - xi0;

        const segsToTake = new Uint8Array(iLen);
        const ad0 = xi0 + (yi - 1) * width;
        const ad1 = xi0 + yi * width;
        for (let i = 0; i < iLen; ++i) {
            segsToTake[i] = selectedRegions.has(regionIds[ad0 + i]) && selectedRegions.has(regionIds[ad1 + i]) ? 0 : 1;
        }

        if (segsToTake.every((n) => n === 1)) {
            return [line];
        } // Nothing removed
        if (segsToTake.every((n) => n === 0)) {
            return [];
        } // All removed

        console.log('convex hor line');

        const result: HorLine[] = [];
        let begin = null;
        for (let i = 0; i <= iLen; ++i) {
            if (i === iLen || segsToTake[i] === 0) {
                if (begin !== null) {
                    result.push({ x0: this.xs[xi0 + begin], x1: this.xs[xi0 + i], y });
                    begin = null;
                }
            } else {
                if (begin === null) {
                    begin = i;
                }
            }
        }
        return result;
    }

    filterVerLine(selectedRegions: Set<number>, line: VerLine): VerLine[] {
        const { width, height, regionIds } = this;
        const { x, y0, y1 } = line;

        const xi = pointIndexOf(this.xs, x);
        if (xi === 0 || xi === width) {
            return [line];
        }

        const yi0 = pointIndexOf(this.ys, y0);
        const yi1 = pointIndexOf(this.ys, y1);
        const iLen = yi1 - yi0;

        const segsToTake = new Uint8Array(iLen);
        const ad0 = yi0 * width + xi - 1;
        const ad1 = yi0 * width + xi;
        for (let i = 0; i < iLen; ++i) {
            segsToTake[i] = selectedRegions.has(regionIds[ad0 + i * width]) && selectedRegions.has(regionIds[ad1 + i * width]) ? 0 : 1;
        }

        if (segsToTake.every((n) => n === 1)) {
            return [line];
        } // Nothing removed
        if (segsToTake.every((n) => n === 0)) {
            return [];
        } // All removed

        console.log('convex ver line');

        const result: VerLine[] = [];
        let begin = null;
        for (let i = 0; i <= iLen; ++i) {
            if (i === iLen || segsToTake[i] === 0) {
                if (begin !== null) {
                    result.push({ x, y0: this.ys[yi0 + begin], y1: this.ys[yi0 + i] });
                    begin = null;
                }
            } else {
                if (begin === null) {
                    begin = i;
                }
            }
        }
        return result;
    }

    public removeLines(selectedRegions: Set<number>, sheet: DrawingSheet) {
        sheet.horLines = sheet.horLines.flatMap((line) => this.filterHorLine(selectedRegions, line));
        sheet.verLines = sheet.verLines.flatMap((line) => this.filterVerLine(selectedRegions, line));
    }
}

class Raster {
    width: number;
    height: number;
    hRaster: Uint8Array;
    vRaster: Uint8Array;

    regionCount: number;
    regionIds: Int32Array;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
        this.hRaster = new Uint8Array(width * (height + 1));
        this.vRaster = new Uint8Array((width + 1) * height);

        this.regionCount = 0;
        this.regionIds = new Int32Array(width * height);
        this.regionIds.fill(-1);
    }

    public addHorLine(x0: number, x1: number, y: number) {
        var ad: number = y * this.width + x0;
        for (var xi = x0; xi < x1; ++xi, ++ad)
            this.hRaster[ad] = 1;
    }

    public addVerLine(x: number, y0: number, y1: number) {
        var ad: number = y0 * (this.width + 1) + x;
        for (var yi = y0; yi < y1; ++yi, ad += this.width + 1)
            this.vRaster[ad] = 1;
    }

    public findRegionIds() {
        for (var y = 0; y < this.height; ++y)
            for (var x = 0; x < this.width; ++x)
                if (this.regionId(x, y) === -1) {
                    this.fillH(x, y, this.regionCount);
                    ++this.regionCount;
                }
    }

    public print() {
        console.log("");
        const { width, height, regionIds, hRaster, vRaster } = this;
        for (var y = 0; y < height; ++y) {
            {
                var str = " ";
                for (var x = 0; x < width; ++x) {
                    str = str
                        + (hRaster[x + y * width] === 1 ? " ----" : "     ");
                }
                console.log(str);
            }
            {
                var str = "";
                for (var x = 0; x < width; ++x) {
                    const id = regionIds[x + width * y];
                    str = str
                        + (vRaster[x + y * (width + 1)] === 1 ? " | " : "   ")
                        + (id >= 10 || id < 0 ? "" : " ") + id.toString();
                }
                str = str + (vRaster[width + y * (width + 1)] === 1 ? " | " : "   ");
                console.log(str);
            }
        }
        {
            const y = height;
            var str = " ";
            for (var x = 0; x < width; ++x) {
                str = str
                    + (hRaster[x + y * width] === 1 ? " ----" : "     ");
            }
            console.log(str);
        }

    }

    private regionId(x: number, y: number) {
        return this.regionIds[x + this.width * y];
    }

    private setRegionId(x: number, y: number, regionId: number) {
        this.regionIds[x + this.width * y] = regionId;
    }

    private hasH(x: number, y: number) {
        return this.hRaster[x + y * this.width] === 1;
    }

    private hasV(x: number, y: number) {
        return this.vRaster[x + y * (this.width + 1)] === 1;
    }

    private fillH(x: number, y: number, regionId: number) {
        var x0 = x;
        while (x0 > 0 && !this.hasV(x0, y))
            --x0;
        var x1 = x + 1;
        while (x1 < this.width && !this.hasV(x1, y))
            ++x1;

        // We mark the whole line before calling fillV recursively
        // to reduce the depth of recursion
        const xs = [];
        for (var xx = x0; xx < x1; ++xx)
            if (this.regionId(xx, y) === -1) {
                this.setRegionId(xx, y, regionId);
                xs.push(xx);
            }
        for (const xx of xs)
            this.fillV(xx, y, regionId);
    }

    private fillV(x: number, y: number, regionId: number) {
        var y0 = y;
        while (y0 > 0 && !this.hasH(x, y0))
            --y0;
        var y1 = y + 1;
        while (y1 < this.height && !this.hasH(x, y1))
            ++y1;

        // We mark the whole line before calling fillH recursively
        // to reduce the depth of recursion
        const ys = [];
        for (var yy = y0; yy < y1; ++yy)
            if (this.regionId(x, yy) === -1) {
                this.setRegionId(x, yy, regionId);
                ys.push(yy);
            }
        for (const yy of ys)
            this.fillH(x, yy, regionId);
    }
}

function ceilCoord(xs: number[], x: number) {
    if (x <= xs[0]) {
        return xs[0];
    }
    let a = 0;
    let b = xs.length - 1;
    while (b - a > 1) {
        const c = Math.floor(0.5 * (a + b));
        if (x < xs[c]) {
            b = c;
        }
        else if (x > xs[c]) {
            a = c;
        }
        else {
            return xs[c];
        }
    }
    return xs[b];
}

function floorCoord(xs: number[], x: number) {
    if (x >= xs[xs.length - 1]) {
        return xs[xs.length - 1];
    }
    let a = 0;
    let b = xs.length - 1;
    while (b - a > 1) {
        const c = Math.floor(0.5 * (a + b));
        if (x < xs[c]) {
            b = c;
        }
        else if (x > xs[c]) {
            a = c;
        }
        else {
            return xs[c];
        }
    }
    return xs[a];
}

export function cleanUpSheet(sheet: DrawingSheet): DrawingSheet {
    const { horLines, verLines } = sheet;

    const xSet: Set<number> = new Set(), ySet: Set<number> = new Set();
    for (const { y } of horLines) {
        ySet.add(y);
    }
    for (const { x } of verLines) {
        xSet.add(x);
    }

    const xs: number[] = Array.from(xSet), ys: number[] = Array.from(ySet);
    xs.sort(compareNumbers);
    ys.sort(compareNumbers);

    //

    const shortenHor = (line: HorLine) => {
        const { x0, x1, y } = line;
        const good0 = xSet.has(x0);
        const good1 = xSet.has(x1);
        if (good0 && good1) {
            return line;
        }
        else {
            return {
                x0: good0 ? x0 : ceilCoord(xs, x0),
                x1: good1 ? x1 : floorCoord(xs, x1),
                y
            };
        }
    };

    const shortenVer = (line: VerLine) => {
        const { x, y0, y1 } = line;
        const good0 = xSet.has(y0);
        const good1 = xSet.has(y1);
        if (good0 && good1) {
            return line;
        }
        else {
            return {
                x,
                y0: good0 ? y0 : ceilCoord(ys, y0),
                y1: good1 ? y1 : floorCoord(ys, y1),
            };
        }
    };

    return {
        ...sheet,
        horLines: horLines.map(shortenHor).filter(({ x0, x1 }: HorLine) => x0 < x1),
        verLines: verLines.map(shortenVer).filter(({ y0, y1 }: VerLine) => y0 < y1)
    };
}

export function parseDrawingSheet(text: string) {
    const obj = yaml.safeLoad(text);
    if (!obj.name) {
        obj.name = "unnamed drawing sheet";
    }
    if (!obj.horLines) {
        obj.horLines = [];
    }
    if (!obj.verLines) {
        obj.verLines = [];
    }
    if (!obj.gridAreas) {
        obj.gridAreas = [];
    }
    if (!obj.regions) {
        if (obj.parts) {
            obj.regions = Object.entries(obj.parts)
                .map((name, boundary) => ({
                    name, boundary,
                    includeInterior: false,
                    includeSheetLines: false
                }));
            delete obj.parts;
        } else {
            obj.regions = [];
        }
    } else {
        for (const region of obj.regions) {
            region.includeInterior = !!region.includeInterior;
            region.includeSheetLines = !!region.includeSheetLines;
        }
    }
    obj.includeSheetLines = !!obj.includeSheetLines;
    return obj as DrawingSheet;
}

export function drawingSheetToString(pattern: DrawingSheet) {
    return yaml.safeDump(pattern);
}

export function drawingSheetsToString(patterns: DrawingSheet[]) {
    return yaml.safeDump(patterns);
}
