import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { StoreState } from '../store';

const userIsAuthenticatedDefaults = {
    // If selector is true, wrapper will not redirect
    // For example let's check that state contains user data
    authenticatedSelector: (state: StoreState) => {
        return state.auth.user !== undefined;
    },
    authenticatingSelector: (state: StoreState) => {
        return state.auth.isAuthenticating;
    },
    // A nice display name for this check
    wrapperDisplayName: 'UserIsAuthenticated'
};

// export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults);

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    ...userIsAuthenticatedDefaults,
    // AuthenticatingComponent: Loading, // this is handled via redux and is hidden all the time
    // The url to redirect user to if they fail
    redirectPath: '/login',
});

export const userIsAdminRedir = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: (state: StoreState) => {
        return state.auth.user !== undefined && state.auth.user.admin;
    },
    redirectPath: '/',
    wrapperDisplayName: 'UserIsAdmin'
});

const userIsNotAuthenticatedDefaults = {
    // Want to redirect the user when they are done loading and authenticated
    authenticatedSelector: (state: StoreState) => {
        return state.auth.user === undefined && state.auth.isAuthenticating === false;
    },
    wrapperDisplayName: 'UserIsNotAuthenticated'
};

// export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    ...userIsNotAuthenticatedDefaults,
    allowRedirectBack: false,
    redirectPath: (state: StoreState, ownProps) => {
        const redirectPath = window.sessionStorage.getItem('redirectPath');
        if (redirectPath) {
            window.sessionStorage.removeItem('redirectPath');
            return redirectPath;
        }
        return '/';
    },
});
