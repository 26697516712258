import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions, { User } from '../actions/users';

export interface UserState {
    isAuthenticating: boolean;
    isLoggedIn: boolean;
    user?: User;
}

const unauthenticatedState: UserState = {
    isAuthenticating: false,
    isLoggedIn: false,
};

const initialState = { ...unauthenticatedState };

export default function(state: UserState = initialState, action: IStoreActions): UserState {
    switch (action.type) {
        case getType(actions.authenticatingUser):
            return {
                ...state,
                isAuthenticating: true,
            };
        case getType(actions.loginUser):
            return {
                isAuthenticating: false,
                isLoggedIn: true,
                user: action.payload,
            };
        case getType(actions.userLogout):
            return { ...unauthenticatedState };
        default:
            return state;
        }
}