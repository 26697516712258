import * as React from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import ReactTimeAgo from 'react-time-ago';
import { Icon, Item, Progress, Segment, SemanticICONS } from 'semantic-ui-react';
import { Notification, NotificationType } from '../actions/notifications';
import { StoreState } from '../store';
import marked from 'marked';

interface OwnProps {
    id: string;
    onCloseClick: (id: string) => void;
}

interface StatePropsFromStore {
    notification: Notification;
}

interface OwnState {
    dismissAfter: number;
    startTime: Date;
    header: string | null;
    message: string;
    icon: SemanticICONS;
    date: Date;
    username: string;
    progress: number;
    type: NotificationType;
}

type Props = OwnProps & StatePropsFromStore;

class NotificationJSX extends React.PureComponent<Props, OwnState> {

    private id: number = -1;
    constructor(props: Props) {
        super(props);
        const { dismissAfter, message } = this.props.notification;
        const adjustedDismissAfter = dismissAfter + 300;
        this.state = {
            ...this.props.notification,
            dismissAfter: adjustedDismissAfter,
            message: marked(message),
            progress: dismissAfter,
            startTime: new Date(),
        };
    }

    public onDismiss = () => {
        this.props.onCloseClick(this.props.id);
    }

    public componentDidMount() {
        this.updateProgress();
    }
    public componentWillUnmount() {
        cancelAnimationFrame(this.id);
    }

    public render() {
        const { id, onCloseClick, ...rest} = this.props;
        const { header, icon, message, date, username, progress, type, dismissAfter } = this.state;
        const color = type === NotificationType.ERROR ? 'red' : 'blue';
        return (
            <Segment {...rest} color={color} raised={true} key={id} dispatch={undefined}>
                <Item.Group style={{marginBottom: '0px'}}>
                    <Item>
                        <Item.Content verticalAlign={'middle'}>
                            <Item.Header style={{display: 'block'}}>
                                <Icon name={icon} />
                                {header}
                                <Icon style={{float: 'right'}} name="close" link={true} onClick={this.onDismiss} />
                            </Item.Header>
                            <Item.Description>
                                <div dangerouslySetInnerHTML={{__html: message}} />
                            </Item.Description>
                            <Item.Extra>
                                <ReactTimeAgo date={date} />by @{username}
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <Progress color={color} total={dismissAfter} value={progress} attached="bottom" />
            </Segment>
        );
    }

    private updateProgress = () => {
        const { startTime, dismissAfter } = this.state;
        const progress = (startTime.getTime() + dismissAfter) - new Date().getTime();
        this.setState({
            ...this.state,
            progress,
        });
        if (progress > 0) {
            this.id = requestAnimationFrame(this.updateProgress);
        }
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const { notifications } = state;
    return {
        notification: notifications[ownProps.id],
    };
}

export default connect(mapStateToProps)(NotificationJSX);