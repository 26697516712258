import { IStoreActions } from '../actions';
import { PagePartition, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';
import composable from './composable';

export interface PagePartitionStore extends ChangeEventStore<PagePartition> {}

const composed = composable<PagePartition>(
    TargetCollection.PAGEPARTITIONS,
    ProjectChangeEvent.prototype.getPagepartition,
);

export default function(state: PagePartitionStore = {}, action: IStoreActions): PagePartitionStore {
    return composed(state, action);
}