import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { parseTargetPattern, TargetPattern, TargetPatternDefinitionP } from '../components/targetpatterns/TargetPattern';
import { ChangeOperation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';
import { updateTargetPattern, clearModifiedTargetPatterns, clearModifiedTargetPattern } from '../actions/targetpatterns';

export interface TargetPatternDefinitionsStore {
    targetPatternDefinitions: ChangeEventStore<TargetPatternDefinitionP>;
    modifiedTargetPatternDefinitions: ChangeEventStore<TargetPattern>;
}

function composed(
    state: ChangeEventStore<TargetPatternDefinitionP> = {},
    action: IStoreActions
): ChangeEventStore<TargetPatternDefinitionP> {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectChangeEvent)) {
            if (action.payload.getTargetcollection() === TargetCollection.TARGETPATTERNDEFINITIONS) {
                const changeEvent = action.payload;
                const key = keyFunc(changeEvent);
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE)
                    delete draftState[key];
                else {
                    const targetPatternDefinition = changeEvent.getTargetpatterndefinition();
                    if(targetPatternDefinition)
                        draftState[key] = {
                            targetPattern: parseTargetPattern(targetPatternDefinition.getDefinition()),
                            target: targetPatternDefinition.getTarget(),
                            mappable: targetPatternDefinition.getMappable(),
                            editable: targetPatternDefinition.getEditable()
                        };
                }
            }
        }
        else if (action.type === getType(actions.unsubscribe))
            return {};
    });
}

const keyFunc = (changeEvent: ProjectChangeEvent) => changeEvent.getContentid();

const initialState: TargetPatternDefinitionsStore = {
    targetPatternDefinitions: {},
    modifiedTargetPatternDefinitions: {},
};

export default function (state: TargetPatternDefinitionsStore = initialState, action: IStoreActions): TargetPatternDefinitionsStore {
    return produce(state, (draftState) => {
        if (action.type === getType(updateTargetPattern)) {
            draftState.modifiedTargetPatternDefinitions = {
                ...draftState.modifiedTargetPatternDefinitions,
                [action.payload.targetPatternId]: action.payload.targetPattern
            };
        }
        else if (action.type === getType(clearModifiedTargetPatterns)) {
            draftState.modifiedTargetPatternDefinitions = {};
        }
        else if (action.type === getType(clearModifiedTargetPattern)) {
            draftState.modifiedTargetPatternDefinitions = { ...draftState.modifiedTargetPatternDefinitions };
            delete draftState.modifiedTargetPatternDefinitions[action.payload];
        }
        else
            draftState.targetPatternDefinitions = composed(state.targetPatternDefinitions, action);
    });
}
