import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/projectremoval';

export interface ProjectRemovalState {
    active: boolean;
    project: string;
    knownActiveUsersCount: number;
}

const initialState : ProjectRemovalState = {
    active: false,
    project: '',
    knownActiveUsersCount: 0,
};

export default function (state: ProjectRemovalState = initialState, action: IStoreActions): ProjectRemovalState {
    switch (action.type) {
        case getType(actions.startProjectRemoval):
            return {
                active: true,
                ...action.payload
            }
        case getType(actions.cancelProjectRemoval):
            return {
                active: false,
                project: '',
                knownActiveUsersCount: 0,
            };
        default:
            return state;
    }
}