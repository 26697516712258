import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { createAction,  } from 'typesafe-actions';
import { v4 as uuid } from 'uuid';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';

const notificationShow = createAction('NOTIFICATION_SHOW', (resolve) => {
    return (notification: Notification) => resolve(notification);
});

const notificationDismiss = createAction('NOTIFICATION_DISMISS', (resolve) => {
    return (notificationId: string) => resolve(notificationId);
});

const notificationClear = createAction('NOTIFICATION_CLEAR', (resolve) => {
    return (notificationId: string) => resolve(notificationId);
});

export enum NotificationType {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export interface Notification {
    date: Date;
    username: string;
    header: string;
    message: string;
    icon: SemanticICONS;
    type: NotificationType;
    
    dismissAfter: number;
    id: string;
}

export function showInfoNotification(header: string, message: string, icon?: SemanticICONS): ThunkResult<void> {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(showNotification({
            date: new Date(),
            header,
            icon: icon ? icon : 'info',
            message,
            type: NotificationType.INFO,
            username: getState().auth.user!.username,
            id: uuid(),
            dismissAfter: 5000,
        }));
    };
}

export function showErrorNotification(header: string, message: string): ThunkResult<void> {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(showNotification({
            date: new Date(),
            header,
            icon: 'exclamation',
            message,
            type: NotificationType.ERROR,
            username: getState().auth.user!.username,
            id: uuid(),
            dismissAfter: 5000,
        }));
    };
}

export function showNotification(notification: Notification): ThunkResult<string> {
    return (dispatch: ThunkDispatch): string => {

        dispatch(notificationShow(notification));
        setTimeout(
            () => {
                dispatch(notificationDismiss(notification.id));
            },
            notification.dismissAfter
        );
        return notification.id;
    };
}

export default {
    notificationClear,
    notificationDismiss, 
    notificationShow,
};