// package: 
// file: projectList.proto

var projectList_pb = require("./projectList_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProjectListService = (function () {
  function ProjectListService() {}
  ProjectListService.serviceName = "ProjectListService";
  return ProjectListService;
}());

ProjectListService.ProjectList = {
  methodName: "ProjectList",
  service: ProjectListService,
  requestStream: false,
  responseStream: true,
  requestType: projectList_pb.ProjectListRequest,
  responseType: projectList_pb.ProjectListChangeEvent
};

ProjectListService.AdminProjectList = {
  methodName: "AdminProjectList",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.ProjectListRequest,
  responseType: projectList_pb.AdminProjectListResponse
};

ProjectListService.IsProjectNameAvailable = {
  methodName: "IsProjectNameAvailable",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.IsProjectNameAvailableRequest,
  responseType: projectList_pb.IsProjectNameAvailableResponse
};

ProjectListService.CreateProject = {
  methodName: "CreateProject",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.CreateProjectRequest,
  responseType: projectList_pb.CreateProjectResponse
};

ProjectListService.RemoveProject = {
  methodName: "RemoveProject",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.RemoveProjectRequest,
  responseType: projectList_pb.RemoveProjectResponse
};

ProjectListService.UpdateProjectName = {
  methodName: "UpdateProjectName",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.UpdateProjectNameRequest,
  responseType: projectList_pb.UpdateProjectNameResponse
};

ProjectListService.UpdateProjectDescription = {
  methodName: "UpdateProjectDescription",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.UpdateProjectDescriptionRequest,
  responseType: projectList_pb.UpdateProjectDescriptionResponse
};

ProjectListService.ProjectAccessRights = {
  methodName: "ProjectAccessRights",
  service: ProjectListService,
  requestStream: false,
  responseStream: true,
  requestType: projectList_pb.ProjectAccessRightsRequest,
  responseType: projectList_pb.ProjectAccessRightsChangeEvent
};

ProjectListService.ChangeUserRole = {
  methodName: "ChangeUserRole",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.ChangeUserRoleRequest,
  responseType: projectList_pb.ChangeUserRoleReponse
};

ProjectListService.UserList = {
  methodName: "UserList",
  service: ProjectListService,
  requestStream: false,
  responseStream: true,
  requestType: projectList_pb.UserListRequest,
  responseType: projectList_pb.User
};

ProjectListService.CreateUser = {
  methodName: "CreateUser",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.CreateUserRequest,
  responseType: projectList_pb.CreateUserResponse
};

ProjectListService.RemoveUser = {
  methodName: "RemoveUser",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.RemoveUserRequest,
  responseType: projectList_pb.RemoveUserResponse
};

ProjectListService.VerifyUser = {
  methodName: "VerifyUser",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.VerifyUserRequest,
  responseType: projectList_pb.VerifyUserResponse
};

ProjectListService.PromoteUserToAdmin = {
  methodName: "PromoteUserToAdmin",
  service: ProjectListService,
  requestStream: false,
  responseStream: false,
  requestType: projectList_pb.PromoteUserToAdminRequest,
  responseType: projectList_pb.PromoteUserToAdminResponse
};

ProjectListService.JobQueueStatus = {
  methodName: "JobQueueStatus",
  service: ProjectListService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: projectList_pb.JobQueueStatusEvent
};

exports.ProjectListService = ProjectListService;

function ProjectListServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProjectListServiceClient.prototype.projectList = function projectList(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ProjectListService.ProjectList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.adminProjectList = function adminProjectList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.AdminProjectList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.isProjectNameAvailable = function isProjectNameAvailable(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.IsProjectNameAvailable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.createProject = function createProject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.CreateProject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.removeProject = function removeProject(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.RemoveProject, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.updateProjectName = function updateProjectName(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.UpdateProjectName, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.updateProjectDescription = function updateProjectDescription(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.UpdateProjectDescription, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.projectAccessRights = function projectAccessRights(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ProjectListService.ProjectAccessRights, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.changeUserRole = function changeUserRole(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.ChangeUserRole, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.userList = function userList(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ProjectListService.UserList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.createUser = function createUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.CreateUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.removeUser = function removeUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.RemoveUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.verifyUser = function verifyUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.VerifyUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.promoteUserToAdmin = function promoteUserToAdmin(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectListService.PromoteUserToAdmin, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectListServiceClient.prototype.jobQueueStatus = function jobQueueStatus(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ProjectListService.JobQueueStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.ProjectListServiceClient = ProjectListServiceClient;

