import { grpc } from '@improbable-eng/grpc-web';
import { createAction } from 'typesafe-actions';
import { AdminProjectListResponse, ChangeUserRoleReponse, ChangeUserRoleRequest, CreateProjectRequest, CreateProjectResponse, CreateUserRequest, CreateUserResponse, IsProjectNameAvailableRequest, IsProjectNameAvailableResponse, ProjectAccessRightsChangeEvent, ProjectAccessRightsRequest, ProjectDesc, ProjectListChangeEvent, ProjectListRequest, PromoteUserToAdminRequest, PromoteUserToAdminResponse, RemoveProjectRequest, RemoveProjectResponse, RemoveUserRequest, RemoveUserResponse, UpdateProjectDescriptionRequest, UpdateProjectDescriptionResponse, UpdateProjectNameRequest, UpdateProjectNameResponse, User, UserListRequest, VerifyUserRequest, VerifyUserResponse } from '../proto/projectList_pb';
import { ProjectListService } from '../proto/projectList_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { stdOnEnd, stdOnError, stdOnMessage } from './grpcutils';
import { showInfoNotification } from './notifications';

const listProjects = createAction('LIST_PROJECTS', (resolve) => {
    return () => resolve();
});
const listeningProjects = createAction('LISTENING_PROJECTS', (resolve) => {
    return (stream: grpc.Request) => resolve(stream);
});
const doUnsubscribeListProjects = createAction('UNSUBSCRIBE_LIST_PROJECTS', (resolve) => {
    return () => resolve();
});
const receiveProjectListChangeEvent = createAction('RECEIVED_PROJECT_LIST_CHANGE_EVENT', (resolve) => {
    return (event: ProjectListChangeEvent) => resolve(event);
});
const createProject = createAction('CREATE_PROJECT', (resolve) => {
    return (name: string, description: string) => resolve({ name, description });
});
const projectNameAvailable = createAction('PROJECT_NAME_AVAILABLE', (resolve) => {
    return (available: boolean) => resolve(available);
});
const deleteProject = createAction('DELETE_PROJECT', (resolve) => {
    return (id: string) => resolve(id);
});

const listUsers = createAction('LIST_USERS', (resolve) => {
    return () => resolve();
});
const receivedUser = createAction('RECEIVED_USER', (resolve) => {
    return (user: User) => resolve(user);
});

const receivedAdminProjectList = createAction('RECEIVED_ADMIN_PROJECT_LIST', (resolve) => {
    return (projectList: ProjectDesc[]) => resolve(projectList);
});

const listProjectAccessRights = createAction('LIST_PROJECT_ACCESS_RIGHTS', (resolve) => {
    return () => resolve();
});
const receivedProjectAccessRightsChangeEvent =
    createAction('RECEIVED_PROJECT_ACCESS_RIGHTS_CHANGE_EVENT', (resolve) => {
        return (projectId: string, event: ProjectAccessRightsChangeEvent) => resolve({ event, projectId });
    });
const changeUserRole = createAction('CHANGE_USER_ROLE');

const userRemoved = createAction('REMOVED_USER', (resolve) => {
    return (user: string) => resolve(user);
});

export const subscribeListProjects = (callback?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new ProjectListRequest();

        const grpcAction: GrpcActionPayload<ProjectListRequest, ProjectListChangeEvent> = {
            methodDescriptor: ProjectListService.ProjectList,
            request,
            onMessage: (event) => receiveProjectListChangeEvent(event),
            onStart: (stream) => {
                if (callback) {
                    callback();
                }
                return listeningProjects(stream);
            },
            onError: (code, msg) => {
                // should we retry here ?
                if (code === grpc.Code.Unknown) {
                    dispatch(subscribeListProjects());
                }
                return stdOnError(dispatch, 'Project List Change Listening')(code, msg);
            },
            onEnd: (code) => {
                if (code === grpc.Code.OK) {
                    dispatch(showInfoNotification(
                        'Project List Change Stream Finished',
                        ''
                    ));
                }
            },
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const unsubscribeListProjects = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        dispatch(doUnsubscribeListProjects());
    };
};

export const checkIfProjectNameIsAvailable = (name: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new IsProjectNameAvailableRequest();
        request.setName(name);

        const grpcAction: GrpcActionPayload<IsProjectNameAvailableRequest, IsProjectNameAvailableResponse> = {
            methodDescriptor: ProjectListService.IsProjectNameAvailable,
            onMessage: (response) => dispatch(projectNameAvailable(response.getAvailable())),
            request,
            onError: stdOnError(dispatch, 'Check If Project Name Is Available')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doCreateProject = (name: string, description: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new CreateProjectRequest();
        request.setName(name);
        request.setDescription(description);

        const grpcAction: GrpcActionPayload<CreateProjectRequest, CreateProjectResponse> = {
            methodDescriptor: ProjectListService.CreateProject,
            request,
            onEnd: stdOnEnd(dispatch, 'Create Project ' + name)
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doDeleteProject = (id: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new RemoveProjectRequest();
        request.setProjectid(id);

        // let's get the project name here
        const { projects } = getState().projects;
        const project = projects[id];
        const projectName = project ? project.getName() : 'undefined';

        const grpcAction: GrpcActionPayload<RemoveProjectRequest, RemoveProjectResponse> = {
            methodDescriptor: ProjectListService.RemoveProject,
            request,
            onEnd: stdOnEnd(dispatch, 'Delete Project ' + projectName)
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doListUsers = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new UserListRequest();
        const grpcAction: GrpcActionPayload<UserListRequest, User> = {
            methodDescriptor: ProjectListService.UserList,
            onMessage: (user) => receivedUser(user),
            request,
            onError: stdOnError(dispatch, 'List Users')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doListProjects = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new ProjectListRequest();
        const grpcAction: GrpcActionPayload<ProjectListRequest, AdminProjectListResponse> = {
            methodDescriptor: ProjectListService.AdminProjectList,
            onMessage: (projectList) => receivedAdminProjectList(projectList.getProjectsList()),
            request,
            onError: stdOnError(dispatch, 'List Projects')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doListProjectAccessRights = (projectId: string, onStartCallback: (stream: grpc.Request) => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new ProjectAccessRightsRequest();
        request.setProjectid(projectId);
        const grpcAction: GrpcActionPayload<ProjectAccessRightsRequest, ProjectAccessRightsChangeEvent> = {
            methodDescriptor: ProjectListService.ProjectAccessRights,
            onMessage: (event) => receivedProjectAccessRightsChangeEvent(projectId, event),
            onStart: (stream: grpc.Request) => onStartCallback(stream),
            request,
            onError: stdOnError(dispatch, 'List Project Access Rights')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doChangeUserRole = (projectId: string, user: string, role: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new ChangeUserRoleRequest();
        request.setProjectid(projectId);
        request.setUser(user);
        request.setRole(role);
        const header = role !== '' ? 'Assigned user' : 'Removed user';
        const grpcAction: GrpcActionPayload<ChangeUserRoleRequest, ChangeUserRoleReponse> = {
            methodDescriptor: ProjectListService.ChangeUserRole,
            request,
            onError: stdOnError(dispatch, 'Change User Role'),
            onMessage: stdOnMessage(dispatch,
                                    header,
                                    header + ' **' + user + '**' + (role !== '' ? ' with role **' + role + '**' : '')
            )
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const verifyUser = (user: string, verify: boolean, cb?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new VerifyUserRequest();
        request.setUser(user);
        request.setVerify(verify);
        const grpcAction: GrpcActionPayload<VerifyUserRequest, VerifyUserResponse> = {
            methodDescriptor: ProjectListService.VerifyUser,
            request,
            onMessage: () => {
                if (cb) {
                    cb();
                }
                const header = verify ? 'Verified user' : 'Revoked user';
                const message = header + ' **' + user + '**' + (verify ? ' was verified.' : ' was revoked.');
                dispatch(showInfoNotification(
                    header,
                    message
                ));
            },
            onError: stdOnError(dispatch, 'Verify User')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const promoteUserToAdmin = (user: string, cb?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new PromoteUserToAdminRequest();
        request.setUser(user);
        const grpcAction: GrpcActionPayload<PromoteUserToAdminRequest, PromoteUserToAdminResponse> = {
            methodDescriptor: ProjectListService.PromoteUserToAdmin,
            request,
            onMessage: () => {
                if (cb) {
                    cb();
                }

                const header = 'Promoted user';
                const message = header + ' **' + user + '** to admin.';
                dispatch(showInfoNotification(
                    header,
                    message
                ));
            },
            onError: stdOnError(dispatch, 'Promote User to Admin')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const createUser = (username: string, email: string, verify: boolean, cb?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new CreateUserRequest();
        request.setUsername(username);
        request.setEmail(email);
        request.setVerified(verify);
        const grpcAction: GrpcActionPayload<CreateUserRequest, CreateUserResponse> = {
            methodDescriptor: ProjectListService.CreateUser,
            request,
            onMessage: () => {
                if (cb) {
                    cb();
                }
                const header = 'Created user';
                const message = header + ' **' + username + '** and verified: ' + verify;
                dispatch(showInfoNotification(
                    header,
                    message
                ));
            },
            onError: stdOnError(dispatch, 'Create User')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const removeUser = (user: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new RemoveUserRequest();
        request.setUser(user);
        const grpcAction: GrpcActionPayload<PromoteUserToAdminRequest, RemoveUserResponse> = {
            methodDescriptor: ProjectListService.RemoveUser,
            request,
            onMessage: () => {
                dispatch(userRemoved(user));

                const header = 'Removed user';
                const message = header + ' **' + user;
                dispatch(showInfoNotification(
                    header,
                    message
                ));
            },
            onError: stdOnError(dispatch, 'Remove User')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const changeProjectName = (projectId: string, description: string, newName: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new UpdateProjectNameRequest();
        request.setProjectid(projectId);
        request.setName(newName);
        request.setDescription(description);

        const project = getState().projects.projects[projectId];
        const oldName = project ? project.getName() : 'undefined';

        const grpcAction: GrpcActionPayload<UpdateProjectNameRequest, UpdateProjectNameResponse> = {
            methodDescriptor: ProjectListService.UpdateProjectName,
            request,
            onMessage: stdOnMessage(dispatch,
                                    'Renamed project',
                                    'Renamed project from **' + oldName + '** to **' + newName + '**.'),
            onError: stdOnError(dispatch, 'Change Project Name')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const changeProjectDescription = (projectId: string, projectName: string, newDescription: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new UpdateProjectDescriptionRequest();
        request.setProjectid(projectId);
        request.setName(projectName);
        request.setDescription(newDescription);
        const grpcAction: GrpcActionPayload<UpdateProjectDescriptionRequest, UpdateProjectDescriptionResponse> = {
            methodDescriptor: ProjectListService.UpdateProjectDescription,
            request,
            onMessage: stdOnMessage(dispatch,
                                    'Description modified',
                                    'Description modified to **' + newDescription + '**'),
            onError: stdOnError(dispatch, 'Change Project Description')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export default {
    changeUserRole,
    createProject,
    deleteProject,
    doUnsubscribeListProjects,
    listProjectAccessRights,
    listProjects,
    listUsers,
    listeningProjects,
    projectNameAvailable,
    receiveProjectListChangeEvent,
    receivedProjectAccessRightsChangeEvent,
    receivedUser,
    receivedAdminProjectList,
    userRemoved,
};
