import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';

export interface PageErrorsStore {
    [pageId: string]: string;
}

const initialState = {};

export default function(state: PageErrorsStore = initialState, action: IStoreActions): PageErrorsStore {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.fetchPageErrorsSuccess)) {
            draftState[action.meta] = action.payload;
        }
        if (action.type === getType(actions.fetchPageErrorsBegin)) {
            // clear current annotation cache
            return initialState;
        }
    });
}