import { createAction } from 'typesafe-actions';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';
import { baseURL, loginURL } from '../utils/constants';
import { fetchApi, FetchInfo } from './api';

const loginUrl = window.location.origin + loginURL;

export interface User {
    token: string;
    username: string;
    email: string;
    createdAt: string;
    lastLogin: string;
    loginCount: number;
    verified: boolean;
    admin: boolean;
}

const authenticatingUser = createAction('AUTHENTICATING_USER');

const loginUser = createAction('USER_LOGIN', (resolve) => {
    return (user: User) => resolve(user);
});

export const doLogin = (redirectPath?: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        if (redirectPath) {
            // store the redirectPath for later use
            window.sessionStorage.setItem('redirectPath', redirectPath);
        }
        const tokenFromStorage = window.localStorage.getItem('token');
        if (tokenFromStorage !== null) {
            // we already have a token, let's see if it is valid
            dispatch(authorizeToken(tokenFromStorage));
        } else {
            const loginPath = '/login?targetUrl=' + loginUrl;
            // let's authenticate and ask for new token
/*             const fetchInfo: FetchInfo = {
                extra: { // this is enough that the authorization header is not added
                    redirect: 'manual'
                },
                label: 'foo',
                onFailure: () => {
                    // ok, fetch login failed, lets do window.location.replace()
                    const fullLogin = baseURL + loginPath;
                    window.location.href = fullLogin;
                },
                onSuccess: () => {
                    const token = '';
                    return dispatch(authorizeToken(token));
                },
                request: loginPath,
            };
            dispatch(fetchApi(fetchInfo)); */
            // ok, fetch login failed, lets do window.location.replace()
            const fullLogin = baseURL + loginPath;
            window.location.href = fullLogin;
        }
    };
};

export const authorizeToken = (token: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(authenticatingUser());
        const fetchInfo: FetchInfo = {
            extra: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            },
            label: 'foo',
            onFailure: (error) => {
                // clear possible old token from storage
                window.localStorage.removeItem('token');
                // login
                dispatch(doLogin());
            },
            onSuccess: (response) => {
                // store the token to sessionstorage for later use
                window.localStorage.setItem('token', token);
                // login the user
                response.json().then((data) => {
                    const user: User = {
                        createdAt: data.createdAt,
                        email: data.email,
                        lastLogin: data.lastLogin,
                        loginCount: data.loginCount,
                        token,
                        username: data.username,
                        verified: data.verified,
                        admin: data.admin,
                    };
                    dispatch(loginUser(user));
                    // redirect happens via auth.ts authWrapper
                });
            },
            request: '/me',
        };
        dispatch(fetchApi(fetchInfo));
    };
};

export const doLogout = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const tokenFromStorage = window.localStorage.getItem('token');
        if (tokenFromStorage !== null) {
            window.localStorage.removeItem('token');
            // we already have a token, let's see if it is valid
        }
        const loginPath = '/logout';

        const fetchInfo: FetchInfo = {
            label: 'foo',
            onFailure: () => {
                // ok, fetch login failed, lets do window.location.replace()
                const fullLogin = baseURL + loginPath;
                window.location.href = fullLogin;
            },
            onSuccess: () => {
                    const token = '';
                    return dispatch(authorizeToken(token));
            },
            request: loginPath,
        };
        dispatch(fetchApi(fetchInfo));

    };
};

export default {
    authenticatingUser,
    loginUser,
    userLogout: createAction('USER_LOGOUT')
};
