import { grpc } from "@improbable-eng/grpc-web";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import { createAction } from "typesafe-actions";
import { JobQueueStatusEvent } from "../proto/projectList_pb";
import { ProjectListService } from "../proto/projectList_pb_service";
import { StoreState } from "../store";
import { ThunkDispatch, ThunkResult } from "../store-types";
import { GrpcActionPayload, grpcRequest } from "./grpc";
import { stdOnError } from "./grpcutils";

const receivedJobQueueStatusEvent = createAction('RECEIVED_JOB_QUEUE_STATUS_EVENT', (resolve) => {
    return (event: JobQueueStatusEvent) => resolve(event);
});

const subscribedJobQueueStatus = createAction('SUBSCRIBED_JOB_QUEUE_STATUS', (resolve) => {
    return (request: grpc.Request) => resolve(request);
});

const unsubscribedJobQueueStatus = createAction('UNSUBSCRIBED_JOB_QUEUE_STATUS', (resolve) => {
    return () => resolve({});
});

export const subscribeJobQueue = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        const grpcAction: GrpcActionPayload<Empty, JobQueueStatusEvent> = {
            methodDescriptor: ProjectListService.JobQueueStatus,
            onStart: (request) => subscribedJobQueueStatus(request),
            onMessage: (event) => receivedJobQueueStatusEvent(event),
            onError: (code, msg) => {
                return stdOnError(dispatch, 'Listening job queue')(code, msg);
            },
            request: new Empty(),
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const unsubscribeJobQueue = (): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { jobqueue } = getState();
        if(jobqueue.subscription !== null) {
            jobqueue.subscription.close();
            dispatch(unsubscribedJobQueueStatus());
        }
    };
};

export default {
    receivedJobQueueStatusEvent,
    subscribedJobQueueStatus,
    unsubscribedJobQueueStatus
}
