import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { authorizeToken, doLogin } from '../../actions/users';
import { StoreState } from '../../store';

import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { ThunkDispatch } from '../../store-types';

const locationHelper = locationHelperBuilder({});

interface LoginProps {
    location: Location;
}

interface LoginDispatchProps {
    doAuthorizeToken: (token: string) => void;
    doLogin: (key: string) => void;
}

class Login extends React.Component<LoginProps & LoginDispatchProps> {
    public componentDidMount() {
        // let's see if url contains #token=<value>
        const location: Location = this.props.location;

        const hash: string = location.hash;
        if (hash && hash.indexOf('token=') > -1) {
            const token = hash.substring(1, hash.length).replace('token=', '');
            this.props.doAuthorizeToken(token);
        } else {
            const redirectQueryParam = locationHelper.getRedirectQueryParam(this.props);
            this.props.doLogin(redirectQueryParam);
        }
    }

    public render() {
        return (
            <div>Redirecting...</div>
        );
    }
}

function mapStateToProps(state: StoreState): LoginProps {
    return {
        location: state.router.location,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): LoginDispatchProps {
    return {
        doAuthorizeToken: (token: string) => {
            dispatch(authorizeToken(token));
        },
        doLogin: (redirectPath?: string) => {
            dispatch(doLogin(redirectPath));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);