import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions, { Notification } from '../actions/notifications';

export interface NotificationStore {
    [id: string]: Notification;
}

const initialState: NotificationStore = {};

export default function(state: NotificationStore = initialState, action: IStoreActions): NotificationStore {
    return produce(state, (draftState) => {
        switch (action.type) {
            case getType(actions.notificationClear):
            case getType(actions.notificationDismiss):
                delete draftState[action.payload];
                break;
            case getType(actions.notificationShow):
                draftState[action.payload.id] = action.payload;
                break;
            default:
                break;
        }
    });
}

/*
const handleProjectChangeEvent = (state: Notification[], changeEvent: ProjectChangeEvent): Notification[] => {
    const targetCollection: TargetCollection = changeEvent.getTargetcollection();
    switch (targetCollection) {
        case TargetCollection.MESSAGES:
            return handleMessagesEvent(state, changeEvent);
        default:
            return state;
    }
};

const handleMessagesEvent = (state: Notification[], changeEvent: ProjectChangeEvent): Notification[] => {
    switch (changeEvent.getChangeoperation()) {
        case ChangeOperation.INSERT:
            return handleMessagesInsert(state, changeEvent);
        case ChangeOperation.DELETE:
            return handleMessagesDelete(state, changeEvent);
        default:
            return state;
    }
};

const handleMessagesInsert = (state: Notification[], changeEvent: ProjectChangeEvent): Notification[] => {
    Logger.info('messages insert');
//    const message: Message = changeEvent.getMessage()!;
//    const notification: Notification = {
//        header: 'Message',
//        message: message.getContent(),
//        type: NotificationType.INFO,
//        dismissAfter: 5000,
//        id: changeEvent.getContentid(),
//    };
//    
//    return showNotification(state, notification);
    return state;
};

const handleMessagesDelete = (state: Notification[], changeEvent: ProjectChangeEvent): Notification[] => {
    Logger.info('messages delete');
    return state;
};
*/