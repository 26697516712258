import yaml from 'js-yaml';
import { MetamodelDefinition, getSymbolType, Metamodel } from './Metamodel';

export interface TargetPatternDefinitionP {
    targetPattern: TargetPattern;
    target: string;
    mappable: boolean;
    editable: boolean;
}

export interface TargetPattern {
    id: string; // ref to 'pdfpattern'
    units: TargetUnit[]; // currently can assume just one unit here
    inputPoints: Terminal[];
    outputPoints: Terminal[];
}

export interface TargetUnit {
    id: string;
    symbolId?: string; // actual name in target app
    attributes: TargetAttribute[];

    // For PLCopen only
    type?: string;

    // For Proteus only
    element?: string;
    componentClass?: string;
    componentName?: string;
}

export interface Terminal {
    id: string; // ref to terminal id 'pdfpattern', such as 'input1' or 'output2'
    terminalId: string; // actual name in target app
    plcOpenType?: string; // for plcopen only
    index?: number; // matters for dcs only, default value 1

    // these can always be given the same values as long as patterns have only one unit and no signals
    type: string; // set to 'terminal'
    unitId: string; // set this to match with targetPattern.units[0].id

    sheetConnectionTerminal?: boolean;
    inSheetConnectionTerminal?: boolean;
}

export interface TargetAttribute {
    name?: string; // either name or terminal must be given
    terminal?: string;
    textId?: string;
    script?: string;
    defaultValue?: string;
    defaultListValue?: string[];
}

export function matchingAttribute(attribute: TargetAttribute, name: string | undefined, terminal: string | undefined): boolean {
    if (attribute.name) {
        if (name) {
            return attribute.name === name;
        }
        return false;
    }
    if (attribute.terminal && terminal) {
        return attribute.terminal === terminal;
    }
    return false;
}

export function isInputTerminalId(targetPattern: TargetPattern, id: string | undefined): boolean {
    return targetPattern.inputPoints.some(t => t.id === id);
}



const DEFAULT_UNIT_ID = 's1';

export const PLC_OPEN_UNIT_TYPES = ['functionBlock', 'function',
                                'inVariable', 'outVariable',
                                'inVariableGlobal', 'outVariableGlobal',
                                'constant',
                                'connector', 'continuation',
                                'leftPowerRail', 'rightPowerRail',
                                'coil', 'contact', 'coilGlobal', 'contactGlobal'];

export const PLC_OPEN_TERMINAL_TYPES = ['BOOL', 'BYTE',
                                        'WORD', 'DWORD', 'LWORD',
                                        'SINT', 'INT', 'DINT', 'LINT', 'USINT', 'UINT', 'UDINT', 'ULINT',
                                        'REAL', 'LREAL',
                                        'TIME', 'DATE', 'DT', 'TOD',
                                        'string', 'wstring',
                                        'ANY', 'ANY_DERIVED', 'ANY_ELEMENTARY',
                                        'ANY_MAGNITUDE', 'ANY_NUM', 'ANY_REAL', 'ANY_INT',
                                        'ANY_BIT', 'ANY_STRING', 'ANY_DATE'];

export const PROTEUS_ELEMENT_TYPES = ['Equipment', 'InstrumentComponent',
                                        'ProcessInstrument', 'PipingComponent', 'Nozzle',
                                        'PipeConnectorSymbol', 'SignalConnectorSymbol',
                                        'PropertyBreak', 'PipeFlowArrow', 'Label'];
export interface BoundingBox {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}

export function getTargetPatternBoundingBox(targetPattern: TargetPattern, includeTexts: boolean, metamodel?: Metamodel): BoundingBox {
    if (metamodel) {
        const symbolName = targetPattern.units[0].symbolId;
        const symbolType = getSymbolType(symbolName!, metamodel);
        if (!symbolType) {
            return {minX: 0, minY: 0, maxX: 0, maxY: 0};
        }
        const bb = symbolType.boundingBox;
        if (includeTexts) {
            const xPadding = Math.max(bb[2] - bb[0], bb[3] - bb[1]);
            return {minX: bb[0] - xPadding, minY: bb[1], maxX: bb[2] + xPadding, maxY: bb[3]};
        }

        return {minX: bb[0], minY: bb[1], maxX: bb[2], maxY: bb[3]};
    }
    const maxX = includeTexts ? 50 + 30 : 50;
    const maxY = 10 + 10 * Math.max(targetPattern.inputPoints.length, targetPattern.outputPoints.length);
    const minX = includeTexts ? -30 : 0;
    const minY = 0;
    return { minX, minY, maxX, maxY};
}

export const NEW_PATTERN_NAME = '(new pattern)';

export function getDefaultPLCOpenTargetPattern(): TargetPattern {
    const input: Terminal = {
        id: 'input1',
        terminalId: 'IN1',
        unitId: DEFAULT_UNIT_ID,
        type: 'terminal'
    };
    const output: Terminal = {
        id: 'output1',
        terminalId: 'OUT',
        unitId: DEFAULT_UNIT_ID,
        type: 'terminal'
    };
    const unit: TargetUnit = {
        id: DEFAULT_UNIT_ID,
        symbolId: '(name)',
        attributes: [],
        type: 'functionBlock'
    };
    return {
        id: NEW_PATTERN_NAME,
        units: [unit],
        inputPoints: [input],
        outputPoints:[output]
    };
}

export function getDefaultTargetPattern(): TargetPattern {
    const unit: TargetUnit = {
        id: DEFAULT_UNIT_ID,
        symbolId: ' ',
        attributes: []
    };
    return {
        id: NEW_PATTERN_NAME,
        units: [unit],
        inputPoints: [],
        outputPoints:[]
    };
}

export function getDefaultProteusTargetPattern(): TargetPattern {
    const unit: TargetUnit = {
        id: DEFAULT_UNIT_ID,
        element: 'Equipment',
        attributes: []
    };
    return {
        id: NEW_PATTERN_NAME,
        units: [unit],
        inputPoints: [],
        outputPoints:[]
    };
}

export function parseTargetPattern(patternText: string) {
    const obj = yaml.safeLoad(patternText);

    if (!obj.id)
        obj.id = "";
    if (!obj.units)
        obj.units = [];
    if (!obj.inputPoints)
        obj.inputPoints = [];
    if (!obj.outputPoints)
        obj.outputPoints = [];

    return obj as TargetPattern;
}

export function targetPatternToString(targetPattern: TargetPattern, target: string) {
    const dumpObject = target !== 'Proteus' ? targetPattern : getProteusPatternForYamlDump(targetPattern);
    return yaml.safeDump(dumpObject, { skipInvalid: true });
}

export function targetPatternsToString(targetPatterns: TargetPattern[], target: string) {
    const dumpObject = target !== 'Proteus' ? targetPatterns : targetPatterns.map((p) => getProteusPatternForYamlDump(p));
    return yaml.safeDump(dumpObject, { skipInvalid: true });
}

// The input/output point fields are automatically initialized to empty arrays
// but actually should not be included in the proteus pattern at all
function getProteusPatternForYamlDump(targetPattern: TargetPattern) {
    const obj = JSON.parse(JSON.stringify(targetPattern));
    delete obj.inputPoints;
    delete obj.outputPoints;
    return obj;
}
