import produce from 'immer';
import yaml from 'js-yaml';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { ChangeOperation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';

export interface TargetConfigurationP {
    configuration: any;
    target: string;
    schema: any;
}

export interface TargetConfigurationsStore {
    targetConfigurations: ChangeEventStore<TargetConfigurationP>;
}

function composed(
    state: ChangeEventStore<TargetConfigurationP>,
    action: IStoreActions
): ChangeEventStore<TargetConfigurationP> {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectChangeEvent)) {
            if (action.payload.getTargetcollection() === TargetCollection.TARGETCONFIGURATIONS) {
                const changeEvent = action.payload;
                const key = keyFunc(changeEvent);
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE) {
                    delete draftState[key];
                } else {
                    const targetConfiguration = changeEvent.getTargetconfiguration();
                    if (targetConfiguration) {
                        draftState[key] = {
                            configuration: yaml.safeLoad(targetConfiguration.getValue()),
                            target: targetConfiguration.getTarget(),
                            schema: JSON.parse(targetConfiguration.getSchema())
                        };
                    }
                }
            }
        } else if (action.type === getType(actions.unsubscribe)) {
            return {};
        }
    });
}

const keyFunc = (changeEvent: ProjectChangeEvent) => changeEvent.getContentid();

const initialState: TargetConfigurationsStore = {
    targetConfigurations: {}
};

export default function (state: TargetConfigurationsStore = initialState, action: IStoreActions): TargetConfigurationsStore {
    return produce(state, (draftState) => {
        /*
        if (action.type === getType(updateTargetPattern)) {
            draftState.modifiedTargetPatternDefinitions = {
                ...draftState.modifiedTargetPatternDefinitions,
                [action.payload.targetPatternId]: action.payload.targetPattern
            };
        }
        else if (action.type === getType(clearModifiedTargetPatterns)) {
            draftState.modifiedTargetPatternDefinitions = {};
        }
        else if (action.type === getType(clearModifiedTargetPattern)) {
            draftState.modifiedTargetPatternDefinitions = { ...draftState.modifiedTargetPatternDefinitions };
            delete draftState.modifiedTargetPatternDefinitions[action.payload];
        }
        else
        */
        //draftState.targetConfigurations = state.targetConfigurations;
        draftState.targetConfigurations = composed(state.targetConfigurations, action);
    });
}
