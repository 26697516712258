import { createAction } from 'typesafe-actions';
import { ObjectPartAssignment, PagePartition, PagePart } from '../proto/projectChangeEvents_pb';
import { UpdatePagePartitionRequest, UpdatePartLabelRequest } from '../proto/project_pb';
import { ProjectService } from '../proto/project_pb_service';
import { ComponentSelection } from '../reducers/painting';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';
import { grpcRequest } from './grpc';
import { stdOnError } from './grpcutils';

export enum SelectionChangeType {
    SET, MERGE, TOGGLE
}

export const changeSelection = createAction('CHANGE_PAINTING_SELECTION', (resolve) => {
    return (selection: ComponentSelection, changeType: SelectionChangeType) => resolve({ selection, changeType });
});

export const clearSelection = createAction('CLEAR_PAINTING_SELECTION');

function withAssignedPart(assignment: ObjectPartAssignment, partId: string): ObjectPartAssignment {
    const newAssignment = new ObjectPartAssignment();
    newAssignment.setBoundingbox(assignment.getBoundingbox());
    newAssignment.setSymbol(assignment.getSymbol());
    newAssignment.setAssignedpart(partId);
    return newAssignment;
}

export const paintSelected = (projectId: string, pageId: string, partId: string) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const state = getState();
        const oldPartition = Object.values(state.pagepartitions).find((part) => part.getPage() === pageId);
        const selection = state.painting.selection;
        if (!oldPartition || !selection || selection.pageId !== pageId) {
            return;
        }
        const selectedItems = selection.selectedItems;

        const partition = new PagePartition();
        partition.setPage(pageId);
        partition.setPartsList(oldPartition.getPartsList());
        partition.setAssignmentsList(oldPartition.getAssignmentsList().map((assignment, index) => {
            if (selectedItems.includes(index)) {
                return withAssignedPart(assignment, partId);
            } else {
                return assignment;
            }
        }));

        const request = new UpdatePagePartitionRequest();
        request.setProjectid(projectId);
        request.setPartition(partition);

        const grpcAction = {
            methodDescriptor: ProjectService.UpdatePagePartition,
            request,
            onError: stdOnError(dispatch, 'Set Page Partition')
        };
        dispatch(grpcRequest(grpcAction));
        dispatch(clearSelection());
    };
};

export const removePart = (projectId: string, pageId: string, partId: string) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const oldPartition = Object.values(getState().pagepartitions).find((part) => part.getPage() === pageId);
        if (!oldPartition) {
            return;
        }

        const partition = new PagePartition();
        partition.setPage(pageId);
        partition.setPartsList(oldPartition.getPartsList().filter((part) => part.getId() !== partId));
        partition.setAssignmentsList(oldPartition.getAssignmentsList().map((assignment) => {
            if (assignment.getAssignedpart() === partId) {
                return withAssignedPart(assignment, '');
            } else {
                return assignment;
            }
        }));

        const request = new UpdatePagePartitionRequest();
        request.setProjectid(projectId);
        request.setPartition(partition);

        const grpcAction = {
            methodDescriptor: ProjectService.UpdatePagePartition,
            request,
            onError: stdOnError(dispatch, 'Remove Part')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const changePartLabel = (projectId: string, pageId: string, partId: string, newLabel: string) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new UpdatePartLabelRequest();
        request.setProjectid(projectId);
        request.setPageid(pageId);
        request.setPartid(partId);
        request.setNewlabel(newLabel);

        const grpcAction = {
            methodDescriptor: ProjectService.UpdatePartLabel,
            request,
            onError: stdOnError(dispatch, 'Update Part Label')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doUpdatePartsList = (projectId: string, pageId: string, parts: PagePart[]) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const oldPartition = Object.values(getState().pagepartitions).find((part) => part.getPage() === pageId);
        if (!oldPartition) {
            return;
        }

        const partition = new PagePartition();
        partition.setPage(pageId);
        partition.setPartsList(parts);
        partition.setAssignmentsList(oldPartition.getAssignmentsList());

        const request = new UpdatePagePartitionRequest();
        request.setProjectid(projectId);
        request.setPartition(partition);

        const grpcAction = {
            methodDescriptor: ProjectService.UpdatePagePartition,
            request,
            onError: stdOnError(dispatch, 'Update Parts List')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export default {
    changeSelection,
    clearSelection,
};