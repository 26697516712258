import produce from 'immer';
import { PDFPageProxy } from 'pdfjs-dist';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/pdfpages';

export interface ResponseStore {
    [pageId: string]: PDFPageProxy;
}

const initialState = {};

export default function(state: ResponseStore = initialState, action: IStoreActions): ResponseStore {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.processPdfPageSuccess)) {
            draftState[action.meta] = action.payload;
        }
    });
}