import { createAction } from 'typesafe-actions';
import { RemoveProjectRequest, RemoveProjectResponse } from '../proto/projectList_pb';
import { ProjectListService } from '../proto/projectList_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { showErrorNotification, showInfoNotification } from './notifications';

const startProjectRemoval = createAction('START_PROJECT_REMOVAL', (resolve) => {
    return (project: string, knownActiveUsersCount: number) => resolve({project, knownActiveUsersCount});
});

function confirmProjectRemoval() {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { project, knownActiveUsersCount } = getState().projectremoval;

        const { projects } = getState().projects;
        const projectEntry = projects[project];
        const projectName = projectEntry ? projectEntry.getName() : 'undefined'; 

        const request = new RemoveProjectRequest();
        request.setProjectid(project);
        request.setRemoveevenwhenactive(knownActiveUsersCount > 0);

        const grpcAction: GrpcActionPayload<RemoveProjectRequest, RemoveProjectResponse> = {
            methodDescriptor: ProjectListService.RemoveProject,
            onMessage: (response) => {
                if (response.getHadactiveusers() > 0) {
                    return startProjectRemoval(project, response.getHadactiveusers());
                } else {
                    dispatch(cancelProjectRemoval());
                    dispatch(showInfoNotification(
                        'Project Removed',
                        'Removed project **' + projectName + '**.'
                    ));            
                }
            },
            onError: (code, message) => {
                dispatch(cancelProjectRemoval());
                dispatch(showErrorNotification(
                    'Project Removal Failed',
                    message
                ));
            },
            request,
        };
        // let's get the project name here
        dispatch(grpcRequest(grpcAction));
    };
}

const cancelProjectRemoval = createAction('CANCEL_PROJECT_REMOVAL', (resolve) => {
    return () => resolve();
});

export default {
    startProjectRemoval,
    cancelProjectRemoval,
    confirmProjectRemoval,
};