import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/viewers';

export interface ViewersState {
    scale: number;
    x: number;
    y: number;
    scaleResult: boolean;
    patternDefinitionHidingMode: HidingMode;
}

export enum HidingMode {
    DONT_HIDE,
    HIDE_INTERPRETATION,
    HIGHLIGHT_INTERPRETATION
}

const initialState = {
    scale: 1,
    x: 0,
    y: 0,
    scaleResult: true,
    patternDefinitionHidingMode: HidingMode.HIDE_INTERPRETATION,
};

export default function (state: ViewersState = initialState, action: IStoreActions): ViewersState {
    switch (action.type) {
        case getType(actions.changeScale):
            return produce(state, (draftState) => {
                draftState.scale = action.payload;
            });
        case getType(actions.changeX):
            return produce(state, (draftState) => {
                draftState.x = action.payload;
            });
        case getType(actions.changeY):
            return produce(state, (draftState) => {
                draftState.y = action.payload;
            });
        case getType(actions.setScaleResult):
            return produce(state, (draftState) => {
                draftState.scaleResult = action.payload;
            });
        case getType(actions.setPatternDefinitionHidingMode):
            return produce(state, (draftState) => {
                draftState.patternDefinitionHidingMode = action.payload;
            });
        default:
            return state;
    }
}