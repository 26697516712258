
import * as React from 'react';
import { connect } from 'react-redux';
import { Portal, Transition } from 'semantic-ui-react';
import actions from '../actions/notifications';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';
import { arraysEqual } from '../utils/functions';
import NotificationJSX from './NotificationJSX';

interface OwnProps {
}

interface StatePropsFromStore {
    notificationIds: string[];
}

interface DispatchProps {
    onDismissNotification: (id: string) => void;
}

type Props = OwnProps & StatePropsFromStore & DispatchProps;

class NotificationManager extends React.Component<Props> {
    public dismissNotification = (id: string) => {
        this.props.onDismissNotification(id);
    }

    public shouldComponentUpdate(nextProps: Props) {
        return !arraysEqual(this.props.notificationIds, nextProps.notificationIds);
    }

    public render() {
        const { notificationIds } = this.props;
        const renderedNotifications = notificationIds.map( (id: string) => {
            return (
                <NotificationJSX
                    key={id}
                    id={id}
                    onCloseClick={this.dismissNotification}
                />
            );
        });
        return (
            <Portal open={true} key="notificationsPortal">
                <div
                    style={{
                        bottom       : '10px',
                        margin    : 'auto',
                        maxWidth : '400px',
                        position  : 'absolute',
                        right     : '10px',
                        width     : '80%',
                        zIndex   : 2000,
                    }}
                >
                    <Transition.Group
                        as="div"
                        duration={400}
                        animation={'fade left'}
                    >
                        {renderedNotifications}
                    </Transition.Group>
                </div>
            </Portal>
        );
    }
}

function mapStateToProps(state: StoreState, ownProps: OwnProps): StatePropsFromStore {
    const notificationIds = Object.keys(state.notifications);
    return {
        notificationIds,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchProps {
    return {
        onDismissNotification: (id: string) => {
            dispatch(actions.notificationDismiss(id));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManager);