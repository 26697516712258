import { grpc } from '@improbable-eng/grpc-web';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { createAction } from 'typesafe-actions';
import { ProjectChangeEvent } from '../proto/projectChangeEvents_pb';
import { CancelTranslationRequest, CancelTranslationResponse, ChangeAllPageSelectionsRequest, ChangeAllPageSelectionsResponse, ChangeSinglePageSelectionRequest, ChangeSinglePageSelectionResponse, ProjectChangeEventRequest, PropertyNameAndValue, RemoveSourceFileRequest, RemoveSourceFileResponse, TranslateRequest, TranslateResponse } from '../proto/project_pb';
import { ProjectService } from '../proto/project_pb_service';
import { AnnotationCollection } from '../reducers/annotations';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';
import { fetchApi, FetchInfo } from './api';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { stdOnEnd, stdOnError, stdOnMessage } from './grpcutils';
import { showErrorNotification, showInfoNotification } from './notifications';
import pdfActions from './pdfpages';

// change events
const subscribee = createAction('SUBSCRIBE', (resolve) => {
    return () => resolve();
});
const subscribed = createAction('SUBSCRIBED', (resolve) => {
    return (projectId: string, stream: grpc.Request) => resolve({ projectId, stream });
});
const unsubscribe = createAction('UNSUBSCRIBE', (resolve) => {
    return (projectId: string, reason: string | null) => resolve({ projectId, reason });
});
const receivedProjectChangeEvent = createAction('RECEIVED_PROJECT_CHANGE_EVENT', (resolve) => {
    return (event: ProjectChangeEvent) => resolve(event);
});
// feth page data
const fetchPageDataBegin = createAction('FETCH_PAGE_DATA', (resolve) => {
    return (pageId: string) => resolve(pageId);
});
const fetchPageDataSuccess = createAction('FETCH_PAGE_DATA_SUCCESS', (resolve) => {
    return (data: ArrayBuffer, meta?: string) => resolve(data, meta);
});
const fetchPageDataFailure = createAction('FETCH_PAGE_DATA_FAILURE', (resolve) => {
    return (error: Error, meta?: string) => resolve(error, meta);
});
// feth page annotations
const fetchAnnotationsBegin = createAction('FETCH_ANNOTATIONS', (resolve) => {
    return (pageId: string) => resolve(pageId);
});
const fetchAnnotationsSuccess = createAction('FETCH_ANNOTATIONS_SUCCESS', (resolve) => {
    return (data: AnnotationCollection, meta?: string) => resolve(data, meta);
});
const fetchAnnotationsFailure = createAction('FETCH_ANNOTATIONS_FAILURE', (resolve) => {
    return (error: Error, meta?: string) => resolve(error, meta);
});
// feth page errors
const fetchPageErrorsBegin = createAction('FETCH_PAGE_ERRORS', (resolve) => {
    return (pageId: string) => resolve(pageId);
});
const fetchPageErrorsSuccess = createAction('FETCH_PAGE_ERRORS_SUCCESS', (resolve) => {
    return (data: string, meta?: string) => resolve(data, meta);
});
const fetchPageErrorsFailure = createAction('FETCH_PAGE_ERRORS_FAILURE', (resolve) => {
    return (error: Error, meta?: string) => resolve(error, meta);
});
// translate
// const translateBegin = createAction('TRANSLATE_BEGIN');
const translateSuccess = createAction('TRANSLATE_SUCCESS', (resolve) => {
    return (response: TranslateResponse, meta?: string) => resolve(response, meta);
});
const translateFailure = createAction('TRANSLATE_FAILURE', (resolve) => {
    return (error: Error, meta?: string) => resolve(error, meta);
});
// results
const showResults = createAction('TOGGLE_SHOW_RESULTS', (resolve) => {
    return (value: boolean) => resolve(value);
});
// delete source file
const deleteSourceFile = createAction('DELETE_SOURCE_FILE', (resolve) => {
    return () => resolve();
});

// change if page is selected for translation or not
const changePageSelectionAction = createAction('CHANGE_PAGE_SELECTION');
const changeAllPageSelectionAction = createAction('CHANGE_ALL_PAGE_SELECTION');

// properties
const setPropertyValue = createAction('SET_PROPERTY_VALUE', (resolve) => {
    return () => resolve();
});

export const changePageSelection = (projectId: string, pageId: string, selected: boolean): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const changePageSelectionRequest = new ChangeSinglePageSelectionRequest();
        changePageSelectionRequest.setProjectid(projectId);
        changePageSelectionRequest.setPage(pageId);
        changePageSelectionRequest.setSelect(selected);

        const header = selected ? 'Including page' : 'Excluding page';
        const { pages } = getState();
        const page = pages[pageId];
        const message = header + ' **' + (page ? page.getPagenumber() : '?') + '** ' + (selected ? 'to' : 'from') + ' translation';

        const grpcAction: GrpcActionPayload<ChangeSinglePageSelectionRequest, ChangeSinglePageSelectionResponse> = {
            methodDescriptor: ProjectService.ChangeSinglePageSelection,
            request: changePageSelectionRequest,
            onMessage: stdOnMessage(dispatch, header, message),
            onError: stdOnError(dispatch, 'Change Page Selection ' + page.getPagenumber()),
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const changeAllPageSelection = (projectId: string, selected: boolean): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const changePageSelectionRequest = new ChangeAllPageSelectionsRequest();
        changePageSelectionRequest.setProjectid(projectId);
        changePageSelectionRequest.setSelect(selected);

        const grpcAction: GrpcActionPayload<ChangeAllPageSelectionsRequest, ChangeAllPageSelectionsResponse> = {
            methodDescriptor: ProjectService.ChangeAllPageSelections,
            request: changePageSelectionRequest,
            onError: stdOnError(dispatch, 'Change Page Selection')
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doUnsubscribe = (projectId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        dispatch(unsubscribe(projectId, null));
    };
};

export const subscribe = (projectId: string, callback?: () => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        const request = new ProjectChangeEventRequest();
        request.setProjectid(projectId);

        const grpcAction: GrpcActionPayload<ProjectChangeEventRequest, ProjectChangeEvent> = {
            batch: true,
            methodDescriptor: ProjectService.ProjectChangeEvents,
            onMessage: (event) => receivedProjectChangeEvent(event),
            onStart: (stream) => {
                if (callback) {
                    callback();
                }
                return subscribed(projectId, stream);
            },
            onError: (code, msg) => {
                if (msg.length === 0) {
                    switch (code) {
                        case grpc.Code.PermissionDenied:
                            msg = "Permission denied";
                            break;
                        case grpc.Code.InvalidArgument:
                            msg = "Invalid project name";
                            break;

                    }
                }
                dispatch(unsubscribe(projectId, msg));
                if (code === grpc.Code.Unknown) {
                    dispatch(subscribe(projectId));
                }
                return stdOnError(dispatch, 'Project Change Listening')(code, msg);
            },
            onEnd: (code) => {
                if (code === grpc.Code.OK) {
                    dispatch(unsubscribe(projectId, "Removed"));
                    dispatch(showErrorNotification(
                        'Project Change Stream Finished',
                        'The project might have been removed.'
                    ));
                }
            },
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const selectPage = (projectId: string, pageId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        // first let's see if we already have page response for id
        const cachedresponse = Object.entries(getState().responses).find(([rpageId, response]) => pageId === rpageId);
        if (cachedresponse) {
            dispatch(pdfActions.processPdfPageSuccess(cachedresponse[1], pageId));
        } else {
            dispatch(fetchPageDataBegin(pageId));

            const request: FetchInfo = {
                label: 'foo',
                meta: pageId,
                onFailure: (err, meta) => dispatch(fetchPageDataFailure(err, meta)),
                onSuccess: (response, meta) => {
                    response.arrayBuffer().then((data) => {
                        dispatch(fetchPageDataSuccess(data, meta));
                    });
                },
                request: '/' + projectId + '/pages/' + pageId,
            };
            dispatch(fetchApi(request));
        }
    };
};

export const doFetchPageThumbnail = (thumbnail: string, cb?: (imageUrl: string) => void): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        const request: FetchInfo = {
            label: 'foo',
            onSuccess: (response, meta) => {
                response.blob().then((blob) => {
                    const urlCreator = window.URL;
                    const imageUrl = urlCreator.createObjectURL(blob);
                    if (cb) {
                        cb(imageUrl);
                    }
                });
            },
            request: thumbnail
        };
        dispatch(fetchApi(request));
    };
};

export const removeSourceFile = (projectId: string, key: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {
        dispatch(showInfoNotification('Delete', 'Deleting source file with id ' + key));
        const request: RemoveSourceFileRequest = new RemoveSourceFileRequest();
        request.setProjectid(projectId);
        request.setSourcefile(key);

        const grpcAction: GrpcActionPayload<RemoveSourceFileRequest, RemoveSourceFileResponse> = {
            methodDescriptor: ProjectService.RemoveSourceFile,
            request,
            onEnd: stdOnEnd(dispatch, 'Remove Source File'),
        };

        dispatch(grpcRequest(grpcAction));
    };
};

export const translate = (projectId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new TranslateRequest();
        request.setProjectid(projectId);

        const grpcAction: GrpcActionPayload<TranslateRequest, TranslateResponse> = {
            methodDescriptor: ProjectService.Translate,
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const forceCancelTranslate = (projectId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new CancelTranslationRequest();
        request.setProjectid(projectId);
        request.setTranslationid("");
        request.setForce(true);

        const grpcAction: GrpcActionPayload<CancelTranslationRequest, CancelTranslationResponse> = {
            methodDescriptor: ProjectService.CancelTranslation,
            request,
            onEnd: stdOnEnd(dispatch, 'Cancel Translation'),
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const cancelTranslate = (projectId: string, translationId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new CancelTranslationRequest();
        request.setProjectid(projectId);
        request.setTranslationid(translationId);
        request.setForce(false);

        const grpcAction: GrpcActionPayload<CancelTranslationRequest, CancelTranslationResponse> = {
            methodDescriptor: ProjectService.CancelTranslation,
            request,
            onEnd: stdOnEnd(dispatch, 'Cancel Translation'),
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const doSetPropertyValue = (projectId: string, propertyName: string, propertyValue: string) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new PropertyNameAndValue();
        request.setProjectid(projectId);
        request.setName(propertyName);
        request.setValue(propertyValue);
        const grpcAction = {
            methodDescriptor: ProjectService.SetPropertyValue,
            request,
            onError: stdOnError(dispatch, 'Set Property')
        };
        dispatch(grpcRequest(grpcAction));

        dispatch(
            showInfoNotification(
                'Changing ' + propertyName,
                'Changing property **' + propertyName + '** to value **' + propertyValue + '**'
            )
        );
    };
};

let currentFreshId = 0;

function addFeatureIds<G extends Geometry | null = Geometry, P = GeoJsonProperties>(feature: Feature<G, P>) {
    return {
        ...feature,
        id: feature.id || currentFreshId++
    };
}

function addAnnotationCollectionIds(annotationCollection: AnnotationCollection) {
    return {
        ...annotationCollection,
        features: annotationCollection.features.map(addFeatureIds)
    };
}

export const fetchAnnotations = (projectId: string, pageId: string, pageTranslationId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {

        dispatch(fetchAnnotationsBegin(pageTranslationId));

        const request: FetchInfo = {
            label: 'fetch annotations',
            meta: pageId,
            onFailure: (err, meta) => dispatch(fetchAnnotationsFailure(err, meta)),
            onSuccess: (response, meta) => {
                response.json().then((data) => {
                    dispatch(fetchAnnotationsSuccess(addAnnotationCollectionIds(data), meta));
                });
            },
            request: '/' + projectId + '/pageTranslations/' + pageTranslationId + '/Annotations',
        };
        dispatch(fetchApi(request));
    };
};

export const doFetchPageErrors = (projectId: string, pageId: string, pageTranslationId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch) => {

        dispatch(fetchPageErrorsBegin(pageTranslationId));

        const request: FetchInfo = {
            label: 'fetch page errors',
            meta: pageId,
            onFailure: (err, meta) => dispatch(fetchPageErrorsFailure(err, meta)),
            onSuccess: (response, meta) => {
                response.text().then((data) => {
                    dispatch(fetchPageErrorsSuccess(data, meta));
                });
            },
            request: '/' + projectId + '/pageTranslations/' + pageTranslationId + '/ErrorMessage',
        };
        dispatch(fetchApi(request));
    };
};

export default {
    changePageSelectionAction,
    changeAllPageSelectionAction,
    deleteSourceFile,
    fetchAnnotationsBegin,
    fetchAnnotationsFailure,
    fetchAnnotationsSuccess,
    fetchPageDataBegin,
    fetchPageDataFailure,
    fetchPageDataSuccess,
    fetchPageErrorsBegin,
    fetchPageErrorsFailure,
    fetchPageErrorsSuccess,
    receivedProjectChangeEvent,
    setPropertyValue,
    showResults,
    subscribed,
    subscribee,
    //    translateBegin,
    translateFailure,
    translateSuccess,
    unsubscribe,
};
