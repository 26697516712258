import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { addPatternTestResult, clearModifiedPattern, clearModifiedPatterns, clearPatternTestResults, updatePattern } from '../actions/patterns';
import { parsePattern, Pattern, PatternDefinitionP } from '../components/patterns/Pattern';
import { PatternTestResult } from '../components/patterns/PatternTestResult';
import { ChangeOperation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';

export interface PatternDefinitionsStore {
    patternDefinitions: ChangeEventStore<PatternDefinitionP>;
    modifiedPatternDefinitions: ChangeEventStore<Pattern>;
    patternTestResults: { [key: string]: PatternTestResult[] };
}

function composed(
    state: ChangeEventStore<PatternDefinitionP> = {},
    action: IStoreActions
): ChangeEventStore<PatternDefinitionP> {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectChangeEvent)) {
            if (action.payload.getTargetcollection() === TargetCollection.PATTERNDEFINITIONS) {
                const changeEvent = action.payload;
                const key = keyFunc(changeEvent);
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE)
                    delete draftState[key];
                else {
                    const patternDefinition = changeEvent.getPatterndefinition();
                    if(patternDefinition)
                        draftState[key] = {
                            pattern: parsePattern(patternDefinition.getDefinition()),
                            updated: patternDefinition.getUpdated()!.getSeconds(),
                            active: patternDefinition.getActive()
                        };
                }
            }
        }
        else if (action.type === getType(actions.unsubscribe))
            return {};
    });
}

const keyFunc = (changeEvent: ProjectChangeEvent) => changeEvent.getContentid();

const initialState: PatternDefinitionsStore = {
    patternDefinitions: {},
    modifiedPatternDefinitions: {},
    patternTestResults: {},
};

export default function (state: PatternDefinitionsStore = initialState, action: IStoreActions): PatternDefinitionsStore {
    return produce(state, (draftState) => {
        if (action.type === getType(updatePattern)) {
            draftState.modifiedPatternDefinitions = {
                ...draftState.modifiedPatternDefinitions,
                [action.payload.patternId]: action.payload.pattern
            };
        }
        else if (action.type === getType(clearModifiedPatterns)) {
            draftState.modifiedPatternDefinitions = {};
        }
        else if (action.type === getType(clearModifiedPattern)) {
            draftState.modifiedPatternDefinitions = { ...draftState.modifiedPatternDefinitions };
            delete draftState.modifiedPatternDefinitions[action.payload];
        }
        else if (action.type === getType(clearPatternTestResults)) {
            draftState.patternTestResults = { ...draftState.patternTestResults };
            delete draftState.patternTestResults[action.payload];
        }
        else if (action.type === getType(addPatternTestResult)) {
            const patternId = action.payload.patternId;
            if (patternId) {
                const result = action.payload.result;

                const oldResults = draftState.patternTestResults[patternId];
                draftState.patternTestResults = {
                    ...draftState.patternTestResults,
                    [patternId]: oldResults ? oldResults.concat([result]) : [result]
                };
            }
        }
        else
            draftState.patternDefinitions = composed(state.patternDefinitions, action);
    });
}