import { createAction } from 'typesafe-actions';

export interface FetchInfo {
    request: RequestInfo;
    extra?: RequestInit;
    label: string;
    meta?: string;
    onSuccess?: (response: Response, meta?: string) => void;
    onFailure?: (error: Error, meta?: string) => void;
}

export const fetchApi = createAction('FETCH_API', (resolve) => {
    return (fetchRequest: FetchInfo) => resolve(fetchRequest);
});
const apiStart = createAction('API_START', (resolve) => {
    return (label: string) => resolve(label);
});
const apiEnd = createAction('API_END', (resolve) => {
    return (label: string) => resolve(label);
});
const apiError = createAction('API_ERROR', (resolve) => {
    return (error: Error) => resolve(error);
});

export default {
    apiEnd,
    apiError,
    apiStart,
    fetchApi,
};