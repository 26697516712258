import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { ChangeOperation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';

export interface VectorFontDefinitionP {
    name: string;
}

export interface VectorFontDefinitionsStore {
    vectorFontDefinitions: ChangeEventStore<VectorFontDefinitionP>;
}

function composed(
    state: ChangeEventStore<VectorFontDefinitionP>,
    action: IStoreActions
): ChangeEventStore<VectorFontDefinitionP> {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectChangeEvent)) {
            if (action.payload.getTargetcollection() === TargetCollection.VECTORFONTDEFINITIONS) {
                const changeEvent = action.payload;
                const key = keyFunc(changeEvent);
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE) {
                    delete draftState[key];
                } else {
                    const vectorFont = changeEvent.getVectorfontdefinition();
                    if (vectorFont) {
                        draftState[key] = {
                            name: vectorFont.getName(),
                        };
                    }
                }
            }
        } else if (action.type === getType(actions.unsubscribe)) {
            return {};
        }
    });
}

const keyFunc = (changeEvent: ProjectChangeEvent) => changeEvent.getContentid();

const initialState: VectorFontDefinitionsStore = {
    vectorFontDefinitions: {}
};

export default function (state: VectorFontDefinitionsStore = initialState, action: IStoreActions): VectorFontDefinitionsStore {
    return produce(state, (draftState) => {
        draftState.vectorFontDefinitions = composed(state.vectorFontDefinitions, action);
    });
}
