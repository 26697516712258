import Logger from 'js-logger';
import { Middleware, MiddlewareAPI } from 'redux';
import { getType } from 'typesafe-actions';
import actions, { FetchInfo } from '../actions/api';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';
import { baseURL } from '../utils/constants';

export function newApiMiddleware(): Middleware {
    return ({ dispatch, getState }: MiddlewareAPI<ThunkDispatch, StoreState>) => (next: ThunkDispatch) => (action) => {
        if (action.type === getType(actions.fetchApi)) {
            handleApiCall(getState, dispatch, action.payload);
        }
        return next(action);
    };
}

function handleApiCall(getState: () => StoreState, dispatch: ThunkDispatch, fetchInfo: FetchInfo) {
    const { request, extra, meta, label, onSuccess, onFailure } = fetchInfo;
    
    const completeRequest = baseURL + request;
    const requestInit = ensureAuthorizationHeader(getState, extra);

    if (label) {
        dispatch(actions.apiStart(label));
    }

    fetch(completeRequest, requestInit)
        .then(handleErrors)
        .then((response) => {
            if (onSuccess) {
                onSuccess(response, meta);
            }
        })
        .catch((error) => {
            dispatch(actions.apiError(error));
            if (onFailure) {
                onFailure(error, meta);
            }
        })
        .finally(() => {
            if (label) {
                dispatch(actions.apiEnd(label));
            }
        });
}

function ensureAuthorizationHeader(getState: () => StoreState, extra?: RequestInit) {
    if (extra === undefined) {
        const { user } = getState().auth;
        if (user) {
            extra = {
                headers: {
                    Authorization: 'Bearer ' + user.token
                }
            };
        }
    }
    return extra;
}

// Handle HTTP errors since fetch won't.
function handleErrors(response: Response) {
    if (!response.ok) {
        Logger.error(response);
        throw Error(response.statusText);
    }
    return response;
}
