import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/projects';
import { ProjectDesc, ProjectUpdated } from '../proto/projectList_pb';
import { ChangeEventStore } from '../store-types';

export interface ProjectStore {
    available: boolean;
    projects: ChangeEventStore<ProjectUpdated | undefined>;

    adminProjectList: ProjectDesc[];
}

const initialState = {
    available: false,
    projects: {},
    adminProjectList: [],
};

export default function(state: ProjectStore = initialState, action: IStoreActions): ProjectStore {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receiveProjectListChangeEvent)) {
            const changeEvent = action.payload;
            if (changeEvent.hasUpdated()) {
                draftState.projects[changeEvent.getProjectid()] = changeEvent.getUpdated()!; 
            } else if (changeEvent.hasRemoved()) {
                delete draftState.projects[changeEvent.getProjectid()];
            }
        }
        if (action.type === getType(actions.projectNameAvailable)) {
            draftState.available = action.payload;
        }
        if (action.type === getType(actions.receivedAdminProjectList)) {
            draftState.adminProjectList = action.payload;
        }
    });
}