import yaml from 'js-yaml';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { ChangeOperation, TargetCollection } from '../proto/projectChangeEvents_pb';

export type ConfigurationsStore = any;

export default function reduce(
    state: ConfigurationsStore = {},
    action: IStoreActions
): ConfigurationsStore {
    if (action.type === getType(actions.receivedProjectChangeEvent)) {
        if (action.payload.getTargetcollection() === TargetCollection.CONFIGURATIONS) {
            const changeEvent = action.payload;
            if (changeEvent.getChangeoperation() === ChangeOperation.DELETE)
                return {};
            else
                return yaml.safeLoad(changeEvent.getConfiguration()!.getValue());
        }
    }
    else if (action.type === getType(actions.unsubscribe))
        return {};
    return state;
}
