import { IStoreActions } from '../actions';
import { PageTranslation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';
import composable from './composable';

export interface PageTranslationStore extends ChangeEventStore<PageTranslation> {}

const composed = composable<PageTranslation>(
    TargetCollection.PAGETRANSLATIONS,
    ProjectChangeEvent.prototype.getPagetranslation,
);

export default function(state: PageTranslationStore = {}, action: IStoreActions): PageTranslationStore {
    return composed(state, action);
}