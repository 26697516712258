import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { parseMetamodel, MetamodelDefinition } from '../components/targetpatterns/Metamodel';
import { ChangeOperation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';

export interface MetamodelsStore {
    metamodels: ChangeEventStore<MetamodelDefinition>;
}

function composed(
    state: ChangeEventStore<MetamodelDefinition>,
    action: IStoreActions
): ChangeEventStore<MetamodelDefinition> {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectChangeEvent)) {
            if (action.payload.getTargetcollection() === TargetCollection.METAMODELS) {
                const changeEvent = action.payload;
                const key = keyFunc(changeEvent);
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE) {
                    delete draftState[key];
                } else {
                    const metamodel = changeEvent.getMetamodel();
                    if (metamodel) {
                        draftState[key] = {
                            metamodel: parseMetamodel(metamodel.getValue()),
                            target: metamodel.getTarget(),
                            name: metamodel.getName()
                        };
                    }
                }
            }
        } else if (action.type === getType(actions.unsubscribe)) {
            return {};
        }
    });
}

const keyFunc = (changeEvent: ProjectChangeEvent) => changeEvent.getContentid();

const initialState: MetamodelsStore = {
    metamodels: {}
};

export default function(state: MetamodelsStore = initialState, action: IStoreActions): MetamodelsStore {
    return produce(state, (draftState) => {
        draftState.metamodels = composed(state.metamodels, action);
    });
}
