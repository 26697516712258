// reducers.js
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import accessrights from "./accessrights";
import annotations from "./annotations";
import auth from "./auth";
import configurations from "./configurations";
import drawingsheets from "./drawingsheets";
import issues from "./issues";
import jobqueue from "./jobqueue";
import jobqueuestatus from "./jobqueuestatus";
import metamodels from "./metamodels";
import notifications from "./notifications";
import pageerrors from "./pageerrors";
import pagepartitions from "./pagepartitions";
import pages from "./pages";
import pagetranslations from "./pagetranslations";
import painting from "./painting";
import paper2pid from "./paper2pid";
import patterndefinitions from "./patterns";
import projectremoval from "./projectremoval";
import projects from "./projects2";
import projecttranslations from "./projecttranslations";
import properties from "./properties";
import propertytypes from "./propertytypes";
import responses from "./responses";
import sourcefiles from "./sourcefiles";
import subscriptions from "./subscriptions";
import targetconfigurations from "./targetconfigurations";
import targetpatterndefinitions from "./targetpatterns";
import userReducer from "./users";
import vectorfontdefinitions from "./vectorfonts";
import viewers from "./viewers";

export default (history: History) =>
    combineReducers({
        accessrights,
        annotations,
        auth,
        notifications,
        pageerrors,
        pages,
        pagepartitions,
        pagetranslations,
        painting,
        paper2pid,
        patterndefinitions,
        drawingsheets,
        projectremoval,
        projects,
        projecttranslations,
        properties,
        propertytypes,
        responses,
        router: connectRouter(history),
        sourcefiles,
        subscriptions,
        user: userReducer,
        viewers,
        targetconfigurations,
        jobqueuestatus,
        configurations,
        jobqueue,
        targetpatterndefinitions,
        metamodels,
        vectorfontdefinitions,
        issues,
    });
