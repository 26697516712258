import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions, { SelectionChangeType } from '../actions/painting';

export interface ComponentSelection {
    pageId: string;
    selectedItems: number[];
}

export interface PaintingStore {
    selection?: ComponentSelection;
}

const initialState = {
};

function mergeSelections(a: ComponentSelection, b?: ComponentSelection): ComponentSelection {
    if (b && b.pageId === a.pageId) {
        return {
            pageId: a.pageId,
            selectedItems: b.selectedItems.concat(a.selectedItems.filter((i) => !b.selectedItems.includes(i)))
        };
    } else {
        return a;
    }
}

function toggleSelections(a: ComponentSelection, b?: ComponentSelection): ComponentSelection {
    if (b && b.pageId === a.pageId) {
        return {
            pageId: a.pageId,
            selectedItems: b.selectedItems
                .filter((i) => !a.selectedItems.includes(i))
                .concat(a.selectedItems.filter((i) => !b.selectedItems.includes(i)))
        };
    } else {
        return a;
    }
}

export default function(state: PaintingStore = initialState, action: IStoreActions): PaintingStore {
    switch (action.type) {
        case getType(actions.changeSelection): {
            let newSelection: ComponentSelection;
            switch (action.payload.changeType) {
                case SelectionChangeType.MERGE:
                    newSelection = mergeSelections(action.payload.selection, state.selection);
                    break;
                case SelectionChangeType.TOGGLE:
                    newSelection = toggleSelections(action.payload.selection, state.selection);
                    break;
                default:
                    newSelection = action.payload.selection;
                    break;
            }
            return {
                ...state,
                selection: newSelection,
            };
        }
        case getType(actions.clearSelection): {
            return {
                ...state,
                selection: undefined,
            };
        }        
        default:
            return state;
    }
}