import { createAction } from 'typesafe-actions';
import { DrawingSheet, drawingSheetToString } from '../components/drawingsheets/DrawingSheet';
import { RemoveDrawingSheetsRequest, RemoveDrawingSheetsResponse, UpdateDrawingSheetsRequest, UpdateDrawingSheetsResponse } from '../proto/project_pb';
import { ProjectService } from '../proto/project_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { stdOnError } from './grpcutils';
import { DrawingSheetDefinition } from '../proto/projectChangeEvents_pb';

export const updateDrawingSheet = createAction('UPDATE_DRAWING_SHEET', (resolve) => {
    return (projectId: string, drawingSheetId: string, drawingSheet: DrawingSheet) => resolve({ projectId, drawingSheetId, drawingSheet });
});

export const clearModifiedDrawingSheets = createAction('CLEAR_MODIFIED_DRAWING_SHEETS', (resolve) => {
    return () => resolve();
});

export const clearModifiedDrawingSheet = createAction('CLEAR_MODIFIED_DRAWING_SHEET', (resolve) => {
    return (drawingSheetId: string) => resolve(drawingSheetId);
});

export const removeDrawingSheet = (projectId: string, drawingSheetId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new RemoveDrawingSheetsRequest();
        request.setProjectid(projectId);
        request.setDrawingsheetidsList([drawingSheetId]);

        const grpcAction: GrpcActionPayload<RemoveDrawingSheetsRequest, RemoveDrawingSheetsResponse> = {
            methodDescriptor: ProjectService.RemoveDrawingSheets,
            onError: stdOnError(dispatch, 'Removing drawing sheet'),
            request,
        };
        dispatch(clearModifiedDrawingSheet(drawingSheetId));
        dispatch(grpcRequest(grpcAction));
    };
};

export const saveModifiedDrawingSheet = (projectId: string, drawingSheetId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { drawingsheets } = getState();
        const modifiedDrawingSheet = drawingsheets.modifiedDrawingSheets[drawingSheetId];
        if(!modifiedDrawingSheet)
            return;

        const request = new UpdateDrawingSheetsRequest();
        request.setProjectid(projectId);
        const updatesMap = request.getUpdatesMap();

        const drawingSheetDefinition = new DrawingSheetDefinition();
        drawingSheetDefinition.setDefinition(drawingSheetToString(modifiedDrawingSheet));
        updatesMap.set(drawingSheetId, drawingSheetDefinition);

        const grpcAction: GrpcActionPayload<UpdateDrawingSheetsRequest, UpdateDrawingSheetsResponse> = {
            methodDescriptor: ProjectService.UpdateDrawingSheets,
            onMessage: () => dispatch(clearModifiedDrawingSheet(drawingSheetId)),
            onError: stdOnError(dispatch, 'Saving drawing sheet modifications'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export default {
    updateDrawingSheet, clearModifiedDrawingSheets, clearModifiedDrawingSheet,
};
