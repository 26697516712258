import yaml from 'js-yaml';

export interface PatternDefinitionP {
    pattern: Pattern;
    updated: number;
    active: boolean;
}

export interface Pattern {
    name: string;
    orientations: string;
    target: { [key: string]: string },
    vertices: Vertex[];
    virtualVertices: VirtualVertex[];
    edges: Edge[];
    patternTexts: PatternText[];
    terminals: Terminal[];
    constraints: Constraint[];
    conditions: Condition[];
    attributes: Attribute[];
}

export interface Vertex {
    id: string;
    position: number[];
    transitory: boolean;
}

export interface VirtualVertex {
    id: string;
    ref1: string;
    ref2: string;
    position: number;
    offset: number;
    absolutePosition: number[];
}

export interface Edge {
    id: string;
    vertices: string[];
    ellipse?: Ellipse;
    overlapAllowed: boolean;
    constraints: AttributeConstraint[];
}

export interface AttributeConstraint {
    active: boolean;
    name: string;
    constraint: string;
}

export interface Ellipse {
    cx: number;
    cy: number;
    rx: number;
    ry: number;
}

export interface Condition {
    weight?: number;
    formula: string;
}

export interface PatternText {
    id: string;
    delimiter: string;
    regex: string;
    valueRegex: string;
    required: boolean;

    // Example
    position: Palleogram;

    // For inside text
    areaBoundary?: string[];
    combineInsideTexts: boolean;

    // For outside text
    location?: string[];
    minDistance: number;
    maxDistance: number;
    minRowCount: number;
    maxRowCount: number;
}

export interface Attribute {
    id: string;
    script: string;
}

export interface Palleogram {
    x: number;
    y: number;
    hx: number;
    hy: number;
    vx: number;
    vy: number;
}

export function palleogramCenter(palleogram: Palleogram) {
    const { x, y, hx, hy, vx, vy } = palleogram;
    return {
        x: x + 0.5 * (hx + vx),
        y: y + 0.5 * (hy + vy)
    };
}

export interface Terminal {
    id: string;
    type: string;
    featureType: string;
    feature: string;
    allowedAngles: number[];
}

export type ConstraintName = 'allowContinuations' | 'x-axis' | 'y-axis' | 'extendable'
    | 'no-text-inside' | 'relative-positions' | 'rotating-line' | 'moves-on-line';

export interface Constraint {
    constraint: ConstraintName;
    features: string[];
}

export const orientations = ['N', 'H', 'D4', 'R4', 'R180', 'R90', 'R90B'];

const tU = "rotate(0deg)"
const tR = "rotate(90deg)"
const tD = "rotate(180deg)"
const tL = "rotate(270deg)"

const tUF = "rotate(0deg) scaleX(-1)"
const tRF = "rotate(90deg) scaleX(-1)"
const tDF = "rotate(180deg) scaleX(-1)"
const tLF = "rotate(270deg) scaleX(-1)"

export function orientationTransformations(orientation: string): string[] {
    switch (orientation) {
        default:
        case 'N': return [tU];
        case 'H': return [tU, tUF];
        case 'D4': return [tU, tR, tD, tL, tUF, tRF, tDF, tLF];
        case 'R4': return [tU, tR, tD, tL];
        case 'R180': return [tU, tD];
        case 'R90': return [tU, tR];
        case 'R90B': return [tU, tL];
    }
}

export function orientationDescriptions(orientation: string): string {
    switch (orientation) {
        default:
        case 'N': return 'no rotations/flips allowed';
        case 'H': return 'only horizontal flip allowed';
        case 'D4': return 'all 90 degree rotations and horizontal/vertical flips';
        case 'R4': return 'all 90 degree rotations allowed';
        case 'R180': return '180 rotation allowed';
        case 'R90': return '90 rotation allowed';
        case 'R90B': return '-90 rotation allowed';
    }
}

export type VertexMap = { [key: string]: number[] };

export function getVertexMap(pattern: Pattern) {
    const vertexMap: VertexMap = {};
    for (const vertex of pattern.vertices) {
        const { id, position } = vertex;
        vertexMap[id] = position;
    }
    for (const virtualVertex of pattern.virtualVertices) {
        const position = getVirtualVertexPosition(vertexMap, virtualVertex);
        if (!position)
            continue;
        vertexMap[virtualVertex.id] = [position.x, position.y];
    }
    return vertexMap;
}

export type EdgeMap = { [key: string]: Edge };

export function getEdgeMap(pattern: Pattern) {
    const edgeMap: EdgeMap = {};
    for (const edge of pattern.edges) {
        const { id } = edge;
        edgeMap[id] = edge;
    }
    return edgeMap;
}

export function getTerminalMap(pattern: Pattern) {
    const terminalMap: { [key: string]: Terminal[] } = {};
    for (const terminal of pattern.terminals) {
        const { featureType, feature } = terminal;
        const key = featureType + feature;
        const old = terminalMap[key];
        if (old)
            old.push(terminal);
        else
            terminalMap[key] = [terminal];
    }
    return terminalMap;
}

export function getVertexPosition(vertex: Vertex) {
    return {
        x: vertex.position[0],
        y: vertex.position[1]
    };
}

export function getVirtualVertexPosition(vertexMap: { [key: string]: number[] }, virtualVertex: VirtualVertex) {
    const { ref1, ref2, position, offset, absolutePosition } = virtualVertex;
    const pos1 = vertexMap[ref1];
    const pos2 = vertexMap[ref2];
    if (!pos1 || !pos2)
        return null;

    if (ref1 === ref2) {
        return {
            x: pos1[0] + absolutePosition[0],
            y: pos1[1] + absolutePosition[1]
        };
    }

    return {
        x: pos1[0] + (pos2[0] - pos1[0]) * position + (pos1[1] - pos2[1]) * offset,
        y: pos1[1] + (pos2[1] - pos1[1]) * position + (pos2[0] - pos1[0]) * offset
    };
}

export function getPatternTextBoundingBox(text: PatternText) {
    const { x, y, hx, hy, vx, vy } = text.position;
    var minX = x;
    var minY = y;
    var maxX = x;
    var maxY = y;

    if (hx > 0) maxX += hx;
    else minX += hx;
    if (hy > 0) maxY += hy;
    else minY += hy;
    if (vx > 0) maxX += vx;
    else minX += vx;
    if (vy > 0) maxY += vy;
    else minY += vy;

    return { minX, minY, maxX, maxY };
}

export interface BoundingBox {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}

export function getPatternBoundingBox(pattern: Pattern, includeTexts: boolean): BoundingBox {
    var minX = Infinity;
    var minY = Infinity;
    var maxX = -Infinity;
    var maxY = -Infinity;

    const vertexMap = getVertexMap(pattern);

    for (const vertex of pattern.vertices) {
        const x = vertex.position[0];
        const y = vertex.position[1];
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
    }

    for (const virtualVertex of pattern.virtualVertices) {
        const position = getVirtualVertexPosition(vertexMap, virtualVertex);
        if (!position)
            continue;
        const { x, y } = position;
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
    }

    if (includeTexts)
        for (const text of pattern.patternTexts) {
            const bb = getPatternTextBoundingBox(text);

            minX = Math.min(minX, bb.minX);
            maxX = Math.max(maxX, bb.maxX);
            minY = Math.min(minY, bb.minY);
            maxY = Math.max(maxY, bb.maxY);
        }

    for (const edge of pattern.edges)
        if (edge.ellipse) {
            const { cx, cy, rx, ry } = edge.ellipse;
            minX = Math.min(minX, cx - rx);
            maxX = Math.max(maxX, cx + rx);
            minY = Math.min(minY, cy - ry);
            maxY = Math.max(maxY, cy + ry);
        }

    if (isFinite(minX))
        return { minX, minY, maxX, maxY };
    else
        return { minX: -10, minY: -10, maxX: 10, maxY: 10 };
}

export function findVerticesIn(vertexMap: VertexMap, minX: number, minY: number, maxX: number, maxY: number) {
    return Object.keys(vertexMap).filter(id => {
        const position = vertexMap[id];
        const x = position[0];
        const y = position[1];
        return x >= minX && x <= maxX && y >= minY && y <= maxY;
    });
}

export function parsePattern(patternText: string) {
    const obj = yaml.safeLoad(patternText);
    if (!obj.name)
        obj.name = "";
    if (!obj.orientations)
        obj.orientations = "N";
    if (!obj.target)
        obj.target = {};
    if (!obj.vertices)
        obj.vertices = [];
    if (!obj.virtualVertices)
        obj.virtualVertices = [];
    if (!obj.edges)
        obj.edges = [];
    if (!obj.patternTexts)
        obj.patternTexts = [];
    if (!obj.terminals)
        obj.terminals = [];
    if (!obj.constraints)
        obj.constraints = [];
    if (!obj.conditions)
        obj.conditions = [];
    if (!obj.attributes)
        obj.attributes = [];

    for (const edge of obj.edges) {
        edge.overlapAllowed = !!edge.overlapAllowed;
        if (!edge.constraints)
            edge.constraints = [];
    }

    return obj as Pattern;
}

export function patternToString(pattern: Pattern) {
    return yaml.safeDump(pattern);
}

export function patternsToString(patterns: Pattern[]) {
    return yaml.safeDump(patterns);
}