import { batchActions } from 'redux-batched-actions';
import { createAction } from 'typesafe-actions';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';
import { HidingMode } from '../reducers/viewers';

const DEFAULT_SCALE_DELTA: number = 1.3;

const changeScale = createAction('viewers/CHANGE_SCALE', (resolve) => {
    return (scale: number) => resolve(scale);
});
const changeX = createAction('viewers/CHANGE_X', (resolve) => {
    return (x: number) => resolve(x);
});
const changeY = createAction('viewers/CHANGE_Y', (resolve) => {
    return (y: number) => resolve(y);
});

const setScaleResult = createAction('viewers/SET_SCALE_RESULT', (resolve) => {
    return (scaleResult: boolean) => resolve(scaleResult);
});

const setPatternDefinitionHidingMode = createAction('viewers/SET_PATTERN_DEFINITION_HIDING_MODE', (resolve) => {
    return (hidingMode: HidingMode) => resolve(hidingMode);
});

export const modifyScale = (offsetX: number, offsetY: number, deltaY: number) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const state = getState();
        const { x, y, scale } = state.viewers; // previous values from state

        const mousePointTo = {
            x: offsetX / scale - x / scale,
            y: offsetY / scale - y / scale,
        };

        // Logger.info('mousePointTo ' + JSON.stringify(mousePointTo));

        const newScale = deltaY < 0 ? scale * DEFAULT_SCALE_DELTA : scale / DEFAULT_SCALE_DELTA;

        const newX = -(mousePointTo.x - offsetX / newScale) * newScale;
        const newY = -(mousePointTo.y - offsetY / newScale) * newScale;

        // Logger.info('newX ' + newX + ' newY ' + newY);
        dispatch(batchActions([
            changeScale(newScale),
            changeX(newX),
            changeY(newY)
        ]));
    };
};

export const doChangeScale = (scale: number) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(changeScale(scale));
    };
};

export const doChangeX = (x: number) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(changeX(x));
    };
};

export const doChangeY = (y: number) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        dispatch(changeY(y));
    };
};

const FIT_PADDING_RATIO = 0.9;
export const doFit = (boundingBox: [number, number, number, number], viewingArea: [number, number]) => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        console.log("fit");
        console.log("  boundingBox = " + boundingBox);
        console.log("  viewingArea = " + viewingArea);
        const [ x1, y1, x2, y2 ] = boundingBox;
        const [ width, height ] = viewingArea;
        const bbWidth = Math.abs(x2-x1);
        const bbHeight = Math.abs(y2-y1);
        console.log("  bbWidth = " + bbWidth);
        console.log("  bbHeight = " + bbHeight);

        const scale = Math.min(width/bbWidth, height/bbHeight)*FIT_PADDING_RATIO;
        console.log("  scale = " + scale);
        const centerX = 0.5*(x1 + x2);
        const centerY = 0.5*(y1 + y2);

        dispatch(changeX(width*0.5-centerX*scale));
        dispatch(changeY(height*0.5-centerY*scale));
        dispatch(changeScale(scale));
    };
};

export default {
    changeScale,
    changeX,
    changeY,
    setScaleResult,
    setPatternDefinitionHidingMode
};
