import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/projects';
import { User } from '../proto/projectList_pb';
import { ChangeEventStore } from '../store-types';

export interface UserStore extends ChangeEventStore<User> {}

export default function(state: UserStore = {}, action: IStoreActions): UserStore {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedUser)) {
            const changeEvent = action.payload;
            draftState[changeEvent.getName()] = changeEvent;
        } else if (action.type === getType(actions.userRemoved)) {
            delete draftState[action.payload];
        }
    });
}
