import { IStoreActions } from '../actions';
import { ProjectChangeEvent, ProjectTranslation, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';
import composable from './composable';

export interface ProjectTranslationStore extends ChangeEventStore<ProjectTranslation> {}

const composed = composable<ProjectTranslation>(
    TargetCollection.PROJECTTRANSLATIONS,
    ProjectChangeEvent.prototype.getProjecttranslation
);

export default function(state: ProjectTranslationStore = {}, action: IStoreActions): ProjectTranslationStore {
    return composed(state, action);
}