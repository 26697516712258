import { grpc } from '@improbable-eng/grpc-web';
import * as jspb from 'google-protobuf';
import debounce from 'lodash.debounce';
import { Middleware, MiddlewareAPI } from 'redux';
import { batchActions } from 'redux-batched-actions';
import { IStoreActions } from '../actions';
import { GRPC_WEB_REQUEST, GrpcActionPayload } from '../actions/grpc';
import { StoreState } from '../store';
import { ThunkDispatch } from '../store-types';
import { grpcURL } from '../utils/constants';
import Logger, { isDebug } from '../utils/logger';

const log = (methodDescriptor: grpc.MethodDefinition<jspb.Message, jspb.Message>, prefix: string) => {
    if (isDebug()) {
        const grpcMethod = methodDescriptor.service.serviceName + '/' + methodDescriptor.methodName;
        Logger.debug(prefix + ' ' + grpcMethod);
    }
};

const handleGrpcCall = <RequestType extends jspb.Message, ResponseType extends jspb.Message>(
    getState: () => StoreState,
    dispatch: ThunkDispatch,
    grpcAction: GrpcActionPayload<RequestType, ResponseType>
): void => {
    const token = getState().auth.user!.token;

    const metadata = new grpc.Metadata();
    metadata.append('custom-header-1', token);
    const { debug, request, methodDescriptor, onHeaders, onMessage, onEnd, onError, onStart, batch } = grpcAction;

    // this is used by batched actions
    let actionsToDispatch: IStoreActions[] = [];
    // @ts-ignore
    let debouncedDispatch;
    if (batch) {
        debouncedDispatch = debounce(
            () => {
                const acts = actionsToDispatch;
                actionsToDispatch = [];
                // console.log('dispatching ' + acts.length + ' debounced actions');
                dispatch(batchActions(acts));
            },
            100,
            {
                leading: true,
                maxWait: 100,
                trailing: true,
            });
    }

    const stream = grpc.invoke<RequestType, ResponseType, grpc.MethodDefinition<RequestType, ResponseType>>(
        methodDescriptor, {
            debug,
            host: grpcURL,
            metadata,
            request,
            onHeaders: (headers: grpc.Metadata) => {
                if (!onHeaders) {
                    return;
                }
                const actionToDispatch = onHeaders(headers);
                return actionToDispatch && dispatch(actionToDispatch);
            },
            onMessage: (res: ResponseType) => {
                if (!onMessage) {
                    return;
                }
                const actionToDispatch = onMessage(res);
                if (batch && actionToDispatch) {
                    actionsToDispatch.push(actionToDispatch);
                    // @ts-ignore
                    return debouncedDispatch(); // return void in this case
                } else {
                    return actionToDispatch && dispatch(actionToDispatch);
                }
            },
            onEnd: (code: grpc.Code, msg: string, trailers: grpc.Metadata) => {
                log(methodDescriptor, 'End gRPC request:');
                if (code !== grpc.Code.OK) {
                    Logger.error('gRPC non-OK status: ' + code + ' ' + msg);
                    if (onError) {
                        const actionToDispatch = onError(code, msg);
                        return actionToDispatch && dispatch(actionToDispatch);
                    }
                }
                if (!onEnd) {
                    return;
                }
                const actionToDispatch = onEnd(code, msg, trailers);
                return actionToDispatch && dispatch(actionToDispatch);
            },
        });

    if (onStart) {
        const actionToDispatch = onStart(stream);
        if (actionToDispatch) {
            dispatch(actionToDispatch);
        }
    }
};

export function newGrpcMiddleware(): Middleware {
    return ({ dispatch, getState }: MiddlewareAPI<ThunkDispatch, StoreState>) => (next: ThunkDispatch) => (action) => {
        if (action.type === GRPC_WEB_REQUEST) {
            return handleGrpcCall(getState, dispatch, action.payload);
        } else {
            return next(action);
        }
    };
}
