// package: 
// file: project.proto

var project_pb = require("./project_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var projectChangeEvents_pb = require("./projectChangeEvents_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ProjectService = (function () {
  function ProjectService() {}
  ProjectService.serviceName = "ProjectService";
  return ProjectService;
}());

ProjectService.ProjectChangeEvents = {
  methodName: "ProjectChangeEvents",
  service: ProjectService,
  requestStream: false,
  responseStream: true,
  requestType: project_pb.ProjectChangeEventRequest,
  responseType: projectChangeEvents_pb.ProjectChangeEvent
};

ProjectService.Translate = {
  methodName: "Translate",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.TranslateRequest,
  responseType: project_pb.TranslateResponse
};

ProjectService.CancelTranslation = {
  methodName: "CancelTranslation",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.CancelTranslationRequest,
  responseType: project_pb.CancelTranslationResponse
};

ProjectService.RemoveSourceFile = {
  methodName: "RemoveSourceFile",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.RemoveSourceFileRequest,
  responseType: project_pb.RemoveSourceFileResponse
};

ProjectService.ChangeSinglePageSelection = {
  methodName: "ChangeSinglePageSelection",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.ChangeSinglePageSelectionRequest,
  responseType: project_pb.ChangeSinglePageSelectionResponse
};

ProjectService.ChangeAllPageSelections = {
  methodName: "ChangeAllPageSelections",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.ChangeAllPageSelectionsRequest,
  responseType: project_pb.ChangeAllPageSelectionsResponse
};

ProjectService.ValidatePropertyValue = {
  methodName: "ValidatePropertyValue",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.PropertyNameAndValue,
  responseType: project_pb.ValidatePropertyValueResponse
};

ProjectService.SetPropertyValue = {
  methodName: "SetPropertyValue",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.PropertyNameAndValue,
  responseType: project_pb.SetPropertyValueResponse
};

ProjectService.RemovePreset = {
  methodName: "RemovePreset",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.RemovePresetRequest,
  responseType: project_pb.RemovePresetResponse
};

ProjectService.UpdatePagePartition = {
  methodName: "UpdatePagePartition",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdatePagePartitionRequest,
  responseType: project_pb.UpdatePagePartitionResponse
};

ProjectService.UpdatePartLabel = {
  methodName: "UpdatePartLabel",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdatePartLabelRequest,
  responseType: project_pb.UpdatePartLabelResponse
};

ProjectService.CreatePatternFromExample = {
  methodName: "CreatePatternFromExample",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.CreatePatternFromExampleRequest,
  responseType: project_pb.CreatePatternFromExampleResponse
};

ProjectService.UpdatePatterns = {
  methodName: "UpdatePatterns",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdatePatternsRequest,
  responseType: project_pb.UpdatePatternsResponse
};

ProjectService.RemovePatterns = {
  methodName: "RemovePatterns",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.RemovePatternsRequest,
  responseType: project_pb.RemovePatternsResponse
};

ProjectService.TestPattern = {
  methodName: "TestPattern",
  service: ProjectService,
  requestStream: false,
  responseStream: true,
  requestType: project_pb.TestPatternRequest,
  responseType: project_pb.TestPatternResponse
};

ProjectService.NormalizePatternDefinition = {
  methodName: "NormalizePatternDefinition",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.NormalizePatternDefinitionRequest,
  responseType: project_pb.NormalizePatternDefinitionResponse
};

ProjectService.CreatePattern = {
  methodName: "CreatePattern",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.CreatePatternRequest,
  responseType: project_pb.CreatePatternResponse
};

ProjectService.UpdateTargetPatterns = {
  methodName: "UpdateTargetPatterns",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdateTargetPatternsRequest,
  responseType: project_pb.UpdateTargetPatternsResponse
};

ProjectService.RemoveTargetPatterns = {
  methodName: "RemoveTargetPatterns",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.RemoveTargetPatternsRequest,
  responseType: project_pb.RemoveTargetPatternsResponse
};

ProjectService.NormalizeTargetPatternDefinition = {
  methodName: "NormalizeTargetPatternDefinition",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.NormalizeTargetPatternDefinitionRequest,
  responseType: project_pb.NormalizeTargetPatternDefinitionResponse
};

ProjectService.CreateTargetPattern = {
  methodName: "CreateTargetPattern",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.CreateTargetPatternRequest,
  responseType: project_pb.CreateTargetPatternResponse
};

ProjectService.CreateDrawingSheetFromExample = {
  methodName: "CreateDrawingSheetFromExample",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.CreateDrawingSheetFromExampleRequest,
  responseType: project_pb.CreateDrawingSheetFromExampleResponse
};

ProjectService.RemoveDrawingSheets = {
  methodName: "RemoveDrawingSheets",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.RemoveDrawingSheetsRequest,
  responseType: project_pb.RemoveDrawingSheetsResponse
};

ProjectService.UpdateDrawingSheets = {
  methodName: "UpdateDrawingSheets",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdateDrawingSheetsRequest,
  responseType: project_pb.UpdateDrawingSheetsResponse
};

ProjectService.UpdateConfiguration = {
  methodName: "UpdateConfiguration",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdateConfigurationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProjectService.UpdateTargetConfiguration = {
  methodName: "UpdateTargetConfiguration",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.UpdateTargetConfigurationRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProjectService.ImportPreset = {
  methodName: "ImportPreset",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.ImportPresetRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProjectService.RotatePages = {
  methodName: "RotatePages",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.RotatePagesRequest,
  responseType: google_protobuf_empty_pb.Empty
};

ProjectService.ClaimSourceFile = {
  methodName: "ClaimSourceFile",
  service: ProjectService,
  requestStream: false,
  responseStream: false,
  requestType: project_pb.ClaimSourceFileRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.ProjectService = ProjectService;

function ProjectServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProjectServiceClient.prototype.projectChangeEvents = function projectChangeEvents(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ProjectService.ProjectChangeEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.translate = function translate(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.Translate, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.cancelTranslation = function cancelTranslation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.CancelTranslation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.removeSourceFile = function removeSourceFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.RemoveSourceFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.changeSinglePageSelection = function changeSinglePageSelection(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.ChangeSinglePageSelection, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.changeAllPageSelections = function changeAllPageSelections(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.ChangeAllPageSelections, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.validatePropertyValue = function validatePropertyValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.ValidatePropertyValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.setPropertyValue = function setPropertyValue(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.SetPropertyValue, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.removePreset = function removePreset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.RemovePreset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updatePagePartition = function updatePagePartition(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdatePagePartition, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updatePartLabel = function updatePartLabel(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdatePartLabel, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.createPatternFromExample = function createPatternFromExample(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.CreatePatternFromExample, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updatePatterns = function updatePatterns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdatePatterns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.removePatterns = function removePatterns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.RemovePatterns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.testPattern = function testPattern(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(ProjectService.TestPattern, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.normalizePatternDefinition = function normalizePatternDefinition(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.NormalizePatternDefinition, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.createPattern = function createPattern(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.CreatePattern, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updateTargetPatterns = function updateTargetPatterns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdateTargetPatterns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.removeTargetPatterns = function removeTargetPatterns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.RemoveTargetPatterns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.normalizeTargetPatternDefinition = function normalizeTargetPatternDefinition(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.NormalizeTargetPatternDefinition, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.createTargetPattern = function createTargetPattern(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.CreateTargetPattern, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.createDrawingSheetFromExample = function createDrawingSheetFromExample(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.CreateDrawingSheetFromExample, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.removeDrawingSheets = function removeDrawingSheets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.RemoveDrawingSheets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updateDrawingSheets = function updateDrawingSheets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdateDrawingSheets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updateConfiguration = function updateConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdateConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.updateTargetConfiguration = function updateTargetConfiguration(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.UpdateTargetConfiguration, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.importPreset = function importPreset(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.ImportPreset, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.rotatePages = function rotatePages(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.RotatePages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectServiceClient.prototype.claimSourceFile = function claimSourceFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ProjectService.ClaimSourceFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProjectServiceClient = ProjectServiceClient;

