import * as jspb from "google-protobuf";
import produce from "immer";
import { getType } from "typesafe-actions";
import { IStoreActions } from "../actions";
import actions from "../actions/paper2pidservice";
import {
    ChangeOperation,
    ProjectChangeEvent,
    TargetCollectionMap,
} from "../proto/projectChangeEvents_pb";
import { ChangeEventStore } from "../store-types";

const initialState = {};

const composed = <T extends jspb.Message>(
    targetCollection: TargetCollectionMap[keyof TargetCollectionMap],
    get: () => T | undefined,
    keyFunc: (changeEvent: ProjectChangeEvent) => string = defaultKeyFunction
) => (
    state: ChangeEventStore<T> = initialState,
    action: IStoreActions
): ChangeEventStore<T> => {
    if (
        action.type === getType(actions.receivedProjectChangeEvent) &&
        action.payload.getTargetcollection() === targetCollection
    )
        return produce(state, (draftState) => {
            const changeEvent = action.payload;
            const key = keyFunc(changeEvent);
            if (changeEvent.getChangeoperation() === ChangeOperation.DELETE) {
                delete draftState[key];
            } else {
                // @ts-ignore
                draftState[key] = get.call(changeEvent)!;
            }
        });
    if (action.type === getType(actions.unsubscribe)) {
        // hopefully every store should be initial when leaving project
        return initialState;
    }
    return state;
};

const defaultKeyFunction = (changeEvent: ProjectChangeEvent) =>
    changeEvent.getContentid();

export default composed;
