/* eslint-disable */
// source: projectChangeEvents.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.Box', null, global);
goog.exportSymbol('proto.ChangeOperation', null, global);
goog.exportSymbol('proto.Configuration', null, global);
goog.exportSymbol('proto.DrawingSheetDefinition', null, global);
goog.exportSymbol('proto.EnumItem', null, global);
goog.exportSymbol('proto.EnumType', null, global);
goog.exportSymbol('proto.Issue', null, global);
goog.exportSymbol('proto.JobQueueStatus', null, global);
goog.exportSymbol('proto.Message', null, global);
goog.exportSymbol('proto.Metamodel', null, global);
goog.exportSymbol('proto.NumberType', null, global);
goog.exportSymbol('proto.ObjectPartAssignment', null, global);
goog.exportSymbol('proto.Page', null, global);
goog.exportSymbol('proto.PagePart', null, global);
goog.exportSymbol('proto.PagePartition', null, global);
goog.exportSymbol('proto.PageTranslation', null, global);
goog.exportSymbol('proto.PatternDefinition', null, global);
goog.exportSymbol('proto.ProjectChangeEvent', null, global);
goog.exportSymbol('proto.ProjectChangeEvent.ContentCase', null, global);
goog.exportSymbol('proto.ProjectTranslation', null, global);
goog.exportSymbol('proto.Property', null, global);
goog.exportSymbol('proto.PropertyOrigin', null, global);
goog.exportSymbol('proto.PropertyType', null, global);
goog.exportSymbol('proto.PropertyType.TypeCase', null, global);
goog.exportSymbol('proto.ResultType', null, global);
goog.exportSymbol('proto.SourceFile', null, global);
goog.exportSymbol('proto.StringType', null, global);
goog.exportSymbol('proto.TargetCollection', null, global);
goog.exportSymbol('proto.TargetConfiguration', null, global);
goog.exportSymbol('proto.TargetPatternDefinition', null, global);
goog.exportSymbol('proto.VectorFontDefinition', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProjectChangeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.ProjectChangeEvent.oneofGroups_);
};
goog.inherits(proto.ProjectChangeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProjectChangeEvent.displayName = 'proto.ProjectChangeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SourceFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SourceFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SourceFile.displayName = 'proto.SourceFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Page = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Page, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Page.displayName = 'proto.Page';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProjectTranslation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProjectTranslation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProjectTranslation.displayName = 'proto.ProjectTranslation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PageTranslation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PageTranslation.repeatedFields_, null);
};
goog.inherits(proto.PageTranslation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PageTranslation.displayName = 'proto.PageTranslation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Box = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Box, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Box.displayName = 'proto.Box';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ObjectPartAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ObjectPartAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ObjectPartAssignment.displayName = 'proto.ObjectPartAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PagePart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PagePart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PagePart.displayName = 'proto.PagePart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PagePartition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PagePartition.repeatedFields_, null);
};
goog.inherits(proto.PagePartition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PagePartition.displayName = 'proto.PagePartition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Message = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Message, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Message.displayName = 'proto.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Issue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Issue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Issue.displayName = 'proto.Issue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Property = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Property.displayName = 'proto.Property';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PropertyType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.PropertyType.oneofGroups_);
};
goog.inherits(proto.PropertyType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PropertyType.displayName = 'proto.PropertyType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StringType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StringType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StringType.displayName = 'proto.StringType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NumberType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NumberType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NumberType.displayName = 'proto.NumberType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EnumType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EnumType.repeatedFields_, null);
};
goog.inherits(proto.EnumType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EnumType.displayName = 'proto.EnumType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EnumItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EnumItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EnumItem.displayName = 'proto.EnumItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PatternDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PatternDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PatternDefinition.displayName = 'proto.PatternDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TargetPatternDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TargetPatternDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TargetPatternDefinition.displayName = 'proto.TargetPatternDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DrawingSheetDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DrawingSheetDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DrawingSheetDefinition.displayName = 'proto.DrawingSheetDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TargetConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TargetConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TargetConfiguration.displayName = 'proto.TargetConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Metamodel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Metamodel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Metamodel.displayName = 'proto.Metamodel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VectorFontDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VectorFontDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VectorFontDefinition.displayName = 'proto.VectorFontDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.JobQueueStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.JobQueueStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.JobQueueStatus.displayName = 'proto.JobQueueStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Configuration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Configuration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Configuration.displayName = 'proto.Configuration';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.ProjectChangeEvent.oneofGroups_ = [[4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]];

/**
 * @enum {number}
 */
proto.ProjectChangeEvent.ContentCase = {
  CONTENT_NOT_SET: 0,
  SOURCEFILE: 4,
  PAGE: 5,
  PROJECTTRANSLATION: 6,
  PAGETRANSLATION: 7,
  MESSAGE: 8,
  PROPERTY: 9,
  PROPERTYTYPE: 10,
  PATTERNDEFINITION: 11,
  PAGEPARTITION: 12,
  TARGETCONFIGURATION: 13,
  DRAWINGSHEETDEFINITION: 14,
  JOBQUEUESTATUS: 15,
  CONFIGURATION: 16,
  TARGETPATTERNDEFINITION: 17,
  METAMODEL: 18,
  VECTORFONTDEFINITION: 19,
  ISSUE: 20
};

/**
 * @return {proto.ProjectChangeEvent.ContentCase}
 */
proto.ProjectChangeEvent.prototype.getContentCase = function() {
  return /** @type {proto.ProjectChangeEvent.ContentCase} */(jspb.Message.computeOneofCase(this, proto.ProjectChangeEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProjectChangeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.ProjectChangeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProjectChangeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectChangeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    changeoperation: jspb.Message.getFieldWithDefault(msg, 1, 0),
    targetcollection: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sourcefile: (f = msg.getSourcefile()) && proto.SourceFile.toObject(includeInstance, f),
    page: (f = msg.getPage()) && proto.Page.toObject(includeInstance, f),
    projecttranslation: (f = msg.getProjecttranslation()) && proto.ProjectTranslation.toObject(includeInstance, f),
    pagetranslation: (f = msg.getPagetranslation()) && proto.PageTranslation.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && proto.Message.toObject(includeInstance, f),
    property: (f = msg.getProperty()) && proto.Property.toObject(includeInstance, f),
    propertytype: (f = msg.getPropertytype()) && proto.PropertyType.toObject(includeInstance, f),
    patterndefinition: (f = msg.getPatterndefinition()) && proto.PatternDefinition.toObject(includeInstance, f),
    pagepartition: (f = msg.getPagepartition()) && proto.PagePartition.toObject(includeInstance, f),
    targetconfiguration: (f = msg.getTargetconfiguration()) && proto.TargetConfiguration.toObject(includeInstance, f),
    drawingsheetdefinition: (f = msg.getDrawingsheetdefinition()) && proto.DrawingSheetDefinition.toObject(includeInstance, f),
    jobqueuestatus: (f = msg.getJobqueuestatus()) && proto.JobQueueStatus.toObject(includeInstance, f),
    configuration: (f = msg.getConfiguration()) && proto.Configuration.toObject(includeInstance, f),
    targetpatterndefinition: (f = msg.getTargetpatterndefinition()) && proto.TargetPatternDefinition.toObject(includeInstance, f),
    metamodel: (f = msg.getMetamodel()) && proto.Metamodel.toObject(includeInstance, f),
    vectorfontdefinition: (f = msg.getVectorfontdefinition()) && proto.VectorFontDefinition.toObject(includeInstance, f),
    issue: (f = msg.getIssue()) && proto.Issue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProjectChangeEvent}
 */
proto.ProjectChangeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProjectChangeEvent;
  return proto.ProjectChangeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProjectChangeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProjectChangeEvent}
 */
proto.ProjectChangeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ChangeOperation} */ (reader.readEnum());
      msg.setChangeoperation(value);
      break;
    case 2:
      var value = /** @type {!proto.TargetCollection} */ (reader.readEnum());
      msg.setTargetcollection(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentid(value);
      break;
    case 4:
      var value = new proto.SourceFile;
      reader.readMessage(value,proto.SourceFile.deserializeBinaryFromReader);
      msg.setSourcefile(value);
      break;
    case 5:
      var value = new proto.Page;
      reader.readMessage(value,proto.Page.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 6:
      var value = new proto.ProjectTranslation;
      reader.readMessage(value,proto.ProjectTranslation.deserializeBinaryFromReader);
      msg.setProjecttranslation(value);
      break;
    case 7:
      var value = new proto.PageTranslation;
      reader.readMessage(value,proto.PageTranslation.deserializeBinaryFromReader);
      msg.setPagetranslation(value);
      break;
    case 8:
      var value = new proto.Message;
      reader.readMessage(value,proto.Message.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 9:
      var value = new proto.Property;
      reader.readMessage(value,proto.Property.deserializeBinaryFromReader);
      msg.setProperty(value);
      break;
    case 10:
      var value = new proto.PropertyType;
      reader.readMessage(value,proto.PropertyType.deserializeBinaryFromReader);
      msg.setPropertytype(value);
      break;
    case 11:
      var value = new proto.PatternDefinition;
      reader.readMessage(value,proto.PatternDefinition.deserializeBinaryFromReader);
      msg.setPatterndefinition(value);
      break;
    case 12:
      var value = new proto.PagePartition;
      reader.readMessage(value,proto.PagePartition.deserializeBinaryFromReader);
      msg.setPagepartition(value);
      break;
    case 13:
      var value = new proto.TargetConfiguration;
      reader.readMessage(value,proto.TargetConfiguration.deserializeBinaryFromReader);
      msg.setTargetconfiguration(value);
      break;
    case 14:
      var value = new proto.DrawingSheetDefinition;
      reader.readMessage(value,proto.DrawingSheetDefinition.deserializeBinaryFromReader);
      msg.setDrawingsheetdefinition(value);
      break;
    case 15:
      var value = new proto.JobQueueStatus;
      reader.readMessage(value,proto.JobQueueStatus.deserializeBinaryFromReader);
      msg.setJobqueuestatus(value);
      break;
    case 16:
      var value = new proto.Configuration;
      reader.readMessage(value,proto.Configuration.deserializeBinaryFromReader);
      msg.setConfiguration(value);
      break;
    case 17:
      var value = new proto.TargetPatternDefinition;
      reader.readMessage(value,proto.TargetPatternDefinition.deserializeBinaryFromReader);
      msg.setTargetpatterndefinition(value);
      break;
    case 18:
      var value = new proto.Metamodel;
      reader.readMessage(value,proto.Metamodel.deserializeBinaryFromReader);
      msg.setMetamodel(value);
      break;
    case 19:
      var value = new proto.VectorFontDefinition;
      reader.readMessage(value,proto.VectorFontDefinition.deserializeBinaryFromReader);
      msg.setVectorfontdefinition(value);
      break;
    case 20:
      var value = new proto.Issue;
      reader.readMessage(value,proto.Issue.deserializeBinaryFromReader);
      msg.setIssue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProjectChangeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProjectChangeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProjectChangeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectChangeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChangeoperation();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTargetcollection();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getContentid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSourcefile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.SourceFile.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Page.serializeBinaryToWriter
    );
  }
  f = message.getProjecttranslation();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ProjectTranslation.serializeBinaryToWriter
    );
  }
  f = message.getPagetranslation();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.PageTranslation.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.Message.serializeBinaryToWriter
    );
  }
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.Property.serializeBinaryToWriter
    );
  }
  f = message.getPropertytype();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.PropertyType.serializeBinaryToWriter
    );
  }
  f = message.getPatterndefinition();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.PatternDefinition.serializeBinaryToWriter
    );
  }
  f = message.getPagepartition();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.PagePartition.serializeBinaryToWriter
    );
  }
  f = message.getTargetconfiguration();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.TargetConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getDrawingsheetdefinition();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.DrawingSheetDefinition.serializeBinaryToWriter
    );
  }
  f = message.getJobqueuestatus();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.JobQueueStatus.serializeBinaryToWriter
    );
  }
  f = message.getConfiguration();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.Configuration.serializeBinaryToWriter
    );
  }
  f = message.getTargetpatterndefinition();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.TargetPatternDefinition.serializeBinaryToWriter
    );
  }
  f = message.getMetamodel();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.Metamodel.serializeBinaryToWriter
    );
  }
  f = message.getVectorfontdefinition();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.VectorFontDefinition.serializeBinaryToWriter
    );
  }
  f = message.getIssue();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.Issue.serializeBinaryToWriter
    );
  }
};


/**
 * optional ChangeOperation changeOperation = 1;
 * @return {!proto.ChangeOperation}
 */
proto.ProjectChangeEvent.prototype.getChangeoperation = function() {
  return /** @type {!proto.ChangeOperation} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ChangeOperation} value
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.setChangeoperation = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TargetCollection targetCollection = 2;
 * @return {!proto.TargetCollection}
 */
proto.ProjectChangeEvent.prototype.getTargetcollection = function() {
  return /** @type {!proto.TargetCollection} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.TargetCollection} value
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.setTargetcollection = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string contentId = 3;
 * @return {string}
 */
proto.ProjectChangeEvent.prototype.getContentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.setContentid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SourceFile sourceFile = 4;
 * @return {?proto.SourceFile}
 */
proto.ProjectChangeEvent.prototype.getSourcefile = function() {
  return /** @type{?proto.SourceFile} */ (
    jspb.Message.getWrapperField(this, proto.SourceFile, 4));
};


/**
 * @param {?proto.SourceFile|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setSourcefile = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearSourcefile = function() {
  return this.setSourcefile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasSourcefile = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Page page = 5;
 * @return {?proto.Page}
 */
proto.ProjectChangeEvent.prototype.getPage = function() {
  return /** @type{?proto.Page} */ (
    jspb.Message.getWrapperField(this, proto.Page, 5));
};


/**
 * @param {?proto.Page|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setPage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasPage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ProjectTranslation projectTranslation = 6;
 * @return {?proto.ProjectTranslation}
 */
proto.ProjectChangeEvent.prototype.getProjecttranslation = function() {
  return /** @type{?proto.ProjectTranslation} */ (
    jspb.Message.getWrapperField(this, proto.ProjectTranslation, 6));
};


/**
 * @param {?proto.ProjectTranslation|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setProjecttranslation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearProjecttranslation = function() {
  return this.setProjecttranslation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasProjecttranslation = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PageTranslation pageTranslation = 7;
 * @return {?proto.PageTranslation}
 */
proto.ProjectChangeEvent.prototype.getPagetranslation = function() {
  return /** @type{?proto.PageTranslation} */ (
    jspb.Message.getWrapperField(this, proto.PageTranslation, 7));
};


/**
 * @param {?proto.PageTranslation|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setPagetranslation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearPagetranslation = function() {
  return this.setPagetranslation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasPagetranslation = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Message message = 8;
 * @return {?proto.Message}
 */
proto.ProjectChangeEvent.prototype.getMessage = function() {
  return /** @type{?proto.Message} */ (
    jspb.Message.getWrapperField(this, proto.Message, 8));
};


/**
 * @param {?proto.Message|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setMessage = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Property property = 9;
 * @return {?proto.Property}
 */
proto.ProjectChangeEvent.prototype.getProperty = function() {
  return /** @type{?proto.Property} */ (
    jspb.Message.getWrapperField(this, proto.Property, 9));
};


/**
 * @param {?proto.Property|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setProperty = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearProperty = function() {
  return this.setProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PropertyType propertyType = 10;
 * @return {?proto.PropertyType}
 */
proto.ProjectChangeEvent.prototype.getPropertytype = function() {
  return /** @type{?proto.PropertyType} */ (
    jspb.Message.getWrapperField(this, proto.PropertyType, 10));
};


/**
 * @param {?proto.PropertyType|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setPropertytype = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearPropertytype = function() {
  return this.setPropertytype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasPropertytype = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PatternDefinition patternDefinition = 11;
 * @return {?proto.PatternDefinition}
 */
proto.ProjectChangeEvent.prototype.getPatterndefinition = function() {
  return /** @type{?proto.PatternDefinition} */ (
    jspb.Message.getWrapperField(this, proto.PatternDefinition, 11));
};


/**
 * @param {?proto.PatternDefinition|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setPatterndefinition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearPatterndefinition = function() {
  return this.setPatterndefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasPatterndefinition = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional PagePartition pagePartition = 12;
 * @return {?proto.PagePartition}
 */
proto.ProjectChangeEvent.prototype.getPagepartition = function() {
  return /** @type{?proto.PagePartition} */ (
    jspb.Message.getWrapperField(this, proto.PagePartition, 12));
};


/**
 * @param {?proto.PagePartition|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setPagepartition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearPagepartition = function() {
  return this.setPagepartition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasPagepartition = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional TargetConfiguration targetConfiguration = 13;
 * @return {?proto.TargetConfiguration}
 */
proto.ProjectChangeEvent.prototype.getTargetconfiguration = function() {
  return /** @type{?proto.TargetConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.TargetConfiguration, 13));
};


/**
 * @param {?proto.TargetConfiguration|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setTargetconfiguration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearTargetconfiguration = function() {
  return this.setTargetconfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasTargetconfiguration = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DrawingSheetDefinition drawingSheetDefinition = 14;
 * @return {?proto.DrawingSheetDefinition}
 */
proto.ProjectChangeEvent.prototype.getDrawingsheetdefinition = function() {
  return /** @type{?proto.DrawingSheetDefinition} */ (
    jspb.Message.getWrapperField(this, proto.DrawingSheetDefinition, 14));
};


/**
 * @param {?proto.DrawingSheetDefinition|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setDrawingsheetdefinition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearDrawingsheetdefinition = function() {
  return this.setDrawingsheetdefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasDrawingsheetdefinition = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional JobQueueStatus jobQueueStatus = 15;
 * @return {?proto.JobQueueStatus}
 */
proto.ProjectChangeEvent.prototype.getJobqueuestatus = function() {
  return /** @type{?proto.JobQueueStatus} */ (
    jspb.Message.getWrapperField(this, proto.JobQueueStatus, 15));
};


/**
 * @param {?proto.JobQueueStatus|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setJobqueuestatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearJobqueuestatus = function() {
  return this.setJobqueuestatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasJobqueuestatus = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Configuration configuration = 16;
 * @return {?proto.Configuration}
 */
proto.ProjectChangeEvent.prototype.getConfiguration = function() {
  return /** @type{?proto.Configuration} */ (
    jspb.Message.getWrapperField(this, proto.Configuration, 16));
};


/**
 * @param {?proto.Configuration|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setConfiguration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearConfiguration = function() {
  return this.setConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasConfiguration = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional TargetPatternDefinition targetPatternDefinition = 17;
 * @return {?proto.TargetPatternDefinition}
 */
proto.ProjectChangeEvent.prototype.getTargetpatterndefinition = function() {
  return /** @type{?proto.TargetPatternDefinition} */ (
    jspb.Message.getWrapperField(this, proto.TargetPatternDefinition, 17));
};


/**
 * @param {?proto.TargetPatternDefinition|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setTargetpatterndefinition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearTargetpatterndefinition = function() {
  return this.setTargetpatterndefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasTargetpatterndefinition = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional Metamodel metamodel = 18;
 * @return {?proto.Metamodel}
 */
proto.ProjectChangeEvent.prototype.getMetamodel = function() {
  return /** @type{?proto.Metamodel} */ (
    jspb.Message.getWrapperField(this, proto.Metamodel, 18));
};


/**
 * @param {?proto.Metamodel|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setMetamodel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearMetamodel = function() {
  return this.setMetamodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasMetamodel = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional VectorFontDefinition vectorFontDefinition = 19;
 * @return {?proto.VectorFontDefinition}
 */
proto.ProjectChangeEvent.prototype.getVectorfontdefinition = function() {
  return /** @type{?proto.VectorFontDefinition} */ (
    jspb.Message.getWrapperField(this, proto.VectorFontDefinition, 19));
};


/**
 * @param {?proto.VectorFontDefinition|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setVectorfontdefinition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearVectorfontdefinition = function() {
  return this.setVectorfontdefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasVectorfontdefinition = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional Issue issue = 20;
 * @return {?proto.Issue}
 */
proto.ProjectChangeEvent.prototype.getIssue = function() {
  return /** @type{?proto.Issue} */ (
    jspb.Message.getWrapperField(this, proto.Issue, 20));
};


/**
 * @param {?proto.Issue|undefined} value
 * @return {!proto.ProjectChangeEvent} returns this
*/
proto.ProjectChangeEvent.prototype.setIssue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.ProjectChangeEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProjectChangeEvent} returns this
 */
proto.ProjectChangeEvent.prototype.clearIssue = function() {
  return this.setIssue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProjectChangeEvent.prototype.hasIssue = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SourceFile.prototype.toObject = function(opt_includeInstance) {
  return proto.SourceFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SourceFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SourceFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    claimed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SourceFile}
 */
proto.SourceFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SourceFile;
  return proto.SourceFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SourceFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SourceFile}
 */
proto.SourceFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClaimed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SourceFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SourceFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SourceFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SourceFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClaimed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string fileName = 1;
 * @return {string}
 */
proto.SourceFile.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SourceFile} returns this
 */
proto.SourceFile.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool claimed = 2;
 * @return {boolean}
 */
proto.SourceFile.prototype.getClaimed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SourceFile} returns this
 */
proto.SourceFile.prototype.setClaimed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Page.prototype.toObject = function(opt_includeInstance) {
  return proto.Page.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Page} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Page.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourcefile: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selectedfortranslation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    claimed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Page}
 */
proto.Page.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Page;
  return proto.Page.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Page} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Page}
 */
proto.Page.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourcefile(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSelectedfortranslation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClaimed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Page.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Page.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Page} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Page.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourcefile();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSelectedfortranslation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getClaimed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string sourceFile = 1;
 * @return {string}
 */
proto.Page.prototype.getSourcefile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Page} returns this
 */
proto.Page.prototype.setSourcefile = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 pageNumber = 2;
 * @return {number}
 */
proto.Page.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Page} returns this
 */
proto.Page.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool selectedForTranslation = 3;
 * @return {boolean}
 */
proto.Page.prototype.getSelectedfortranslation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Page} returns this
 */
proto.Page.prototype.setSelectedfortranslation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool claimed = 4;
 * @return {boolean}
 */
proto.Page.prototype.getClaimed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Page} returns this
 */
proto.Page.prototype.setClaimed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProjectTranslation.prototype.toObject = function(opt_includeInstance) {
  return proto.ProjectTranslation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProjectTranslation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectTranslation.toObject = function(includeInstance, msg) {
  var f, obj = {
    state: jspb.Message.getFieldWithDefault(msg, 1, ""),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    claimed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProjectTranslation}
 */
proto.ProjectTranslation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProjectTranslation;
  return proto.ProjectTranslation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProjectTranslation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProjectTranslation}
 */
proto.ProjectTranslation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClaimed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProjectTranslation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProjectTranslation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProjectTranslation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProjectTranslation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClaimed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string state = 1;
 * @return {string}
 */
proto.ProjectTranslation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProjectTranslation} returns this
 */
proto.ProjectTranslation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double progress = 2;
 * @return {number}
 */
proto.ProjectTranslation.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ProjectTranslation} returns this
 */
proto.ProjectTranslation.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional bool claimed = 3;
 * @return {boolean}
 */
proto.ProjectTranslation.prototype.getClaimed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProjectTranslation} returns this
 */
proto.ProjectTranslation.prototype.setClaimed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PageTranslation.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PageTranslation.prototype.toObject = function(opt_includeInstance) {
  return proto.PageTranslation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PageTranslation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PageTranslation.toObject = function(includeInstance, msg) {
  var f, obj = {
    translation: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, ""),
    state: jspb.Message.getFieldWithDefault(msg, 3, ""),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    availableresultsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    summaryMap: (f = msg.getSummaryMap()) ? f.toObject(includeInstance, undefined) : [],
    targetmodelpagecount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PageTranslation}
 */
proto.PageTranslation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PageTranslation;
  return proto.PageTranslation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PageTranslation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PageTranslation}
 */
proto.PageTranslation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTranslation(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 5:
      var value = /** @type {!Array<!proto.ResultType>} */ (reader.readPackedEnum());
      msg.setAvailableresultsList(value);
      break;
    case 6:
      var value = msg.getSummaryMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetmodelpagecount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PageTranslation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PageTranslation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PageTranslation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PageTranslation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTranslation();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAvailableresultsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getSummaryMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTargetmodelpagecount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string translation = 1;
 * @return {string}
 */
proto.PageTranslation.prototype.getTranslation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.setTranslation = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string page = 2;
 * @return {string}
 */
proto.PageTranslation.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string state = 3;
 * @return {string}
 */
proto.PageTranslation.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double progress = 4;
 * @return {number}
 */
proto.PageTranslation.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated ResultType availableResults = 5;
 * @return {!Array<!proto.ResultType>}
 */
proto.PageTranslation.prototype.getAvailableresultsList = function() {
  return /** @type {!Array<!proto.ResultType>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.ResultType>} value
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.setAvailableresultsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.ResultType} value
 * @param {number=} opt_index
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.addAvailableresults = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.clearAvailableresultsList = function() {
  return this.setAvailableresultsList([]);
};


/**
 * map<string, string> summary = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.PageTranslation.prototype.getSummaryMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.clearSummaryMap = function() {
  this.getSummaryMap().clear();
  return this;};


/**
 * optional int32 targetModelPageCount = 7;
 * @return {number}
 */
proto.PageTranslation.prototype.getTargetmodelpagecount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.PageTranslation} returns this
 */
proto.PageTranslation.prototype.setTargetmodelpagecount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Box.prototype.toObject = function(opt_includeInstance) {
  return proto.Box.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Box} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Box.toObject = function(includeInstance, msg) {
  var f, obj = {
    minx: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    miny: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    maxx: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    maxy: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Box}
 */
proto.Box.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Box;
  return proto.Box.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Box} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Box}
 */
proto.Box.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinx(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMiny(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxx(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Box.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Box.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Box} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Box.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinx();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMiny();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMaxx();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getMaxy();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double minX = 1;
 * @return {number}
 */
proto.Box.prototype.getMinx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Box} returns this
 */
proto.Box.prototype.setMinx = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double minY = 2;
 * @return {number}
 */
proto.Box.prototype.getMiny = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Box} returns this
 */
proto.Box.prototype.setMiny = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double maxX = 3;
 * @return {number}
 */
proto.Box.prototype.getMaxx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Box} returns this
 */
proto.Box.prototype.setMaxx = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double maxY = 4;
 * @return {number}
 */
proto.Box.prototype.getMaxy = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Box} returns this
 */
proto.Box.prototype.setMaxy = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ObjectPartAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.ObjectPartAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ObjectPartAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ObjectPartAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    boundingbox: (f = msg.getBoundingbox()) && proto.Box.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assignedpart: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ObjectPartAssignment}
 */
proto.ObjectPartAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ObjectPartAssignment;
  return proto.ObjectPartAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ObjectPartAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ObjectPartAssignment}
 */
proto.ObjectPartAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Box;
      reader.readMessage(value,proto.Box.deserializeBinaryFromReader);
      msg.setBoundingbox(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedpart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ObjectPartAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ObjectPartAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ObjectPartAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ObjectPartAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoundingbox();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.Box.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssignedpart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Box boundingBox = 1;
 * @return {?proto.Box}
 */
proto.ObjectPartAssignment.prototype.getBoundingbox = function() {
  return /** @type{?proto.Box} */ (
    jspb.Message.getWrapperField(this, proto.Box, 1));
};


/**
 * @param {?proto.Box|undefined} value
 * @return {!proto.ObjectPartAssignment} returns this
*/
proto.ObjectPartAssignment.prototype.setBoundingbox = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ObjectPartAssignment} returns this
 */
proto.ObjectPartAssignment.prototype.clearBoundingbox = function() {
  return this.setBoundingbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ObjectPartAssignment.prototype.hasBoundingbox = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.ObjectPartAssignment.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ObjectPartAssignment} returns this
 */
proto.ObjectPartAssignment.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string assignedPart = 3;
 * @return {string}
 */
proto.ObjectPartAssignment.prototype.getAssignedpart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ObjectPartAssignment} returns this
 */
proto.ObjectPartAssignment.prototype.setAssignedpart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PagePart.prototype.toObject = function(opt_includeInstance) {
  return proto.PagePart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PagePart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PagePart.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PagePart}
 */
proto.PagePart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PagePart;
  return proto.PagePart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PagePart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PagePart}
 */
proto.PagePart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PagePart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PagePart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PagePart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PagePart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.PagePart.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PagePart} returns this
 */
proto.PagePart.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.PagePart.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PagePart} returns this
 */
proto.PagePart.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.PagePart.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PagePart} returns this
 */
proto.PagePart.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PagePartition.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PagePartition.prototype.toObject = function(opt_includeInstance) {
  return proto.PagePartition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PagePartition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PagePartition.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, ""),
    partsList: jspb.Message.toObjectList(msg.getPartsList(),
    proto.PagePart.toObject, includeInstance),
    assignmentsList: jspb.Message.toObjectList(msg.getAssignmentsList(),
    proto.ObjectPartAssignment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PagePartition}
 */
proto.PagePartition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PagePartition;
  return proto.PagePartition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PagePartition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PagePartition}
 */
proto.PagePartition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 2:
      var value = new proto.PagePart;
      reader.readMessage(value,proto.PagePart.deserializeBinaryFromReader);
      msg.addParts(value);
      break;
    case 3:
      var value = new proto.ObjectPartAssignment;
      reader.readMessage(value,proto.ObjectPartAssignment.deserializeBinaryFromReader);
      msg.addAssignments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PagePartition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PagePartition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PagePartition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PagePartition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPartsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.PagePart.serializeBinaryToWriter
    );
  }
  f = message.getAssignmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ObjectPartAssignment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string page = 1;
 * @return {string}
 */
proto.PagePartition.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PagePartition} returns this
 */
proto.PagePartition.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated PagePart parts = 2;
 * @return {!Array<!proto.PagePart>}
 */
proto.PagePartition.prototype.getPartsList = function() {
  return /** @type{!Array<!proto.PagePart>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PagePart, 2));
};


/**
 * @param {!Array<!proto.PagePart>} value
 * @return {!proto.PagePartition} returns this
*/
proto.PagePartition.prototype.setPartsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.PagePart=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PagePart}
 */
proto.PagePartition.prototype.addParts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.PagePart, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PagePartition} returns this
 */
proto.PagePartition.prototype.clearPartsList = function() {
  return this.setPartsList([]);
};


/**
 * repeated ObjectPartAssignment assignments = 3;
 * @return {!Array<!proto.ObjectPartAssignment>}
 */
proto.PagePartition.prototype.getAssignmentsList = function() {
  return /** @type{!Array<!proto.ObjectPartAssignment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ObjectPartAssignment, 3));
};


/**
 * @param {!Array<!proto.ObjectPartAssignment>} value
 * @return {!proto.PagePartition} returns this
*/
proto.PagePartition.prototype.setAssignmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ObjectPartAssignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ObjectPartAssignment}
 */
proto.PagePartition.prototype.addAssignments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ObjectPartAssignment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PagePartition} returns this
 */
proto.PagePartition.prototype.clearAssignmentsList = function() {
  return this.setAssignmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Message.prototype.toObject = function(opt_includeInstance) {
  return proto.Message.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Message} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Message.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    created: (f = msg.getCreated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Message}
 */
proto.Message.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Message;
  return proto.Message.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Message}
 */
proto.Message.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Message.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Message.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreated();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user = 1;
 * @return {string}
 */
proto.Message.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Message} returns this
 */
proto.Message.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.Message.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Message} returns this
 */
proto.Message.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.Message.prototype.getCreated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.Message} returns this
*/
proto.Message.prototype.setCreated = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Message} returns this
 */
proto.Message.prototype.clearCreated = function() {
  return this.setCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Message.prototype.hasCreated = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.Message.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Message} returns this
 */
proto.Message.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Issue.prototype.toObject = function(opt_includeInstance) {
  return proto.Issue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Issue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Issue.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    location: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Issue}
 */
proto.Issue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Issue;
  return proto.Issue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Issue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Issue}
 */
proto.Issue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Issue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Issue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Issue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Issue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.Issue.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Issue} returns this
 */
proto.Issue.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string location = 2;
 * @return {string}
 */
proto.Issue.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Issue} returns this
 */
proto.Issue.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Property.prototype.toObject = function(opt_includeInstance) {
  return proto.Property.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Property} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Property.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    origin: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Property}
 */
proto.Property.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Property;
  return proto.Property.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Property}
 */
proto.Property.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {!proto.PropertyOrigin} */ (reader.readEnum());
      msg.setOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Property.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Property.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrigin();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.Property.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Property} returns this
 */
proto.Property.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PropertyOrigin origin = 2;
 * @return {!proto.PropertyOrigin}
 */
proto.Property.prototype.getOrigin = function() {
  return /** @type {!proto.PropertyOrigin} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.PropertyOrigin} value
 * @return {!proto.Property} returns this
 */
proto.Property.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.PropertyType.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.PropertyType.TypeCase = {
  TYPE_NOT_SET: 0,
  NUMBERTYPE: 1,
  STRINGTYPE: 2,
  ENUMTYPE: 3
};

/**
 * @return {proto.PropertyType.TypeCase}
 */
proto.PropertyType.prototype.getTypeCase = function() {
  return /** @type {proto.PropertyType.TypeCase} */(jspb.Message.computeOneofCase(this, proto.PropertyType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PropertyType.prototype.toObject = function(opt_includeInstance) {
  return proto.PropertyType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PropertyType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PropertyType.toObject = function(includeInstance, msg) {
  var f, obj = {
    numbertype: (f = msg.getNumbertype()) && proto.NumberType.toObject(includeInstance, f),
    stringtype: (f = msg.getStringtype()) && proto.StringType.toObject(includeInstance, f),
    enumtype: (f = msg.getEnumtype()) && proto.EnumType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PropertyType}
 */
proto.PropertyType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PropertyType;
  return proto.PropertyType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PropertyType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PropertyType}
 */
proto.PropertyType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.NumberType;
      reader.readMessage(value,proto.NumberType.deserializeBinaryFromReader);
      msg.setNumbertype(value);
      break;
    case 2:
      var value = new proto.StringType;
      reader.readMessage(value,proto.StringType.deserializeBinaryFromReader);
      msg.setStringtype(value);
      break;
    case 3:
      var value = new proto.EnumType;
      reader.readMessage(value,proto.EnumType.deserializeBinaryFromReader);
      msg.setEnumtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PropertyType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PropertyType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PropertyType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PropertyType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumbertype();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.NumberType.serializeBinaryToWriter
    );
  }
  f = message.getStringtype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.StringType.serializeBinaryToWriter
    );
  }
  f = message.getEnumtype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.EnumType.serializeBinaryToWriter
    );
  }
};


/**
 * optional NumberType numberType = 1;
 * @return {?proto.NumberType}
 */
proto.PropertyType.prototype.getNumbertype = function() {
  return /** @type{?proto.NumberType} */ (
    jspb.Message.getWrapperField(this, proto.NumberType, 1));
};


/**
 * @param {?proto.NumberType|undefined} value
 * @return {!proto.PropertyType} returns this
*/
proto.PropertyType.prototype.setNumbertype = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.PropertyType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PropertyType} returns this
 */
proto.PropertyType.prototype.clearNumbertype = function() {
  return this.setNumbertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PropertyType.prototype.hasNumbertype = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StringType stringType = 2;
 * @return {?proto.StringType}
 */
proto.PropertyType.prototype.getStringtype = function() {
  return /** @type{?proto.StringType} */ (
    jspb.Message.getWrapperField(this, proto.StringType, 2));
};


/**
 * @param {?proto.StringType|undefined} value
 * @return {!proto.PropertyType} returns this
*/
proto.PropertyType.prototype.setStringtype = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.PropertyType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PropertyType} returns this
 */
proto.PropertyType.prototype.clearStringtype = function() {
  return this.setStringtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PropertyType.prototype.hasStringtype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EnumType enumType = 3;
 * @return {?proto.EnumType}
 */
proto.PropertyType.prototype.getEnumtype = function() {
  return /** @type{?proto.EnumType} */ (
    jspb.Message.getWrapperField(this, proto.EnumType, 3));
};


/**
 * @param {?proto.EnumType|undefined} value
 * @return {!proto.PropertyType} returns this
*/
proto.PropertyType.prototype.setEnumtype = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.PropertyType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PropertyType} returns this
 */
proto.PropertyType.prototype.clearEnumtype = function() {
  return this.setEnumtype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PropertyType.prototype.hasEnumtype = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StringType.prototype.toObject = function(opt_includeInstance) {
  return proto.StringType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StringType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StringType}
 */
proto.StringType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StringType;
  return proto.StringType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StringType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StringType}
 */
proto.StringType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StringType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StringType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StringType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StringType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NumberType.prototype.toObject = function(opt_includeInstance) {
  return proto.NumberType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NumberType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NumberType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NumberType}
 */
proto.NumberType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NumberType;
  return proto.NumberType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NumberType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NumberType}
 */
proto.NumberType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NumberType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NumberType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NumberType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NumberType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EnumType.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EnumType.prototype.toObject = function(opt_includeInstance) {
  return proto.EnumType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EnumType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EnumType.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.EnumItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EnumType}
 */
proto.EnumType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EnumType;
  return proto.EnumType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EnumType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EnumType}
 */
proto.EnumType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EnumItem;
      reader.readMessage(value,proto.EnumItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EnumType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EnumType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EnumType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EnumType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EnumItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnumItem items = 1;
 * @return {!Array<!proto.EnumItem>}
 */
proto.EnumType.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.EnumItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EnumItem, 1));
};


/**
 * @param {!Array<!proto.EnumItem>} value
 * @return {!proto.EnumType} returns this
*/
proto.EnumType.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EnumItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EnumItem}
 */
proto.EnumType.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EnumItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EnumType} returns this
 */
proto.EnumType.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EnumItem.prototype.toObject = function(opt_includeInstance) {
  return proto.EnumItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EnumItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EnumItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EnumItem}
 */
proto.EnumItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EnumItem;
  return proto.EnumItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EnumItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EnumItem}
 */
proto.EnumItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EnumItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EnumItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EnumItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EnumItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.EnumItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EnumItem} returns this
 */
proto.EnumItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.EnumItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EnumItem} returns this
 */
proto.EnumItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PatternDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.PatternDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PatternDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PatternDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    updated: (f = msg.getUpdated()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    definition: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PatternDefinition}
 */
proto.PatternDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PatternDefinition;
  return proto.PatternDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PatternDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PatternDefinition}
 */
proto.PatternDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefinition(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PatternDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PatternDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PatternDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PatternDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDefinition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.PatternDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PatternDefinition} returns this
 */
proto.PatternDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp updated = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.PatternDefinition.prototype.getUpdated = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.PatternDefinition} returns this
*/
proto.PatternDefinition.prototype.setUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PatternDefinition} returns this
 */
proto.PatternDefinition.prototype.clearUpdated = function() {
  return this.setUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PatternDefinition.prototype.hasUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string definition = 3;
 * @return {string}
 */
proto.PatternDefinition.prototype.getDefinition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PatternDefinition} returns this
 */
proto.PatternDefinition.prototype.setDefinition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.PatternDefinition.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PatternDefinition} returns this
 */
proto.PatternDefinition.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TargetPatternDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.TargetPatternDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TargetPatternDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TargetPatternDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    definition: jspb.Message.getFieldWithDefault(msg, 2, ""),
    target: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mappable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TargetPatternDefinition}
 */
proto.TargetPatternDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TargetPatternDefinition;
  return proto.TargetPatternDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TargetPatternDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TargetPatternDefinition}
 */
proto.TargetPatternDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefinition(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMappable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TargetPatternDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TargetPatternDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TargetPatternDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TargetPatternDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefinition();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMappable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.TargetPatternDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TargetPatternDefinition} returns this
 */
proto.TargetPatternDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string definition = 2;
 * @return {string}
 */
proto.TargetPatternDefinition.prototype.getDefinition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TargetPatternDefinition} returns this
 */
proto.TargetPatternDefinition.prototype.setDefinition = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string target = 3;
 * @return {string}
 */
proto.TargetPatternDefinition.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TargetPatternDefinition} returns this
 */
proto.TargetPatternDefinition.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool mappable = 4;
 * @return {boolean}
 */
proto.TargetPatternDefinition.prototype.getMappable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TargetPatternDefinition} returns this
 */
proto.TargetPatternDefinition.prototype.setMappable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool editable = 5;
 * @return {boolean}
 */
proto.TargetPatternDefinition.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TargetPatternDefinition} returns this
 */
proto.TargetPatternDefinition.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DrawingSheetDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.DrawingSheetDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DrawingSheetDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DrawingSheetDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    definition: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DrawingSheetDefinition}
 */
proto.DrawingSheetDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DrawingSheetDefinition;
  return proto.DrawingSheetDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DrawingSheetDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DrawingSheetDefinition}
 */
proto.DrawingSheetDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefinition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DrawingSheetDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DrawingSheetDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DrawingSheetDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DrawingSheetDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefinition();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string definition = 2;
 * @return {string}
 */
proto.DrawingSheetDefinition.prototype.getDefinition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DrawingSheetDefinition} returns this
 */
proto.DrawingSheetDefinition.prototype.setDefinition = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TargetConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.TargetConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TargetConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TargetConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    schema: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TargetConfiguration}
 */
proto.TargetConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TargetConfiguration;
  return proto.TargetConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TargetConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TargetConfiguration}
 */
proto.TargetConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TargetConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TargetConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TargetConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TargetConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSchema();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.TargetConfiguration.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TargetConfiguration} returns this
 */
proto.TargetConfiguration.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.TargetConfiguration.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TargetConfiguration} returns this
 */
proto.TargetConfiguration.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string schema = 3;
 * @return {string}
 */
proto.TargetConfiguration.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TargetConfiguration} returns this
 */
proto.TargetConfiguration.prototype.setSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Metamodel.prototype.toObject = function(opt_includeInstance) {
  return proto.Metamodel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Metamodel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Metamodel.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Metamodel}
 */
proto.Metamodel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Metamodel;
  return proto.Metamodel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Metamodel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Metamodel}
 */
proto.Metamodel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Metamodel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Metamodel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Metamodel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Metamodel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string target = 1;
 * @return {string}
 */
proto.Metamodel.prototype.getTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Metamodel} returns this
 */
proto.Metamodel.prototype.setTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.Metamodel.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Metamodel} returns this
 */
proto.Metamodel.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.Metamodel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Metamodel} returns this
 */
proto.Metamodel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VectorFontDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.VectorFontDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VectorFontDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VectorFontDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VectorFontDefinition}
 */
proto.VectorFontDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VectorFontDefinition;
  return proto.VectorFontDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VectorFontDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VectorFontDefinition}
 */
proto.VectorFontDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VectorFontDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VectorFontDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VectorFontDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VectorFontDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.VectorFontDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.VectorFontDefinition} returns this
 */
proto.VectorFontDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.JobQueueStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.JobQueueStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.JobQueueStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobQueueStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobcount: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.JobQueueStatus}
 */
proto.JobQueueStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.JobQueueStatus;
  return proto.JobQueueStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.JobQueueStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.JobQueueStatus}
 */
proto.JobQueueStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setJobcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.JobQueueStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.JobQueueStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.JobQueueStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.JobQueueStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobcount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 jobCount = 1;
 * @return {number}
 */
proto.JobQueueStatus.prototype.getJobcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.JobQueueStatus} returns this
 */
proto.JobQueueStatus.prototype.setJobcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Configuration.prototype.toObject = function(opt_includeInstance) {
  return proto.Configuration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Configuration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Configuration.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Configuration}
 */
proto.Configuration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Configuration;
  return proto.Configuration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Configuration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Configuration}
 */
proto.Configuration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Configuration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Configuration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Configuration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Configuration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.Configuration.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Configuration} returns this
 */
proto.Configuration.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.ChangeOperation = {
  INSERT: 0,
  REPLACE: 1,
  DELETE: 2
};

/**
 * @enum {number}
 */
proto.TargetCollection = {
  SOURCEFILES: 0,
  PAGES: 1,
  PROJECTTRANSLATIONS: 2,
  PAGETRANSLATIONS: 3,
  MESSAGES: 4,
  PROPERTIES: 5,
  PROPERTYTYPES: 6,
  PATTERNDEFINITIONS: 7,
  PAGEPARTITIONS: 8,
  KEEPALIVE: 9,
  TARGETCONFIGURATIONS: 10,
  DRAWINGSHEETDEFINITIONS: 11,
  JOBQUEUESTATUSES: 12,
  CONFIGURATIONS: 13,
  TARGETPATTERNDEFINITIONS: 14,
  METAMODELS: 15,
  VECTORFONTDEFINITIONS: 16,
  ISSUES: 17
};

/**
 * @enum {number}
 */
proto.ResultType = {
  ERRORMESSAGE: 0,
  TARGETMODELIMAGE: 1,
  DCSPAGE: 2,
  VMPAGE: 3,
  ANNOTATIONS: 4,
  VMSHEETCONNECTIONS: 5,
  DIAGRAMTRANSFORM: 6,
  NODING: 7,
  SHEETNUMBER: 8,
  PROTEUSPAGE: 9,
  APROSPAGE: 10,
  APROSMODELYAML: 11,
  APROSSHEETCONNECTIONS: 12,
  PLCOPENPAGE: 13,
  PLCOPENMODELYAML: 14,
  PLCOPENSHEETCONNECTIONS: 15
};

/**
 * @enum {number}
 */
proto.PropertyOrigin = {
  PROFILE: 0,
  PROJECT: 1
};

goog.object.extend(exports, proto);
