import { grpc } from '@improbable-eng/grpc-web';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/jobqueue';
import { Job, JobQueueStatusEvent } from '../proto/projectList_pb';

export interface JobQueueStore {
    subscription: grpc.Request | null;
    jobs: Job[];
}

const initialState: JobQueueStore = {
    subscription: null,
    jobs: []
};

export default function (state: JobQueueStore = initialState, action: IStoreActions): JobQueueStore {
    if (action.type === getType(actions.receivedJobQueueStatusEvent)) {
        const event = action.payload;
        switch (event.getEventCase()) {
            case JobQueueStatusEvent.EventCase.ADDED: {
                const newJob = event.getAdded()!;
                const newJobId = newJob.getJobid();
                const jobs = state.jobs.filter(job => job.getJobid() !== newJobId);
                jobs.push(newJob);
                return {
                    ...state,
                    jobs
                };
            }
            case JobQueueStatusEvent.EventCase.REMOVED: {
                const oldJobId = event.getRemoved()!.getJobid();
                const jobs = state.jobs.filter(job => job.getJobid() !== oldJobId);
                return { 
                    ...state,
                    jobs
                };
            }
            default:
                return state;
        }
    } else if (action.type === getType(actions.subscribedJobQueueStatus)) {
        return {
            subscription: action.payload,
            jobs: []
        };
    } else if (action.type === getType(actions.unsubscribedJobQueueStatus)) {
        return {
            subscription: null,
            jobs: []
        };
    } else {
        return state;
    }
}