import { grpc } from '@improbable-eng/grpc-web';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/paper2pidservice';
import { ChangeOperation, Message, ProjectChangeEvent, TargetCollection, TargetCollectionMap } from '../proto/projectChangeEvents_pb';
import notifications from "../actions/notifications";
import uuid from 'uuid';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export interface Paper2PidState {
    messages: { [messageId: string]: Message; };
    subscriptions: { [projectId: string]: grpc.Request; };
    connectionProblems: { [projectId: string]: string; };
}

const initialState = {
    messages: {},
    subscriptions: {},
    connectionProblems: {},
};

export default function (state: Paper2PidState = initialState, action: IStoreActions): Paper2PidState {
    switch (action.type) {
        case getType(actions.receivedProjectChangeEvent):
            return handleProjectChangeEvent(state, action.payload);
        case getType(actions.fetchPageDataBegin):
            return state;
        case getType(actions.fetchPageDataSuccess):
        case getType(actions.fetchAnnotationsSuccess):
            return produce(state, (draftState) => {
            });
        case getType(actions.subscribed):
            return produce(state, (draftState) => {
                const { projectId, stream } = action.payload;
                const oldSubscription = draftState.subscriptions[projectId];
                if (oldSubscription) {
                    oldSubscription.close();
                }
                draftState.subscriptions[projectId] = stream;
            });
        case getType(actions.unsubscribe):
            const projectId = action.payload.projectId;
            const stream = state.subscriptions[projectId];
            if (stream) {
                // cancel listening
                stream.close();
            }
            const reason = action.payload.reason;
            if (reason)
                return {
                    ...initialState,
                    connectionProblems: {[projectId]: reason}
                };
            else
                return initialState;
        case getType(notifications.notificationShow): {
            let message = new Message();
            message.setContent(action.payload.header + "::" + action.payload.message);
            let timestamp = new Timestamp();
            timestamp.fromDate(action.payload.date);
            message.setCreated(timestamp);
            message.setType(action.payload.type);

            return produce(state, draftState => {
                draftState.messages[timestamp.toString()] = message;
            });
        } break;
        default:
            return state;
    }
}

const handleProjectChangeEvent = (state: Paper2PidState, changeEvent: ProjectChangeEvent): Paper2PidState => {
    const targetCollection: TargetCollectionMap[keyof TargetCollectionMap] = changeEvent.getTargetcollection();
    switch (targetCollection) {
        case TargetCollection.MESSAGES:
            return handleMessagesEvent(state, changeEvent);
        default:
            return state;
    }
};

const handleMessagesEvent = (state: Paper2PidState, changeEvent: ProjectChangeEvent): Paper2PidState => {
    switch (changeEvent.getChangeoperation()) {
        case ChangeOperation.INSERT:
            return handleMessagesInsert(state, changeEvent);
        case ChangeOperation.DELETE:
            return handleMessagesDelete(state, changeEvent);
        default:
            return state;
    }
};

const handleMessagesInsert = (state: Paper2PidState, changeEvent: ProjectChangeEvent): Paper2PidState => {
    const messageId = changeEvent.getContentid();
    const message: Message = changeEvent.getMessage()!;
    return produce(state, (draftState) => {
        draftState.messages[messageId] = message;
    });
};

const handleMessagesDelete = (state: Paper2PidState, changeEvent: ProjectChangeEvent): Paper2PidState => {
    // TODO
    return state;
};
