import { grpc } from '@improbable-eng/grpc-web';
import * as jspb from 'google-protobuf';
import { Action } from 'redux';
import { action } from 'typesafe-actions';

export const GRPC_WEB_REQUEST = 'GRPC_WEB_REQUEST';

// Basic type for a gRPC Action
export interface GrpcAction<RequestType extends jspb.Message, ResponseType extends jspb.Message> {
    type: typeof GRPC_WEB_REQUEST;
    payload: GrpcActionPayload<RequestType, ResponseType>;
}

// Descriptor of a grpc-web payload
// life-cycle methods mirror grpc-web but allow for an action to be dispatched when triggered
export interface GrpcActionPayload<RequestType extends jspb.Message, ResponseType extends jspb.Message> {
    // The method descriptor to use for a gRPC request, equivalent to grpc.invoke(methodDescriptor, ...)
    methodDescriptor: grpc.MethodDefinition<RequestType, ResponseType>;
    // The transport to use for grpc-web, automatically selected if empty
    transport?: grpc.Transport;
    // toggle debug messages
    debug?: boolean;
    // An instance of of the request message
    request: RequestType;
    // Additional metadata to attach to the request, the same as grpc-web
    metadata?: grpc.Metadata.ConstructorArg;
    // Called immediately before the request is started, useful for toggling a loading status
    onStart?: (stream: grpc.Request) => Action | void;
    // Called when response headers are received
    onHeaders?: (headers: grpc.Metadata) => Action | void;
    // Called on each incoming message
    onMessage?: (res: ResponseType) => Action | void;
    // Called at the end of a request, make sure to check the exit code
    onEnd?: (code: grpc.Code, message: string, trailers: grpc.Metadata) => Action | void;
    onError?: (code: grpc.Code, message: string) => Action | void;

    batch?: boolean;
}

// type with payload
export const grpcRequest = <Req extends jspb.Message, Res extends jspb.Message>(payload: GrpcActionPayload<Req, Res>) =>
    action(GRPC_WEB_REQUEST, payload);
