import { createAction } from 'typesafe-actions';
import { getDefaultPLCOpenTargetPattern, getDefaultProteusTargetPattern, getDefaultTargetPattern, TargetPattern, targetPatternsToString, targetPatternToString } from '../components/targetpatterns/TargetPattern';
import { TargetPatternDefinition } from '../proto/projectChangeEvents_pb';
import { CreateTargetPatternRequest, CreateTargetPatternResponse, NormalizeTargetPatternDefinitionRequest, NormalizeTargetPatternDefinitionResponse, RemoveTargetPatternsRequest, RemoveTargetPatternsResponse, UpdateTargetPatternsRequest, UpdateTargetPatternsResponse } from '../proto/project_pb';
import { ProjectService } from '../proto/project_pb_service';
import { StoreState } from '../store';
import { ThunkDispatch, ThunkResult } from '../store-types';
import { GrpcActionPayload, grpcRequest } from './grpc';
import { stdOnError } from './grpcutils';
import { showErrorNotification } from './notifications';

export const updateTargetPattern = createAction('UPDATE_TARGET_PATTERN', (resolve) => {
    return (projectId: string, targetPatternId: string, targetPattern: TargetPattern) => resolve({ projectId, targetPatternId, targetPattern });
});

export const clearModifiedTargetPatterns = createAction('CLEAR_MODIFIED_TARGET_PATTERNS', (resolve) => {
    return () => resolve();
});

export const clearModifiedTargetPattern = createAction('CLEAR_MODIFIED_TARGET_PATTERN', (resolve) => {
    return (targetPatternId: string) => resolve(targetPatternId);
});

export const saveAllModifiedTargetPatterns = (projectId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { targetPatternDefinitions, modifiedTargetPatternDefinitions } = getState().targetpatterndefinitions;

        const request = new UpdateTargetPatternsRequest();
        request.setProjectid(projectId);
        const updatesMap = request.getUpdatesMap();
        for (const id of Object.keys(modifiedTargetPatternDefinitions)) {
            const targetPattern = modifiedTargetPatternDefinitions[id];
            const targetPatternDefinition = new TargetPatternDefinition();
            const target = targetPatternDefinitions[id].target;
            targetPatternDefinition.setName(targetPattern.id);
            targetPatternDefinition.setDefinition(targetPatternToString(targetPattern, target));
            targetPatternDefinition.setTarget(target);
            targetPatternDefinition.setMappable(targetPatternDefinitions[id].mappable);
            targetPatternDefinition.setEditable(targetPatternDefinitions[id].editable);
            updatesMap.set(id, targetPatternDefinition);
        }

        const grpcAction: GrpcActionPayload<UpdateTargetPatternsRequest, UpdateTargetPatternsResponse> = {
            batch: true,
            methodDescriptor: ProjectService.UpdateTargetPatterns,
            onMessage: (event) => dispatch(clearModifiedTargetPatterns()),
            onError: stdOnError(dispatch, 'Saving target patterns'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const saveModifiedTargetPattern = (projectId: string, targetPatternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { targetPatternDefinitions, modifiedTargetPatternDefinitions } = getState().targetpatterndefinitions;

        const request = new UpdateTargetPatternsRequest();
        request.setProjectid(projectId);
        const updatesMap = request.getUpdatesMap();
        const targetPattern = modifiedTargetPatternDefinitions[targetPatternId];
        if (!targetPattern)
            return;
        const targetPatternDefinition = new TargetPatternDefinition();
        const target = targetPatternDefinitions[targetPatternId].target;
        targetPatternDefinition.setName(targetPattern.id);
        targetPatternDefinition.setDefinition(targetPatternToString(targetPattern, target));
        targetPatternDefinition.setTarget(target);
        targetPatternDefinition.setMappable(targetPatternDefinitions[targetPatternId].mappable);
        targetPatternDefinition.setEditable(targetPatternDefinitions[targetPatternId].editable);
        updatesMap.set(targetPatternId, targetPatternDefinition);

        const grpcAction: GrpcActionPayload<UpdateTargetPatternsRequest, UpdateTargetPatternsResponse> = {
            batch: true,
            methodDescriptor: ProjectService.UpdateTargetPatterns,
            onMessage: (event) => dispatch(clearModifiedTargetPattern(targetPatternId)),
            onError: stdOnError(dispatch, 'Saving target patterns'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const removeTargetPatterns = (projectId: string, targetPatternIds: string[]): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new RemoveTargetPatternsRequest();
        request.setProjectid(projectId);
        request.setTargetpatternidsList(targetPatternIds);

        const grpcAction: GrpcActionPayload<RemoveTargetPatternsRequest, RemoveTargetPatternsResponse> = {
            methodDescriptor: ProjectService.RemoveTargetPatterns,
            onError: stdOnError(dispatch, 'Removing target pattern'),
            request,
        };

        for (const targetPatternId of targetPatternIds){
            dispatch(clearModifiedTargetPattern(targetPatternId));
        }
        dispatch(grpcRequest(grpcAction));
    };
};

export const removeTargetPattern = (projectId: string, targetPatternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new RemoveTargetPatternsRequest();
        request.setProjectid(projectId);
        request.setTargetpatternidsList([targetPatternId]);

        const grpcAction: GrpcActionPayload<RemoveTargetPatternsRequest, RemoveTargetPatternsResponse> = {
            methodDescriptor: ProjectService.RemoveTargetPatterns,
            onError: stdOnError(dispatch, 'Removing target pattern'),
            request,
        };
        dispatch(clearModifiedTargetPattern(targetPatternId));
        dispatch(grpcRequest(grpcAction));
    };
};

export const duplicateTargetPattern = (projectId: string, targetPatternId: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { targetpatterndefinitions } = getState();

        // Find pattern definition
        var targetPattern = targetpatterndefinitions.modifiedTargetPatternDefinitions[targetPatternId];
        if (!targetPattern) {
            const targetPatternDef = targetpatterndefinitions.targetPatternDefinitions[targetPatternId];
            if (targetPatternDef)
                targetPattern = targetPatternDef.targetPattern;
            else
                return;
        }

        // Rename
        targetPattern = { ...targetPattern, id: targetPattern.id + " (copy)"};
        const target = targetpatterndefinitions.targetPatternDefinitions[targetPatternId].target;

        const definition = new TargetPatternDefinition();
        definition.setName(targetPattern.id);
        definition.setDefinition(targetPatternToString(targetPattern, target));
        definition.setTarget(target);
        definition.setMappable(targetpatterndefinitions.targetPatternDefinitions[targetPatternId].mappable);
        definition.setEditable(targetpatterndefinitions.targetPatternDefinitions[targetPatternId].editable);

        const request = new CreateTargetPatternRequest();
        request.setProjectid(projectId);
        request.setDefinition(definition);

        // Invoke
        const grpcAction: GrpcActionPayload<CreateTargetPatternRequest, CreateTargetPatternResponse> = {
            methodDescriptor: ProjectService.CreateTargetPattern,
            onError: stdOnError(dispatch, 'Duplicating target pattern'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const createNewTargetPattern = (projectId: string, target: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        var targetPattern;
        switch(target) {
            case 'APROS':
            case 'DCS':
            case 'VM':
                targetPattern = getDefaultTargetPattern();
                break;
            case 'PLCOPEN':
                targetPattern = getDefaultPLCOpenTargetPattern();
                break;
            case 'Proteus':
                targetPattern = getDefaultProteusTargetPattern();
                break;
            default:
                dispatch(showErrorNotification(
                    'Creation of new pattern failed',
                    'Creating new patterns for target \'' + target + '\' not supported yet.'
                ));
                return;
        }
        const definition = new TargetPatternDefinition();
        definition.setName(targetPattern.id);
        definition.setDefinition(targetPatternToString(targetPattern, target));
        definition.setTarget(target);
        definition.setMappable(true);
        definition.setEditable(true);

        const request = new CreateTargetPatternRequest();
        request.setProjectid(projectId);
        request.setDefinition(definition);

        // Invoke
        const grpcAction: GrpcActionPayload<CreateTargetPatternRequest, CreateTargetPatternResponse> = {
            methodDescriptor: ProjectService.CreateTargetPattern,
            onError: stdOnError(dispatch, 'Creating new target pattern'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    };
};

export const normalizeAndCopyToClipboard = (targetPatterns: TargetPattern[], target: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const request = new NormalizeTargetPatternDefinitionRequest();
        request.setDefinition(targetPatternsToString(targetPatterns, target));

        const grpcAction: GrpcActionPayload<NormalizeTargetPatternDefinitionRequest, NormalizeTargetPatternDefinitionResponse> = {
            batch: true,
            methodDescriptor: ProjectService.NormalizeTargetPatternDefinition,
            onMessage: (event) => {
                navigator.clipboard.writeText(event.getDefinition());
            },
            onError: stdOnError(dispatch, 'Normalizing target pattern'),
            request,
        };
        dispatch(grpcRequest(grpcAction));
    }
}

export const copyTargetPatternsToClipboard = (targetPatternIds: string[], target: string): ThunkResult<void> => {
    return (dispatch: ThunkDispatch, getState: () => StoreState) => {
        const { targetpatterndefinitions } = getState();
        const targetPatterns: TargetPattern[] = targetPatternIds.map(id => {
            const modifiedTargetPattern = targetpatterndefinitions.modifiedTargetPatternDefinitions[id];
            if(modifiedTargetPattern)
                return modifiedTargetPattern;
            const targetPatternDef = targetpatterndefinitions.targetPatternDefinitions[id];
            if(targetPatternDef)
                return targetPatternDef.targetPattern;
            return null;
        }).filter(targetPattern => targetPattern !== null) as TargetPattern[];
        dispatch(normalizeAndCopyToClipboard(targetPatterns, target));
    }
}

export default {
    updateTargetPattern, clearModifiedTargetPatterns, clearModifiedTargetPattern
};
