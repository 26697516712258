import { Map } from "immutable";
import { getType } from "typesafe-actions";
import { IStoreActions } from "../actions";
import actions from "../actions/paper2pidservice";
import {
    ChangeOperation,
    Issue,
    TargetCollection,
} from "../proto/projectChangeEvents_pb";

export interface IssueStore {
    store: Map<string, Issue.AsObject>;
    index: IssueIndex;
}

export interface IssueIndex {
    issues: Issue.AsObject[];
    children: Map<string, IssueIndex>;
}

const EMPTY_ISSUE_INDEX: IssueIndex = {
    issues: [],
    children: Map(),
};

const defaultIssueStore: IssueStore = {
    store: Map(),
    index: EMPTY_ISSUE_INDEX,
};

export function issueIndexChild(name: string, index: IssueIndex): IssueIndex {
    const result = index.children.get(name);
    if (result === undefined) return EMPTY_ISSUE_INDEX;
    else return result;
}

export function hasIssues(location: string, index: IssueIndex): boolean {
    for(const name of location.split(".")) {
        const maybeIndex = index.children.get(name);;
        if(maybeIndex === undefined)
            return false;
        else
            index = maybeIndex;
    }
    return index.issues.length > 0 || index.children.size > 0;
}

export function issueIndexChildAndRemaining(name: string, index: IssueIndex): [IssueIndex ,IssueIndex] {
    const result = index.children.get(name);
    if (result === undefined)
        return [EMPTY_ISSUE_INDEX, index];
    else {
        const remaining = {
            issues: index.issues,
            children: index.children.remove(name)
        }
        return [result, remaining];
    }
}

export function isIssueIndexNonempty(index: IssueIndex) {
    return index.issues.length > 0 || index.children.size > 0;
}

const EMPTY_ARRAY: Issue.AsObject[] = [];

export function issueIndexIssues(index?: IssueIndex): Issue.AsObject[] {
    if (index === undefined) return EMPTY_ARRAY;
    else return index.issues;
}

function addToIndex(
    index: IssueIndex,
    location: string,
    issue: Issue.AsObject
): IssueIndex {
    const { issues, children } = index;
    var key: string, newChildIndex: IssueIndex;

    const p = location.indexOf(".");
    if (p == -1) {
        key = location;
        const oldChildIndex = children.get(key);
        if (oldChildIndex === undefined)
            newChildIndex = { issues: [issue], children: Map() };
        else
            newChildIndex = {
                issues: oldChildIndex.issues.concat([issue]),
                children: oldChildIndex.children,
            };
    } else {
        key = location.substring(0, p);
        newChildIndex = addToIndex(
            children.get(key) || EMPTY_ISSUE_INDEX,
            location.substring(p + 1),
            issue
        );
    }

    return {
        issues,
        children: children.set(key, newChildIndex),
    };
}

function removeFromIndex(
    index: IssueIndex,
    location: string,
    issue: Issue.AsObject
): IssueIndex {
    const { issues, children } = index;

    const p = location.indexOf(".");
    const key = p === -1 ? location : location.substring(0, p);
    const oldChildIndex = children.get(key);
    if (!oldChildIndex) {
        console.error("Didn't find an issue from index.", issue);
        return index;
    }

    var newChildIndex: IssueIndex;
    if (p == -1) {
        if (
            oldChildIndex.issues.length === 1 &&
            oldChildIndex.issues[0] === issue &&
            oldChildIndex.children.size === 0
        )
            newChildIndex = EMPTY_ISSUE_INDEX;
        else
            newChildIndex = {
                issues: oldChildIndex.issues.filter((el) => el !== issue),
                children: oldChildIndex.children,
            };
    } else {
        newChildIndex = removeFromIndex(
            oldChildIndex,
            location.substring(p + 1),
            issue
        );
    }

    if (newChildIndex === EMPTY_ISSUE_INDEX) {
        if (children.size === 1 && issues.length === 0)
            return EMPTY_ISSUE_INDEX;
        else
            return {
                issues,
                children: children.remove(key),
            };
    } else
        return {
            issues,
            children: children.set(key, newChildIndex),
        };
}

export default function reducer(
    state: IssueStore = defaultIssueStore,
    action: IStoreActions
): IssueStore {
    if (action.type === getType(actions.receivedProjectChangeEvent)) {
        if (action.payload.getTargetcollection() === TargetCollection.ISSUES) {
            const changeEvent = action.payload;
            const key = changeEvent.getContentid();
            if (changeEvent.getChangeoperation() === ChangeOperation.DELETE) {
                const issue = state.store.get(key);
                if (issue) {
                    return {
                        store: state.store.remove(key),
                        index: removeFromIndex(
                            state.index,
                            issue.location,
                            issue
                        ),
                    };
                }
            } else {
                const issue_ = changeEvent.getIssue();
                if (issue_) {
                    const issue = issue_.toObject();
                    return {
                        store: state.store.set(key, issue),
                        index: addToIndex(state.index, issue.location, issue),
                    };
                }
            }
        }
    } else if (action.type === getType(actions.unsubscribe))
        return defaultIssueStore;
    return state;
}
