import yaml from 'js-yaml';

export interface MetamodelDefinition {
    target: string;
    metamodel: Metamodel;
    name: string;
}

export function getCombinedMetamodel(metamodels: Metamodel[]) : Metamodel {
    let symbols = metamodels.flatMap((model) => model.symbols).concat();
    symbols = symbols.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i);

    let moduleTypes = metamodels.flatMap((model) => model.moduleTypes).concat();
    moduleTypes = moduleTypes.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i);

    return {
        symbols,
        moduleTypes
    };
}

export interface Metamodel {
    symbols: SymbolType[];
    moduleTypes: ModuleType[];
}

export interface SymbolType {
    name: string;
    label?: string;
    moduleType: string;
    editable: boolean;
    terminals: SymbolTerminal[];
    svg: string;
    boundingBox: number[];
}

export interface SymbolTerminal {
    name: string;
    direction: string; // 'input', 'output' or 'unknown'
    x: number;
    y: number;
}

export interface ModuleType {
    name: string;
    properties: Property[];
}

export interface Property {
    name: string;
    label?: string;
    type?: string;
}

export function getSymbolNames(metamodel: Metamodel): string[] {
    return metamodel.symbols.map((symbol) => symbol.name);
}

export function getInputTerminals(symbolName: string, metamodel: Metamodel): string[] {
    const symbolType = getSymbolType(symbolName, metamodel);
    if (!symbolType) {
        return [];
    }
    return symbolType.terminals.filter((terminal) => terminal.direction === 'input' || terminal.direction === "unknown").map((terminal) => terminal.name);
}

export function getOutputTerminals(symbolName: string, metamodel: Metamodel): string[] {
    const symbolType = getSymbolType(symbolName, metamodel);
    if (!symbolType) {
        return [];
    }
    return symbolType.terminals.filter((terminal) => terminal.direction === 'output' || terminal.direction === "unknown").map((terminal) => terminal.name);
}

export function getAttributeNames(symbolName: string, metamodel: Metamodel): string[] {
    const symbolType = getSymbolType(symbolName, metamodel);
    if (!symbolType) {
        return [];
    }
    const moduleType = getModuleType(symbolType.moduleType, metamodel);
    if (!moduleType) {
        return [];
    }
    return moduleType.properties.map((property) => property.name);
}

export function getProperties(symbolName: string, metamodel: Metamodel): Property[] {
    const symbolType = getSymbolType(symbolName, metamodel);
    if (!symbolType) {
        return [];
    }
    const moduleType = getModuleType(symbolType.moduleType, metamodel);
    if (!moduleType) {
        return [];
    }
    return moduleType.properties;
}

export function getModuleType(moduleTypeName: string, metamodel: Metamodel): ModuleType | undefined {
    return metamodel.moduleTypes.find((moduleType) => moduleType.name === moduleTypeName);
}

export function getSymbolType(symbolName: string, metamodel: Metamodel): SymbolType | undefined {
    return metamodel.symbols.find((symbol) => symbol.name === symbolName);
}

export function parseMetamodel(metamodel: string) {
    const obj = yaml.safeLoad(metamodel);

    if (!obj.symbols) {
        obj.symbols = [];
    }
    if (!obj.moduleTypes) {
        obj.moduleTypes = [];
    }

    return obj as Metamodel;
}
