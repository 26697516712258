import { PDFPageProxy } from 'pdfjs-dist';
import { createAction } from 'typesafe-actions';

const processPdfPageBegin = createAction('PROCESS_PDF_PAGE_BEGIN', (resolve) => {
    return (pageId: string) => resolve(pageId);
});
const processPdfPageSuccess = createAction('PROCESS_PDF_PAGE_SUCCESS', (resolve) => {
    return (pdfPage: PDFPageProxy, meta?: string) => resolve(pdfPage, meta);
});
const processPdfPageError = createAction('PROCESS_PDF_PAGE_ERROR', (resolve) => {
    return (error: Error, meta: string) => resolve(error, meta);
});

export default {
    processPdfPageBegin,
    processPdfPageError,
    processPdfPageSuccess,
};