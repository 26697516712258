import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import actions from '../actions/projects';
import { ProjectAccessRightsChangeEvent } from '../proto/projectList_pb';
import { ChangeEventStore } from '../store-types';

export interface AccessRight {
    [username: string]: ProjectAccessRightsChangeEvent;
}

export interface AccessRightsStore extends ChangeEventStore<AccessRight> {}

export default function(state: AccessRightsStore = {}, action: IStoreActions): AccessRightsStore {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectAccessRightsChangeEvent)) {
            const { projectId, event } = action.payload;
            const role = event.getRole();
            const user = event.getUser();
            if (role && role !== '') {
                const p = draftState[projectId];
                if (!p) {
                    draftState[projectId] = {};
                }
                draftState[projectId][user] = event;
            } else {
                delete draftState[projectId][user];
            }
        }
    });
}