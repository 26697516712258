import { grpc } from '@improbable-eng/grpc-web';
import { ThunkDispatch } from '../store-types';
import { showErrorNotification, showInfoNotification } from './notifications';

export function stdOnError(dispatch: ThunkDispatch, actionLabel: string) {
    return (code: grpc.Code, message: string) => {
        dispatch(showErrorNotification(
            actionLabel + ' failed',
            message || grpc.Code[code]
        ));
    };
}

export function stdOnEnd(dispatch: ThunkDispatch, actionLabel: string) {
    return (code: grpc.Code, message: string) => {
        if (code === grpc.Code.OK) {
            dispatch(showInfoNotification(
                actionLabel + ' succeeded',
                message
            ));
        } else {
            dispatch(showErrorNotification(
                actionLabel + ' failed',
                message || grpc.Code[code]
            ));
        }
    };
}

export function stdOnMessage(dispatch: ThunkDispatch, title: string, message: string) {
    return (response: any) => {
        dispatch(showInfoNotification(
            title,
            message
        ));
    };
}
