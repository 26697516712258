import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IStoreActions } from '../actions';
import { clearModifiedDrawingSheets, updateDrawingSheet, clearModifiedDrawingSheet } from '../actions/drawingsheets';
import actions from '../actions/paper2pidservice';
import { DrawingSheet, parseDrawingSheet } from '../components/drawingsheets/DrawingSheet';
import { ChangeOperation, ProjectChangeEvent, TargetCollection } from '../proto/projectChangeEvents_pb';
import { ChangeEventStore } from '../store-types';

export interface DrawingSheetDefinitionsStore {
    drawingSheetDefinitions: ChangeEventStore<DrawingSheet>;
    modifiedDrawingSheets: ChangeEventStore<DrawingSheet>;
}

function composed(
    state: ChangeEventStore<DrawingSheet> = {},
    action: IStoreActions
): ChangeEventStore<DrawingSheet> {
    return produce(state, (draftState) => {
        if (action.type === getType(actions.receivedProjectChangeEvent)) {
            if (action.payload.getTargetcollection() === TargetCollection.DRAWINGSHEETDEFINITIONS) {
                const changeEvent = action.payload;
                const key = changeEvent.getContentid();
                if (changeEvent.getChangeoperation() === ChangeOperation.DELETE)
                    delete draftState[key];
                else {
                    const drawingSheetDefinition = changeEvent.getDrawingsheetdefinition();
                    if (drawingSheetDefinition)
                        draftState[key] = parseDrawingSheet(drawingSheetDefinition.getDefinition());
                }
            }
        }
        else if (action.type === getType(actions.unsubscribe))
            return {};
    });
}

const initialState: DrawingSheetDefinitionsStore = {
    drawingSheetDefinitions: {},
    modifiedDrawingSheets: {},
};

export default function (state: DrawingSheetDefinitionsStore = initialState, action: IStoreActions): DrawingSheetDefinitionsStore {
    return produce(state, (draftState) => {
        if (action.type === getType(updateDrawingSheet)) {
            draftState.modifiedDrawingSheets = {
                ...draftState.modifiedDrawingSheets,
                [action.payload.drawingSheetId]: action.payload.drawingSheet
            };
        }
        else if (action.type === getType(clearModifiedDrawingSheets)) {
            draftState.modifiedDrawingSheets = {};
        }
        else if (action.type === getType(clearModifiedDrawingSheet)) {
            draftState.modifiedDrawingSheets = { ...draftState.modifiedDrawingSheets };
            delete draftState.modifiedDrawingSheets[action.payload];
        }
        else
            draftState.drawingSheetDefinitions = composed(state.drawingSheetDefinitions, action);
    });
}
