import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from '../../utils/auth';
import ActualApplication from '../ActualApplication';
import Login from '../Login';
import Logout from '../Logout';

// @ts-ignore
const ProtectedActualApplication = userIsAuthenticatedRedir(ActualApplication);
// @ts-ignore
const UnprotectedLogin = userIsNotAuthenticatedRedir(Login);

// @ts-ignore
const ProtectedLogout = userIsAuthenticatedRedir(Logout);

export default class Application extends React.PureComponent<{}> {

    public render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route exact={true} path="/login" component={UnprotectedLogin} />
                    <Route exact={true} path="/logout" component={ProtectedLogout} />
                    <Route component={ProtectedActualApplication} />
                </Switch>
            </React.Fragment>
        );
    }
}